import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { reducers, metaReducers } from './store/reducers'
// import { UserEffects } from './store/user/effects'
import { firebaseConfig, firebaseAuthService } from './services/firebase'
import { jwtAuthService } from './services/jwt'
import { MockHttpCallInterceptor } from './services/fakeApi'
import { ZXingScannerModule } from '@zxing/ngx-scanner';

// locale resistration
import { registerLocaleData } from '@angular/common'
import it from '@angular/common/locales/it';
import { NZ_I18N, it_IT} from 'ng-zorro-antd/i18n'
import { AuthorizationGuard } from './services/jwt/authorization.guard'
import { UserClaimsService } from './services/jwt/user-claims-service'
import { BsModalService } from 'ngx-bootstrap/modal'
import { ChartistModule } from 'ng-chartist'
import { ChartModule } from 'angular2-chartjs'

// jwt interceptor, authguard
// import { JwtInterceptor } from './service/jwt.interceptor';
// import { AuthGuard } from './core/guard/auth.guard';

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'it' },
  { provide: NZ_I18N, useValue: it_IT },
]
registerLocaleData(it)


@NgModule({
  declarations: [AppComponent],
  imports: [
    ZXingScannerModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    // EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,

    // init firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [
    BsModalService,
    
    // auth services
    AuthorizationGuard,
    UserClaimsService,
    firebaseAuthService,
    jwtAuthService,

    // fake http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,

    // firestore settings
    { provide: SETTINGS, useValue: {} },
    {provide: NZ_I18N, useValue: it_IT}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
