import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
// import { AuthGuard } from 'src/app/@vb/components/Guard/auth.guard'
import { WidgetsComponentsModule } from 'src/app/@vb/widgets/widgets-components.module'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// QRcode scanner
import { ZXingScannerModule } from '@zxing/ngx-scanner'
import { Html5QrcodeScanner } from 'html5-qrcode'

// QRcode generator
import { QrCodeModule } from 'ng-qrcode'
import { QRCodeModule } from 'angularx-qrcode'
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode'

// QRcode reader
// import { NgxQrcodeReaderModule } from 'ngx-qrcode-reader';
import { NgxQrcodeReaderModule } from 'ngx-qrcode-reader'

//pdf viewer
import { PdfViewerModule } from 'ng2-pdf-viewer'

// pages

// VB:REPLACE-START:ROUTER-IMPORTS
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { DashboardAlphaComponent } from './pages/dashboard/alpha/alpha.component'
import { DashboardBetaComponent } from './pages/dashboard/beta/beta.component'
import { DashboardGammaComponent } from './pages/dashboard/gamma/gamma.component'
import { DashboardCryptoComponent } from './pages/dashboard/crypto/crypto.component'
import { AppsComponent } from './pages/apps/apps.component'
import { AppsProfileComponent } from './pages/apps/profile/profile.component'
import { AppsCalendarComponent } from './pages/apps/calendar/calendar.component'
import { AppsGalleryComponent } from './pages/apps/gallery/gallery.component'
import { AppsMessagingComponent } from './pages/apps/messaging/messaging.component'
import { AppsMailComponent } from './pages/apps/mail/mail.component'
import { ExtraAppsComponent } from './pages/extra-apps/extra-apps.component'
import { ExtraAppsGithubExploreComponent } from './pages/extra-apps/github-explore/github-explore.component'
import { ExtraAppsGithubDiscussComponent } from './pages/extra-apps/github-discuss/github-discuss.component'
import { ExtraAppsDigitaloceanDropletsComponent } from './pages/extra-apps/digitalocean-droplets/digitalocean-droplets.component'
import { ExtraAppsDigitaloceanCreateComponent } from './pages/extra-apps/digitalocean-create/digitalocean-create.component'
import { ExtraAppsGoogleAnalyticsComponent } from './pages/extra-apps/google-analytics/google-analytics.component'
import { ExtraAppsWordpressPostComponent } from './pages/extra-apps/wordpress-post/wordpress-post.component'
import { ExtraAppsWordpressPostsComponent } from './pages/extra-apps/wordpress-posts/wordpress-posts.component'
import { ExtraAppsWordpressAddComponent } from './pages/extra-apps/wordpress-add/wordpress-add.component'
import { ExtraAppsTodoistListComponent } from './pages/extra-apps/todoist-list/todoist-list.component'
import { ExtraAppsJiraDashboardComponent } from './pages/extra-apps/jira-dashboard/jira-dashboard.component'
import { ExtraAppsJiraAgileBoardComponent } from './pages/extra-apps/jira-agile-board/jira-agile-board.component'
import { ExtraAppsHelpdeskDashboardComponent } from './pages/extra-apps/helpdesk-dashboard/helpdesk-dashboard.component'
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component'
import { EcommerceDashboardComponent } from './pages/ecommerce/dashboard/dashboard.component'
import { EcommerceOrdersComponent } from './pages/ecommerce/orders/orders.component'
import { EcommerceProductCatalogComponent } from './pages/ecommerce/product-catalog/product-catalog.component'
import { EcommerceProductDetailsComponent } from './pages/ecommerce/product-details/product-details.component'
import { EcommerceCartComponent } from './pages/ecommerce/cart/cart.component'
import { UiKitsAntdComponent } from './pages/ui-kits/antd/antd.component'
import { UiKitsBootstrapComponent } from './pages/ui-kits/bootstrap/bootstrap.component'
import { WidgetsComponent } from './pages/widgets/widgets.component'
import { WidgetsGeneralComponent } from './pages/widgets/general/general.component'
import { WidgetsListsComponent } from './pages/widgets/lists/lists.component'
import { WidgetsTablesComponent } from './pages/widgets/tables/tables.component'
import { WidgetsChartsComponent } from './pages/widgets/charts/charts.component'
import { CardsComponent } from './pages/cards/cards.component'
import { CardsBasicComponent } from './pages/cards/basic/basic.component'
import { CardsTabbedComponent } from './pages/cards/tabbed/tabbed.component'
import { TablesComponent } from './pages/tables/tables.component'
import { TablesAntdComponent } from './pages/tables/antd/antd.component'
import { TablesBootstrapComponent } from './pages/tables/bootstrap/bootstrap.component'
import { ChartsComponent } from './pages/charts/charts.component'
import { ChartsChartistjsComponent } from './pages/charts/chartistjs/chartistjs.component'
import { ChartsChartjsComponent } from './pages/charts/chartjs/chartjs.component'
import { ChartsC3Component } from './pages/charts/C3/C3.component'
import { IconsComponent } from './pages/icons/icons.component'
import { IconsFeatherIconsComponent } from './pages/icons/feather-icons/feather-icons.component'
import { IconsFontawesomeComponent } from './pages/icons/fontawesome/fontawesome.component'
import { IconsLineariconsFreeComponent } from './pages/icons/linearicons-free/linearicons-free.component'
import { IconsIcomoonFreeComponent } from './pages/icons/icomoon-free/icomoon-free.component'
import { AdvancedFormExamplesComponent } from './pages/advanced/form-examples/form-examples.component'
import { AdvancedEmailTemplatesComponent } from './pages/advanced/email-templates/email-templates.component'
import { AdvancedPricingTablesComponent } from './pages/advanced/pricing-tables/pricing-tables.component'
import { AdvancedInvoiceComponent } from './pages/advanced/invoice/invoice.component'
import { AdvancedUtilitiesComponent } from './pages/advanced/utilities/utilities.component'
import { AdvancedGridComponent } from './pages/advanced/grid/grid.component'
import { AdvancedTypographyComponent } from './pages/advanced/typography/typography.component'
import { AdvancedColorsComponent } from './pages/advanced/colors/colors.component'
import { NestedComponent } from './pages/nested/nested.component'
import { Nested1Component } from './pages/nested/1/1.component'
import { Nested2Component } from './pages/nested/2/2.component'
import { AddFormTemplateComponent } from './shared/forms/add-form-template/add-form-template.component'
import { AddFormComponent } from './shared/forms/add-form/add-form.component'
import { AuthorizationGuard } from './services/jwt/authorization.guard'
import { CGenericTableComponent } from './shared/tables/c-generic-table.component'
import { KeysPipe } from './shared/tables/data-property-getter-pipe/keys-pipe'
import { DataPropertyGetterPipe } from './shared/tables/data-property-getter-pipe/data-property-getter.pipe'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { CGenericUploadComponent } from './shared/upload/c-generic-upload/c-generic-upload.component'
import { CDashboardComponent } from './pages/c-dashboard/c-dashboard.component'
import { CManageDataComponent } from './pages/c-manage-data/c-manage-data.component'
import { AnagraficaAnimaleComponent } from './pages/pages-precisionfarming/gestione dati/anagrafica-animale/anagrafica-animale.component'
import { AusiliareComponent } from './pages/pages-precisionfarming/gestione dati/ausiliare/ausiliare.component'
import { ClienteComponent } from './pages/pages-precisionfarming/gestione dati/cliente/cliente.component'
import { ContenitoreComponent } from './pages/pages-precisionfarming/gestione dati/contenitore/contenitore.component'
import { DeiezioneComponent } from './pages/pages-precisionfarming/gestione dati/deiezione/deiezione.component'
import { FabbricatoComponent } from './pages/pages-precisionfarming/gestione dati/fabbricato/fabbricato.component'
import { FarmacoComponent } from './pages/pages-precisionfarming/gestione dati/farmaco/farmaco.component'
import { FornitoreComponent } from './pages/pages-precisionfarming/gestione dati/fornitore/fornitore.component'
import { LuogoAnimaliComponent } from './pages/pages-precisionfarming/gestione dati/luogo-animali/luogo-animali.component'
import { MagazzinoComponent } from './pages/pages-precisionfarming/gestione dati/magazzino/magazzino.component'
import { MezzoComponent } from './pages/pages-precisionfarming/gestione dati/mezzo/mezzo.component'
import { ProdottoComponent } from './pages/pages-precisionfarming/gestione dati/fabbricato/prodotto/prodotto.component'
import { SensoreComponent } from './pages/pages-precisionfarming/gestione dati/sensore/sensore.component'
import { TerrenoComponent } from './pages/pages-precisionfarming/gestione dati/terreno/terreno.component'
import { UtensileComponent } from './pages/pages-precisionfarming/gestione dati/utensile/utensile.component'
import { MEditAnagraficaAnimaleComponent } from './modals/precisionfarming-modals/m-edit-anagrafica-animale/m-edit-anagrafica-animale.component'
import { MEditAusiliareComponent } from './modals/precisionfarming-modals/m-edit-ausiliare/m-edit-ausiliare.component'
import { MEditClienteComponent } from './modals/precisionfarming-modals/m-edit-cliente/m-edit-cliente.component'
import { MEditContenitoreComponent } from './modals/precisionfarming-modals/m-edit-contenitore/m-edit-contenitore.component'
import { MEditDeiezioneComponent } from './modals/precisionfarming-modals/m-edit-deiezione/m-edit-deiezione.component'
import { MEditFabbricatoComponent } from './modals/precisionfarming-modals/m-edit-fabbricato/m-edit-fabbricato.component'
import { MEditFarmacoComponent } from './modals/precisionfarming-modals/m-edit-farmaco/m-edit-farmaco.component'
import { MEditLuogoAnimaliComponent } from './modals/precisionfarming-modals/m-edit-luogo-animali/m-edit-luogo-animali.component'
import { MEditMagazzinoComponent } from './modals/precisionfarming-modals/m-edit-magazzino/m-edit-magazzino.component'
import { MEditMezzoComponent } from './modals/precisionfarming-modals/m-edit-mezzo/m-edit-mezzo.component'
import { MEditProdottoComponent } from './modals/precisionfarming-modals/m-edit-prodotto/m-edit-prodotto.component'
import { MEditSensoreComponent } from './modals/precisionfarming-modals/m-edit-sensore/m-edit-sensore.component'
import { MEditTerrenoComponent } from './modals/precisionfarming-modals/m-edit-terreno/m-edit-terreno.component'
import { MEditUtensileComponent } from './modals/precisionfarming-modals/m-edit-utensile/m-edit-utensile.component'
import { MEditFornitoreComponent } from './modals/precisionfarming-modals/m-edit-fornitore/m-edit-fornitore.component'
import { CRegisterComponent } from './pages/c-register/c-register.component'
import { CChartComponent } from './pages/c-chart/c-chart.component'
import { CCardDashboardComponent } from './pages/c-card-dashboard/c-card-dashboard.component'
import { AttivitaComponent } from './pages/pages-precisionfarming/attivita/attivita.component'
import { ChartComponent } from './shared/chart/chart.component'
import { CreaFatturaComponent } from './pages/pages-precisionfarming/vendite/crea-fattura/crea-fattura.component'
import { ProduzioneComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione.component'
import { PuliziaComponent } from './pages/pages-precisionfarming/attivita/pulizia/pulizia.component'
import { ProduzioneDatiSensoreComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione-dati-sensore/produzione-dati-sensore.component'
import { ProduzioneDatiPianificazioneComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione-dati-pianificazione/produzione-dati-pianificazione.component'
import { ProduzioneDatiConsuntiviComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione-dati-consuntivi/produzione-dati-consuntivi.component'
import { CardAttivitaPianificateComponent } from './pages/pages-precisionfarming/attivita/produzione/card-attivita-pianificate/card-attivita-pianificate.component'
import { MManageRazzeComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-razze/m-manage-razze.component'
import { MManageMandriaComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-mandria/m-manage-mandria.component'
import { MManageTipoComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-tipo/m-manage-tipo.component'
import { MManageSottotipoComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-sottotipo/m-manage-sottotipo.component'
import { MManageMagazzinoComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-magazzino/m-manage-magazzino.component'
import { MManageTipoDeiezioneComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-tipo-deiezione/m-manage-tipo-deiezione.component'
import { MManageUtilizzoDeiezioneComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-utilizzo-deiezione/m-manage-utilizzo-deiezione.component'
import { MManageTerrenoComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-terreno/m-manage-terreno.component'
import { RazzaComponent } from './pages/pages-precisionfarming/gestione dati/razza/razza.component'
import { MandriaComponent } from './pages/pages-precisionfarming/gestione dati/mandria/mandria.component'
import { TipoComponent } from './pages/pages-precisionfarming/gestione dati/tipo/tipo.component'
import { SottotipoComponent } from './pages/pages-precisionfarming/gestione dati/sottotipo/sottotipo.component'
import { TipoDeiezioneComponent } from './pages/pages-precisionfarming/gestione dati/tipo-deiezione/tipo-deiezione.component'
import { UtilizzoDeiezioneComponent } from './pages/pages-precisionfarming/gestione dati/utilizzo-deiezione/utilizzo-deiezione.component'
import { MEditDatiPianificazioneComponent } from './modals/precisionfarming-modals/attivita/m-edit-dati-pianificazione/m-edit-dati-pianificazione.component'
import { TipoAttivitaComponent } from './pages/pages-precisionfarming/gestione dati/tipo-attivita/tipo-attivita.component'
import { MManageTipoAttivitaComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-tipo-attivita/m-manage-tipo-attivita.component'
import { MManageTipoAusiliareComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-tipo-ausiliare/m-manage-tipo-ausiliare.component'
import { MManageSottotipoAusiliareComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-sottotipo-ausiliare/m-manage-sottotipo-ausiliare.component'
import { MManageUtilizzoAusiliareComponent } from './modals/precisionfarming-modals/manage-select-fields-modals/m-manage-utilizzo-ausiliare/m-manage-utilizzo-ausiliare.component'
import { TipoAusiliareComponent } from './pages/pages-precisionfarming/gestione dati/tipo-ausiliare/tipo-ausiliare.component'
import { SottotipoAusiliareComponent } from './pages/pages-precisionfarming/gestione dati/sottotipo-ausiliare/sottotipo-ausiliare.component'
import { UtilizzoAusiliareComponent } from './pages/pages-precisionfarming/gestione dati/utilizzo-ausiliare/utilizzo-ausiliare.component'
import { MEditDatiConsuntiviComponent } from './modals/precisionfarming-modals/attivita/m-edit-dati-consuntivi/m-edit-dati-consuntivi.component'
import { ProduzioneUtenteComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione-utente/produzione-utente.component'
import { QRCodeComponent } from './pages/pages-precisionfarming/gestione dati/qr-code/qr-code.component'
import { MQRCodeComponent } from './modals/precisionfarming-modals/m-qr-code/m-qr-code.component'
import { CertificazioneBioComponent } from './pages/pages-precisionfarming/certificazione-bio/certificazione-bio.component'
import { MainAusiliareComponent } from './pages/pages-precisionfarming/main-ausiliare/main-ausiliare.component'
import { MainProdottoComponent } from './pages/pages-precisionfarming/main-prodotto/main-prodotto.component'
import { QrCodeReaderComponent } from './shared/qr-code-reader/qr-code-reader.component'
import { NormativaComponent } from './pages/pages-precisionfarming/normativa/normativa.component'
import { MNormativaComponent } from './modals/precisionfarming-modals/normativa/m-normativa/m-normativa.component'
import { ProduzioneDatiPianificazioneIdComponent } from './pages/pages-precisionfarming/attivita/produzione/produzione-dati-pianificazione-id/produzione-dati-pianificazione-id.component'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  {
    path: '',
    // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [AuthorizationGuard],
    children: [
      // VB:REPLACE-START:ROUTER-CONFIG
      // {
      //   path: 'dashboards',
      //   data: { title: 'Dashboards' },
      //   canActivate: [AuthorizationGuard],
      //   component: DashboardAlphaComponent,
      // },
      {
        path: 'dashboard',
        data: { title: 'Dashboard' },
        canActivate: [AuthorizationGuard],
        component: CDashboardComponent,
      },
      {
        path: 'organic-certification',
        data: { title: 'Certificazione bio' },
        canActivate: [AuthorizationGuard],
        component: CertificazioneBioComponent,
      },
      {
        path: 'auxiliaries',
        data: { title: 'Ausiliari' },
        canActivate: [AuthorizationGuard],
        component: MainAusiliareComponent,
      },
      {
        path: 'products',
        data: { title: 'Prodotti' },
        canActivate: [AuthorizationGuard],
        component: MainProdottoComponent,
      },
      {
        path: 'regulations',
        data: { title: 'Normativa' },
        canActivate: [AuthorizationGuard],
        component: NormativaComponent,
      },
      {
        path: 'manage-data',
        data: { title: 'Gestione Dati' },
        canActivate: [AuthorizationGuard],
        component: CManageDataComponent,
      },
      {
        path: 'manage-data/qr-code',
        data: { title: 'QR code' },
        canActivate: [AuthorizationGuard],
        component: QRCodeComponent,
      },
      {
        path: 'manage-data/animals-registry',
        data: { title: 'Anagrafica animali' },
        canActivate: [AuthorizationGuard],
        component: AnagraficaAnimaleComponent,
      },
      {
        path: 'manage-data/auxiliaries',
        data: { title: 'Ausiliari' },
        canActivate: [AuthorizationGuard],
        component: AusiliareComponent,
      },
      {
        path: 'manage-data/customers',
        data: { title: 'Clienti' },
        canActivate: [AuthorizationGuard],
        component: ClienteComponent,
      },
      {
        path: 'manage-data/containers',
        data: { title: 'Contenitori' },
        canActivate: [AuthorizationGuard],
        component: ContenitoreComponent,
      },
      {
        path: 'manage-data/droppings',
        data: { title: 'Deiezioni' },
        canActivate: [AuthorizationGuard],
        component: DeiezioneComponent,
      },
      {
        path: 'manage-data/buildings',
        data: { title: 'Fabbricati' },
        canActivate: [AuthorizationGuard],
        component: FabbricatoComponent,
      },
      {
        path: 'manage-data/drugs',
        data: { title: 'Farmaci' },
        canActivate: [AuthorizationGuard],
        component: FarmacoComponent,
      },
      {
        path: 'manage-data/suppliers',
        data: { title: 'Fornitori' },
        canActivate: [AuthorizationGuard],
        component: FornitoreComponent,
      },
      {
        path: 'manage-data/places',
        data: { title: 'Luoghi animali' },
        canActivate: [AuthorizationGuard],
        component: LuogoAnimaliComponent,
      },
      {
        path: 'manage-data/warehouses',
        data: { title: 'Magazzini' },
        canActivate: [AuthorizationGuard],
        component: MagazzinoComponent,
      },
      {
        path: 'manage-data/vehicles',
        data: { title: 'Mezzi' },
        canActivate: [AuthorizationGuard],
        component: MezzoComponent,
      },
      {
        path: 'manage-data/products',
        data: { title: 'Prodotti' },
        canActivate: [AuthorizationGuard],
        component: ProdottoComponent,
      },
      {
        path: 'manage-data/sensors',
        data: { title: 'Sensori' },
        canActivate: [AuthorizationGuard],
        component: SensoreComponent,
      },
      {
        path: 'manage-data/farmlands',
        data: { title: 'Terreni' },
        canActivate: [AuthorizationGuard],
        component: TerrenoComponent,
      },
      {
        path: 'manage-data/tools',
        data: { title: 'Utensili' },
        canActivate: [AuthorizationGuard],
        component: UtensileComponent,
      },
      {
        path: 'manage-data/breeds',
        data: { title: 'Razze' },
        canActivate: [AuthorizationGuard],
        component: RazzaComponent,
      },
      {
        path: 'manage-data/herds',
        data: { title: 'Mandrie' },
        canActivate: [AuthorizationGuard],
        component: MandriaComponent,
      },
      {
        path: 'manage-data/types',
        data: { title: 'Tipi' },
        canActivate: [AuthorizationGuard],
        component: TipoComponent,
      },
      {
        path: 'manage-data/subtypes',
        data: { title: 'Sottotipi' },
        canActivate: [AuthorizationGuard],
        component: SottotipoComponent,
      },
      {
        path: 'manage-data/droplet-uses',
        data: { title: 'Utilizzi deiezione' },
        canActivate: [AuthorizationGuard],
        component: UtilizzoDeiezioneComponent,
      },
      {
        path: 'manage-data/droplet-types',
        data: { title: 'Tipi deiezione' },
        canActivate: [AuthorizationGuard],
        component: TipoDeiezioneComponent,
      },
      {
        path: 'manage-data/activity-types',
        data: { title: 'Tipi attività' },
        canActivate: [AuthorizationGuard],
        component: TipoAttivitaComponent,
      },
      {
        path: 'manage-data/types-auxiliaries',
        data: { title: 'Tipi ausiliare' },
        canActivate: [AuthorizationGuard],
        component: TipoAusiliareComponent,
      },
      {
        path: 'manage-data/subtypes-auxiliaries',
        data: { title: 'Tipi ausiliare' },
        canActivate: [AuthorizationGuard],
        component: SottotipoAusiliareComponent,
      },
      {
        path: 'manage-data/uses-auxiliaries',
        data: { title: 'Tipi ausiliare' },
        canActivate: [AuthorizationGuard],
        component: UtilizzoAusiliareComponent,
      },

      // {
      //   path: 'activity',
      //   data: { title: 'Attività' },
      //   canActivate: [AuthorizationGuard],
      //   component: AttivitaComponent,
      // },
      {
        path: 'activity/admin-production',
        data: { title: 'Produzione', role: ['1', '2'] },
        canActivate: [AuthorizationGuard],
        component: ProduzioneComponent,
      },
      {
        path: 'activity/admin-production/:id',
        data: { title: 'Produzione', role: ['1', '2'] },
        canActivate: [AuthorizationGuard],
        component: ProduzioneDatiPianificazioneIdComponent,
      },
      {
        path: 'activity/admin-cleaning',
        data: { title: 'Pulizia', role: ['1', '2'] },
        canActivate: [AuthorizationGuard],
        component: PuliziaComponent,
      },
      {
        path: 'activity/user-production',
        data: { title: 'Produzione', role: ['3'] },
        canActivate: [AuthorizationGuard],
        component: ProduzioneUtenteComponent,
      },
      // {
      //   path: 'activity/user-cleaning',
      //   data: { title: 'Pulizia', role: ['1', '2']},
      //   canActivate: [AuthorizationGuard],
      //   component: PuliziaComponent,
      // },
      {
        path: 'sales/create-invoice',
        data: { title: 'Crea fattura' },
        canActivate: [AuthorizationGuard],
        component: CreaFatturaComponent,
      },

      // {
      //   path: 'extra-apps/wordpress-add',
      //   data: { title: 'Wordpress Add' },
      //   canActivate: [AuthorizationGuard],
      //   component: ExtraAppsWordpressAddComponent,
      // },
      // {
      //   path: 'extra-apps/todoist-list',
      //   data: { title: 'Todoist List' },
      //   canActivate: [AuthorizationGuard],
      //   component: ExtraAppsTodoistListComponent,
      // },
      // {
      //   path: 'extra-apps/jira-dashboard',
      //   data: { title: 'Jira Dashboard' },
      //   canActivate: [AuthorizationGuard],
      //   component: ExtraAppsJiraDashboardComponent,
      // },
      // {
      //   path: 'extra-apps/jira-agile-board',
      //   data: { title: 'Jira Agile Board' },
      //   canActivate: [AuthorizationGuard],
      //   component: ExtraAppsJiraAgileBoardComponent,
      // },
      // {
      //   path: 'extra-apps/helpdesk-dashboard',
      //   data: { title: 'Helpdesk Dashboard' },
      //   canActivate: [AuthorizationGuard],
      //   component: ExtraAppsHelpdeskDashboardComponent,
      // },
      // {
      //   path: 'ecommerce',
      //   data: { title: 'Ecommerce' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceComponent,
      // },
      // {
      //   path: 'ecommerce/dashboard',
      //   data: { title: 'Dashboard' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceDashboardComponent,
      // },
      // {
      //   path: 'ecommerce/orders',
      //   data: { title: 'Orders' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceOrdersComponent,
      // },
      // {
      //   path: 'ecommerce/product-catalog',
      //   data: { title: 'Product Catalog' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceProductCatalogComponent,
      // },
      // {
      //   path: 'ecommerce/product-details',
      //   data: { title: 'Product Details' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceProductDetailsComponent,
      // },
      // {
      //   path: 'ecommerce/cart',
      //   data: { title: 'Cart' },
      //   canActivate: [AuthorizationGuard],
      //   component: EcommerceCartComponent,
      // },
      // {
      //   path: 'ui-kits/antd',
      //   data: { title: 'Ant Design' },
      //   canActivate: [AuthorizationGuard],
      //   component: UiKitsAntdComponent,
      // },
      // {
      //   path: 'ui-kits/bootstrap',
      //   data: { title: 'Bootstrap' },
      //   canActivate: [AuthorizationGuard],
      //   component: UiKitsBootstrapComponent,
      // },
      // {
      //   path: 'widgets',
      //   data: { title: 'Widgets' },
      //   canActivate: [AuthorizationGuard],
      //   component: WidgetsComponent,
      // },
      // {
      //   path: 'widgets/general',
      //   data: { title: 'General' },
      //   canActivate: [AuthorizationGuard],
      //   component: WidgetsGeneralComponent,
      // },
      // {
      //   path: 'widgets/lists',
      //   data: { title: 'Lists' },
      //   canActivate: [AuthorizationGuard],
      //   component: WidgetsListsComponent,
      // },
      // {
      //   path: 'widgets/tables',
      //   data: { title: 'Tables' },
      //   canActivate: [AuthorizationGuard],
      //   component: WidgetsTablesComponent,
      // },
      // {
      //   path: 'widgets/charts',
      //   data: { title: 'Charts' },
      //   canActivate: [AuthorizationGuard],
      //   component: WidgetsChartsComponent,
      // },
      // {
      //   path: 'cards',
      //   data: { title: 'Cards' },
      //   canActivate: [AuthorizationGuard],
      //   component: CardsComponent,
      // },
      // {
      //   path: 'cards/basic',
      //   data: { title: 'Basic Cards' },
      //   canActivate: [AuthorizationGuard],
      //   component: CardsBasicComponent,
      // },
      // {
      //   path: 'cards/tabbed',
      //   data: { title: 'Tabbed Cards' },
      //   canActivate: [AuthorizationGuard],
      //   component: CardsTabbedComponent,
      // },
      // {
      //   path: 'tables',
      //   data: { title: 'Tables' },
      //   canActivate: [AuthorizationGuard],
      //   component: TablesComponent,
      // },
      // {
      //   path: 'tables/antd',
      //   data: { title: 'Ant Design' },
      //   canActivate: [AuthorizationGuard],
      //   component: TablesAntdComponent,
      // },
      // {
      //   path: 'tables/bootstrap',
      //   data: { title: 'Bootstrap' },
      //   canActivate: [AuthorizationGuard],
      //   component: TablesBootstrapComponent,
      // },
      // {
      //   path: 'charts',
      //   data: { title: 'Charts' },
      //   canActivate: [AuthorizationGuard],
      //   component: ChartsComponent,
      // },
      // {
      //   path: 'charts/chartistjs',
      //   data: { title: 'Chartist.js' },
      //   canActivate: [AuthorizationGuard],
      //   component: ChartsChartistjsComponent,
      // },
      // {
      //   path: 'charts/chartjs',
      //   data: { title: 'Chart.js' },
      //   canActivate: [AuthorizationGuard],
      //   component: ChartsChartjsComponent,
      // },
      // {
      //   path: 'charts/C3',
      //   data: { title: 'C3' },
      //   canActivate: [AuthorizationGuard],
      //   component: ChartsC3Component,
      // },
      // {
      //   path: 'icons',
      //   data: { title: 'Icons' },
      //   canActivate: [AuthorizationGuard],
      //   component: IconsComponent,
      // },
      // {
      //   path: 'icons/feather-icons',
      //   data: { title: 'Feather Icons' },
      //   canActivate: [AuthorizationGuard],
      //   component: IconsFeatherIconsComponent,
      // },
      // {
      //   path: 'icons/fontawesome',
      //   data: { title: 'Fontawesome' },
      //   canActivate: [AuthorizationGuard],
      //   component: IconsFontawesomeComponent,
      // },
      // {
      //   path: 'icons/linearicons-free',
      //   data: { title: 'Linearicons' },
      //   canActivate: [AuthorizationGuard],
      //   component: IconsLineariconsFreeComponent,
      // },
      // {
      //   path: 'icons/icomoon-free',
      //   data: { title: 'Icomoon Free' },
      //   canActivate: [AuthorizationGuard],
      //   component: IconsIcomoonFreeComponent,
      // },
      // {
      //   path: 'advanced/form-examples',
      //   data: { title: 'Form Examples' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedFormExamplesComponent,
      // },
      // {
      //   path: 'advanced/email-templates',
      //   data: { title: 'Email Templates' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedEmailTemplatesComponent,
      // },
      // {
      //   path: 'advanced/pricing-tables',
      //   data: { title: 'Pricing Tables' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedPricingTablesComponent,
      // },
      // {
      //   path: 'advanced/invoice',
      //   data: { title: 'Invoice' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedInvoiceComponent,
      // },
      // {
      //   path: 'advanced/utilities',
      //   data: { title: 'Utilities' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedUtilitiesComponent,
      // },
      // {
      //   path: 'advanced/grid',
      //   data: { title: 'Grid' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedGridComponent,
      // },
      // {
      //   path: 'advanced/typography',
      //   data: { title: 'Typography' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedTypographyComponent,
      // },
      // {
      //   path: 'advanced/colors',
      //   data: { title: 'Colors' },
      //   canActivate: [AuthorizationGuard],
      //   component: AdvancedColorsComponent,
      // },
      // {
      //   path: 'nested',
      //   data: { title: 'Nested Items' },
      //   canActivate: [AuthorizationGuard],
      //   component: NestedComponent,
      // },
      // {
      //   path: 'nested/1',
      //   data: { title: 'Level 1' },
      //   canActivate: [AuthorizationGuard],
      //   component: Nested1Component,
      // },
      // {
      //   path: 'nested/2',
      //   data: { title: 'Level 2' },
      //   canActivate: [AuthorizationGuard],
      //   component: Nested2Component,
      // },

      // VB:REPLACE-END:ROUTER-CONFIG
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    QrCodeModule,
    // Html5QrcodeScanner,
    NgxQrcodeReaderModule,
    NgxQRCodeModule,
    QRCodeModule,
    ZXingScannerModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: 'legacy',
    }),
    LayoutsModule,
    WidgetsComponentsModule,
    PdfViewerModule,
  ],
  declarations: [
    KeysPipe,
    DataPropertyGetterPipe,
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    DashboardComponent,
    CDashboardComponent,
    DashboardAlphaComponent,
    DashboardBetaComponent,
    DashboardGammaComponent,
    DashboardCryptoComponent,
    AppsComponent,
    AppsProfileComponent,
    AppsCalendarComponent,
    AppsGalleryComponent,
    AppsMessagingComponent,
    AppsMailComponent,
    ExtraAppsComponent,
    ExtraAppsGithubExploreComponent,
    ExtraAppsGithubDiscussComponent,
    ExtraAppsDigitaloceanDropletsComponent,
    ExtraAppsDigitaloceanCreateComponent,
    ExtraAppsGoogleAnalyticsComponent,
    ExtraAppsWordpressPostComponent,
    ExtraAppsWordpressPostsComponent,
    ExtraAppsWordpressAddComponent,
    ExtraAppsTodoistListComponent,
    ExtraAppsJiraDashboardComponent,
    ExtraAppsJiraAgileBoardComponent,
    ExtraAppsHelpdeskDashboardComponent,
    EcommerceComponent,
    EcommerceDashboardComponent,
    EcommerceOrdersComponent,
    EcommerceProductCatalogComponent,
    EcommerceProductDetailsComponent,
    EcommerceCartComponent,
    UiKitsAntdComponent,
    UiKitsBootstrapComponent,
    WidgetsComponent,
    WidgetsGeneralComponent,
    WidgetsListsComponent,
    WidgetsTablesComponent,
    WidgetsChartsComponent,
    CardsComponent,
    CardsBasicComponent,
    CardsTabbedComponent,
    TablesComponent,
    TablesAntdComponent,
    TablesBootstrapComponent,
    ChartsComponent,
    ChartsChartistjsComponent,
    ChartsChartjsComponent,
    ChartsC3Component,
    IconsComponent,
    IconsFeatherIconsComponent,
    IconsFontawesomeComponent,
    IconsLineariconsFreeComponent,
    IconsIcomoonFreeComponent,
    AdvancedFormExamplesComponent,
    AdvancedEmailTemplatesComponent,
    AdvancedPricingTablesComponent,
    AdvancedInvoiceComponent,
    AdvancedUtilitiesComponent,
    AdvancedGridComponent,
    AdvancedTypographyComponent,
    AdvancedColorsComponent,
    NestedComponent,
    Nested1Component,
    Nested2Component,
    AddFormComponent,
    AddFormTemplateComponent,
    // aggiunti
    CGenericTableComponent,
    // CWidgetProfileComponent,
    // CManageOperatorsComponent,
    CGenericUploadComponent,
    CManageDataComponent,
    AnagraficaAnimaleComponent,
    AusiliareComponent,
    ClienteComponent,
    ContenitoreComponent,
    DeiezioneComponent,
    FabbricatoComponent,
    FarmacoComponent,
    FornitoreComponent,
    LuogoAnimaliComponent,
    MagazzinoComponent,
    MezzoComponent,
    ProdottoComponent,
    SensoreComponent,
    TerrenoComponent,
    UtensileComponent,
    MEditAnagraficaAnimaleComponent,
    MEditAusiliareComponent,
    MEditClienteComponent,
    MEditContenitoreComponent,
    MEditDeiezioneComponent,
    MEditFabbricatoComponent,
    MEditFarmacoComponent,
    MEditLuogoAnimaliComponent,
    MEditMagazzinoComponent,
    MEditMezzoComponent,
    MEditProdottoComponent,
    MEditSensoreComponent,
    MEditTerrenoComponent,
    MEditUtensileComponent,
    MEditFornitoreComponent,
    CCardDashboardComponent,
    AttivitaComponent,
    CreaFatturaComponent,
    ProduzioneComponent,
    PuliziaComponent,
    ProduzioneDatiSensoreComponent,
    ProduzioneDatiPianificazioneComponent,
    ProduzioneDatiConsuntiviComponent,
    CardAttivitaPianificateComponent,
    MManageRazzeComponent,
    MManageMandriaComponent,
    MManageTipoComponent,
    MManageSottotipoComponent,
    MManageMagazzinoComponent,
    MManageTipoDeiezioneComponent,
    MManageUtilizzoDeiezioneComponent,
    MManageTerrenoComponent,
    RazzaComponent,
    MandriaComponent,
    TipoComponent,
    SottotipoComponent,
    TipoDeiezioneComponent,
    UtilizzoDeiezioneComponent,
    MEditDatiPianificazioneComponent,
    TipoAttivitaComponent,
    TipoAusiliareComponent,
    SottotipoAusiliareComponent,
    UtilizzoAusiliareComponent,
    MManageTipoAttivitaComponent,
    MManageTipoAusiliareComponent,
    MManageSottotipoAusiliareComponent,
    MManageUtilizzoAusiliareComponent,
    MEditDatiConsuntiviComponent,
    ProduzioneUtenteComponent,
    QRCodeComponent,
    MQRCodeComponent,
    CertificazioneBioComponent,
    MainAusiliareComponent,
    MainProdottoComponent,
    QrCodeReaderComponent,
    NormativaComponent,
    MNormativaComponent,
    ProduzioneDatiPianificazioneIdComponent,

    // CRegisterComponent,
    // VB:REPLACE-END:ROUTER-DECLARATIONS
  ],
  providers: [AppPreloader, KeysPipe, DataPropertyGetterPipe],
  exports: [RouterModule],
})
export class AppRoutingModule {}
