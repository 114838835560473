<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed [data]="{'title':'Basic'}"></vb-headers-card-header-tabbed>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-3
            [data]="{'title':'With dropdown'}"
          ></vb-headers-card-header-tabbed-3>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-4
            [data]="{'title':'Bold border'}"
          ></vb-headers-card-header-tabbed-4>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-2
            [data]="{'title':'Pills'}"
          ></vb-headers-card-header-tabbed-2>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-5
            [data]="{'title':'With icon','icon':'fe fe-activity'}"
          ></vb-headers-card-header-tabbed-5>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-6></vb-headers-card-header-tabbed-6>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
    </div>
  </div>
</div>
