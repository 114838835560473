import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps',
  templateUrl: './extra-apps.component.html',
})
export class ExtraAppsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
