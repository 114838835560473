import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import jsQR, { QRCode } from 'jsqr';

@Component({
  selector: 'app-qr-code-reader',
  templateUrl: './qr-code-reader.component.html',
  styleUrls: ['./qr-code-reader.component.scss']
})
export class QrCodeReaderComponent implements OnInit, OnDestroy {

  @ViewChild('video', { static: true })
  videoElm!: ElementRef;
  @ViewChild('canvas', { static: true })
  canvasElm!: ElementRef;
  @Input() idInput: string
  @Output() qrCode: EventEmitter<any> = new EventEmitter<any>();

  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  base64textString: string | undefined;
  fileName: any;
  fileType: any;
  qrError: boolean = false;
  qrIncomplete: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
    if(navigator.mediaDevices){
      this.toggleVideoMedia();
    } else {
      this.openFileInput();
    }
  }

  ngOnDestroy(): void {
    this.stopVideo();
  }

  emitQrCode(item) {
    this.qrCode.emit(item);
  }

  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }

  startVideo() {
    console.log('start')
    this.medias.video = true;
    this.medias.video = {
      facingMode: 'environment'
    }

    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    if(this.videoElm.nativeElement.srcObject) {
      this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    }
    
    this.videoStart = false;
  }

  checkImage() {

    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

    if (code) {
      console.log(code);
      this.emitQrCode(code.data);
      this.toggleVideoMedia();
    } else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }

  checkImageFromPhoto() {
    if (!navigator.mediaDevices) {
      const WIDTH = this.videoElm.nativeElement.clientWidth;
      const HEIGHT = this.videoElm.nativeElement.clientHeight;
      this.canvasElm.nativeElement.width = WIDTH;
      this.canvasElm.nativeElement.height = HEIGHT;

      const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;
      let image = new Image();
      image.src = 'data:image/jpg;base64,' + this.base64textString;
      image.addEventListener('load', () => {
        ctx.drawImage(image, 0, 0, WIDTH, HEIGHT)
        const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
        console.log('imageData')
        console.log(imageData)
        const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

        if (code) {
          console.log(code);
          this.emitQrCode(code.data);
        } else {
          console.log('no code');
          this.qrError = true;
        }
      }, false);
    }
  }

  handleFileInput(evt: any) {
    this.qrIncomplete = false;
    var files = evt.target.files;
    var file = files[0];
    this.fileName = file.name;
    this.fileType = file.type.split('/')[1];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }

  }

  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log(btoa(binaryString));
    this.checkImageFromPhoto();
  }

  openFileInput() {
    this.qrError = false;
    
    if (!navigator.mediaDevices) {
      let element: HTMLElement = document.querySelector("#file") as HTMLElement;
      element.click();
      setTimeout(()=>{this.qrIncomplete = true;},500)
      
    }
  }

}
