<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">

    <div class="row">
      <div *ngFor="let field of fields" [className]="field.class">
        <caiwa-add-form-content [field]="field" [form]="form"></caiwa-add-form-content>
      </div>
    </div>

    <span style="color: red">*Campi obbligatori</span>

    <div class="form-row d-flex justify-content-end" *ngFor="let button of text">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!form.valid" *ngIf="button.text">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button.text}}
      </button>
    </div>

  </form>
<!-- 
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div> -->
</div>