import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
})
export class TablesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
