import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataPropertyGetter'
})
export class DataPropertyGetterPipe implements PipeTransform {

  transform(object: any, keyName: string, ...args: unknown[]): unknown {
    if(typeof object[keyName] === 'string' || typeof object[keyName] === 'number'){  
        return object[keyName];
    }
    else{
        return object[keyName].name;
    }
  }

}