<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body">
          <vb-app-partials-jira-agile-board></vb-app-partials-jira-agile-board>
        </div>
      </div>
    </div>
  </div>
</div>
