import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-cards-tabbed',
  templateUrl: './tabbed.component.html',
})
export class CardsTabbedComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
