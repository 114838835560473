export class AnagraficaAnimale {
  id: number
  idRazza: number
  dataNascita: Date
  dataSvezzamento: Date
  dataMorte: Date
  rimontaNaturale: boolean
  dataRimonta: Date
  idMandria: number
  dataIngressoMandria: Date
}
