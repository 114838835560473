<div>
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'Google Analytics Home'}"
          ></vb-headers-card-header>
        </div>
      </div>
      <div class="card"><vb-widgets-charts-2></vb-widgets-charts-2></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'Ask analytics Intelligence'}"
          ></vb-headers-card-header>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-20></vb-widgets-lists-20></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-9></vb-widgets-charts-9></div>
      </div>
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'How do you acquire users?'}"
          ></vb-headers-card-header>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-5></vb-widgets-charts-5></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-10></vb-widgets-charts-10></div>
      </div>
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'How are your active users trending over time?'}"
          ></vb-headers-card-header>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-1></vb-widgets-charts-1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-lists-16></vb-widgets-lists-16></div>
      </div>
    </div>
  </div>
</div>
