import { Injectable } from '@angular/core'

import { of } from 'rxjs'
import { CustomDropdownFields } from 'src/app/models/shared/CustomDropdownField'
import { CustomField } from 'src/app/models/shared/CustomField'
import { CustomTextField } from 'src/app/models/shared/CustomTextField'
import { Role } from 'src/app/models/shared/Role'
import { HttpService } from '../http-services/http.services'

@Injectable()
export class FormService {
  role: Role[] = [];

  constructor(private httpService: HttpService) {
    this.getRole()
  }

  getRole() {
    this.httpService.get('Role/GetAllRole').subscribe(
      res => {
        if (res.isSuccess) {
          for (let r of res.role) {
            let o = new Role()
            o.active = r.active
            o.description = r.description
            o.id = r.id
            o.isOffice = r.isOffice
            o.name = r.name
            this.role.push(o)
          }
        }
      },
      err => {
        console.log(err)
      },
    )
  }

  getProfileFields() {
    const fields: CustomField<string>[] = [
      new CustomDropdownFields({
        key: 'role',
        label: 'Ruolo*',
        required: true,
        options: this.role,
        order: 6,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'username',
        label: 'Username*',
        type: 'username',
        required: true,
        hasBlankSpaces: true,
        order: 3,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'name',
        label: 'Nome*',
        value: '',
        required: true,
        order: 1,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'surname',
        label: 'Cognome*',
        value: '',
        required: true,
        order: 2,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'phone',
        label: 'Numero di telefono',
        value: '',
        order: 4,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'emailAddress',
        label: 'E-mail',
        type: 'email',
        order: 5,
        class: 'col-md-12',
      }),
    ]

    return of(fields.sort((a, b) => a.order - b.order))
  }

  getPasswordFields() {
    const fields: CustomField<string>[] = [
      new CustomTextField({
        key: 'password',
        label: 'Password Corrente*',
        value: '',
        required: true,
        order: 1,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'newPassword',
        label: 'Nuova Password*',
        value: '',
        required: true,
        order: 2,
        class: 'col-md-12',
      }),

      new CustomTextField({
        key: 'confirmPassword',
        label: 'Conferma Password*',
        value: '',
        required: true,
        order: 3,
        class: 'col-md-12',
      }),
    ]

    return of(fields.sort((a, b) => a.order - b.order))
  }

  getManageUsersFields() {
    const fields: CustomField<string>[] = [
      new CustomDropdownFields({
        key: 'idRole',
        label: 'Ruolo*',
        options: this.role,
        order: 5,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'name',
        label: 'Nome*',
        value: '',
        required: true,
        order: 1,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'surname',
        label: 'Cognome*',
        value: '',
        required: true,
        order: 2,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'phonenumber',
        label: 'Numero di telefono',
        value: '',
        order: 3,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'email',
        label: 'E-mail',
        type: 'email',
        order: 4,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'username',
        label: 'Username*',
        type: 'username',
        required: true,
        hasBlankSpaces: true,
        order: 6,
        class: 'col-md-3',
      }),

      new CustomTextField({
        key: 'password',
        label: 'Password*',
        type: 'text',

        required: true,
        order: 7,
        class: 'col-md-3',
      }),
    ]

    return of(fields.sort((a, b) => a.order - b.order))
  }

  getEditUsersFields() {
    const fields: CustomField<string>[] = [
      new CustomDropdownFields({
        key: 'idRole',
        label: 'Ruolo*',
        options: this.role,
        order: 5,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'name',
        label: 'Nome*',
        value: '',
        required: true,
        order: 1,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'surname',
        label: 'Cognome*',
        value: '',
        required: true,
        order: 2,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'phonenumber',
        label: 'Numero di telefono',
        value: '',
        required: true,
        order: 3,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'email',
        label: 'E-mail',
        type: 'email',
        order: 4,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'username',
        label: 'Username*',
        type: 'username',
        hasBlankSpaces: true,
        order: 6,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'password',
        label: 'Password*',
        type: 'password',
        placeholder: 'Lasciare campo vuoto per non modificare',
        order: 7,
        class: 'col-md-6',
      }),
    ]

    return of(fields.sort((a, b) => a.order - b.order))
  }

  getManageRolesFields() {
    const fields: CustomField<string>[] = [
      new CustomTextField({
        key: 'name',
        label: 'Ruolo*',
        value: '',
        required: true,
        order: 1,
        class: 'col-md-6',
      }),

      new CustomTextField({
        key: 'description',
        label: 'Descrizione*',
        value: '',
        required: true,
        order: 2,
        class: 'col-md-6',
      }),
    ]

    return of(fields.sort((a, b) => a.order - b.order))
  }
}
