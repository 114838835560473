<!-- <nz-spin [nzSpinning]="loading"> -->
    <!-- <nz-collapse>
      <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
        [nzDisabled]="panel.disabled">
        <app-m-edit-dati-consuntivi button='Aggiungi' (onButtonActive)="getData('')"></app-m-edit-dati-consuntivi>
      </nz-collapse-panel>
    </nz-collapse>
  </nz-spin>
  <br> -->
  <caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
    [updateBtn]="true"  [isFiltrable]="true" [loading]="loading"  [tableWidth]="tableWidth" tableSize="small"
    
    (updateBtnAction)="edit($event)" 
    (filterCustom)="true">
  <!-- </caiwa-generic-table> -->
