import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-nested-1',
  templateUrl: './1.component.html',
})
export class Nested1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
