import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Html5Qrcode } from 'html5-qrcode';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Attivita } from 'src/app/models/precisionfarming-models/Attivita';
import { HttpService } from 'src/app/services/shared/http-services/http.services';

@Component({
  selector: 'app-m-edit-dati-consuntivi',
  templateUrl: './m-edit-dati-consuntivi.component.html',
  styleUrls: ['./m-edit-dati-consuntivi.component.scss']
})
export class MEditDatiConsuntiviComponent implements OnInit {

  form: FormGroup
  formDatePicker: FormGroup
  idRazza: number;
  idMandria: number;
  currentDate: any
  currentDate1: any
  currentDate2: any
  currentDate3: any
  currentDate4: any
  selectedValue: any
  loadingSelect: boolean
  loading: boolean
  rimontaNaturale: boolean = false
  toClose: boolean = false
  deleteButton: boolean
  @Output() onClose = new EventEmitter()
  @Output() onButtonActive = new EventEmitter();
  @Input() item
  @Input() title
  @Input() isEdit = false
  @Input() button
  modalRef: BsModalRef;
  idProdotto: any;
  idMagazzino: any;
  idTipoAttivita: any;
  idMezzi: any;
  idAusiliari: any;
  idTerreni: any;
  idUtensili: any;
  idUtilizzoAusiliare: any;
  idContenitori: any;
  prodotti: any;
  magazzini: any;
  tipiAttivita: any;
  mezzi: any;
  ausiliari: any;
  terreni: any;
  utensili: any;
  utilizziAusiliari: any;
  nomeAusiliare: any
  ausiliareUtilizzoAusiliare: any = []
  @Input() isAdd = false
  placeholderUtensili: string = ""
  placeholderProdotti: string = ""
  placeholderUtilizziAusiliare: string = "Selezionare ausiliare"
  placeholderAusiliari: string = ""
  placeholderTerreni: string = ""
  placeholderMezzi: string = ""
  placeholderTipiAttivita: string = ""
  placeholderMagazzini: string = ""
  placeholderContenitori: string = ""
  allUtilizziAusiliari: any;
  scannerEnabled: boolean = false;
  qrNotification: boolean = true;
  fileName: any;
  fileType: any;
  base64textString: string;
  fileValue: any;
  showQRCode: boolean;
  qrcodeClass: string = "qrcode-reader-class";
  loadingQR: boolean = false;


  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: [''],
      descrizione: [''],
      dataInizio: [''],
      dataFine: [''],
      quantitaPrevista: [''],
      quantitaProdotta: [''],
      dataInizioEffettiva: [''],
    })
    this.formDatePicker = this.formBuilder.group({
      filterDate: ['']
    })
  }

  ngOnInit(): void {
    this.currentDate1 = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.currentDate = this.currentDate1
    this.currentDate2 = this.currentDate1
    this.currentDate3 = this.currentDate1
    this.currentDate4 = this.currentDate1
    this.getProdotto();
    this.getMagazzini();
    this.getTipoAttivita();
    this.getMezzi();
    this.getAusiliari();
    this.getTerreni();
    this.getUtensili();
    this.getAllUtilizzoAusiliare();
    if (this.item) {
      this.getData()
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit()
    }
  }

  postForm() {
    this.loading = true
    let f: Attivita = new Attivita()
    f.nome = this.f.nome.value
    f.descrizione = this.f.descrizione.value
    f.dataInizio = this.f.dataInizio.value
    f.dataFine = this.f.dataFine.value
    f.quantitaPrevista = this.f.quantitaPrevista.value
    f.idProdotto = this.idProdotto
    f.idMagazzino = this.idMagazzino
    f.idTipoAttivita = this.idTipoAttivita
    let mezziId: number[] = [];
    for (let i = 0; i < this.idMezzi.length; i++) {
      mezziId.push(this.idMezzi[i])
      console.log(this.idMezzi[i]);
    }
    f.idMezzi = mezziId
    f.idAusiliari = this.ausiliareUtilizzoAusiliare
    let terreniId: number[] = [];
    for (let i = 0; i < this.idTerreni.length; i++) {
      terreniId.push(this.idTerreni[i])
    }
    f.idTerreni = terreniId
    let utensiliId: number[] = [];
    for (let i = 0; i < this.idUtensili.length; i++) {
      utensiliId.push(this.idUtensili[i])
    }
    f.idUtensili = utensiliId

    if (this.isEdit) {
      f.id = this.item.id
      this.httpService.put('Attivita/EditAttivita', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.form.reset()
            this.clearId()
            this.closeModal()
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    } else {
      this.httpService.post('Attivita/AddAttivita', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.clearId()
            this.form.reset()
            this.closeModal()
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    }
  }

  getData() {
    console.log('GET DATA')
    this.loading = true
    this.httpService.get('Attivita/GetAttivitaById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          console.log('res attività')
          console.log(res)
          this.loading = false
          this.idProdotto = res.attivita.attivita.idProdotto
          this.idMagazzino = res.attivita.attivita.idMagazzino
          this.idTipoAttivita = res.attivita.attivita.idTipoAttivita
          let mezziId = [];
          for (let i = 0; i < res.attivita.attivitaMezzi.length; i++) {
            mezziId.push(res.attivita.attivitaMezzi[i].idMezzo)
          }
          this.idMezzi = mezziId
          let terreniId = [];
          for (let i = 0; i < res.attivita.attivitaTerreno.length; i++) {
            terreniId.push(res.attivita.attivitaTerreno[i].idTerreno)
          }
          this.idTerreni = terreniId
          let utensiliId = [];
          for (let i = 0; i < res.attivita.attivitaUtensili.length; i++) {
            utensiliId.push(res.attivita.attivitaUtensili[i].idUtensile)
          }
          this.idUtensili = utensiliId
          for (let i = 0; i < res.attivita.attivitaAusiliari.length; i++) {
            console.log('f da get')
            console.log(res.attivita.attivitaAusiliari[i].idAusiliare + " " + res.attivita.attivitaAusiliari[i].idUtilizzoAusiiare)
            this.ausiliareUtilizzoAusiliare.push({ id: res.attivita.attivitaAusiliari[i].idAusiliare, utilizzo: res.attivita.attivitaAusiliari[i].idUtilizzoAusiiare })
          }

          this.form.patchValue({
            nome: res.attivita.attivita.nome,
            descrizione: res.attivita.attivita.descrizione,
            quantitaPrevista: res.attivita.attivita.quantitaPrevista,
            dataInizio: formatDate(res.attivita.attivita.dataInizio, 'yyyy-MM-dd', 'en'),
            dataFine: formatDate(res.attivita.attivita.dataFine, 'yyyy-MM-dd', 'en'),
          })
        } else {
          this.loading = false
          console.log(res)
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  getProdotto() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/TipiProdotti').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.prodotti?.length) {
            this.placeholderProdotti = "Non ci sono prodotti"
          }
          this.prodotti = res.prodotti
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getMagazzini() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/Magazzini').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.magazzini?.length) {
            this.placeholderMagazzini = "Non ci sono magazzini"
          }
          this.magazzini = res.magazzini;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getTipoAttivita() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/TipiAttivita').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.tipiAttivita?.length) {
            this.placeholderTipiAttivita = "Non ci sono tipi attività"
          }
          this.tipiAttivita = res.tipiAttivita;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getMezzi() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/Mezzi').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.mezzi?.length) {
            this.placeholderMezzi = "Non ci sono mezzi"
          }
          this.mezzi = res.mezzi;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getAusiliari() {
    this.idUtilizzoAusiliare = undefined
    this.loadingSelect = true;
    this.httpService.get('DropDown/Ausiliare').subscribe(
      res => {
        if (res.isSuccess) {
          if (!res.ausiliare?.length) {
            this.placeholderAusiliari = "Non ci sono ausiliari"
          }
          this.loadingSelect = false
          this.ausiliari = res.ausiliare;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getTerreni() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/Terreni').subscribe(
      res => {
        if (res.isSuccess) {
          if (!res.terreni?.length) {
            this.placeholderTerreni = "Non ci sono terreni"
          }
          this.loadingSelect = false
          this.terreni = res.terreni;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getUtensili() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/Utensili').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.utensili?.length) {
            this.placeholderUtensili = "Non ci sono utensili"
          }
          this.utensili = res.utensili;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getUtilizzoAusiliare() {
    this.utilizziAusiliari = undefined
    this.placeholderUtilizziAusiliare = ""
    this.loadingSelect = true;
    this.httpService.get('UtilizzoAusiliare/GetUtilizzoAusiliareByAusiliareId?Id=' + this.idAusiliari).subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.utilizzoausiliare?.length) {
            this.placeholderUtilizziAusiliare = "Non ci sono utilizzi"
          }
          this.utilizziAusiliari = res.utilizzoausiliare;
          console.log(res.utilizzoausiliare)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getNameAusiliareById(id) {
    for (let item of this.ausiliari) {
      if (item.id == id) {
        return item.nome
      }
    }
  }

  getNameUtilizzoById(id) {
    for (let item of this.allUtilizziAusiliari) {
      if (item.id == id) {
        return item.nome
      }
    }
  }

  clearId() {
    this.idProdotto = undefined
    this.idMagazzino = undefined
    this.idTipoAttivita = undefined
    this.idMezzi = undefined
    this.idTerreni = undefined
    this.idUtensili = undefined
    this.idUtilizzoAusiliare = undefined
    this.idAusiliari = undefined
    this.ausiliareUtilizzoAusiliare = []
  }

  getAllUtilizzoAusiliare() {
    this.loadingSelect = true;
    this.httpService.get('UtilizzoAusiliare/GetUtilizzoAusiliare').subscribe(
      res => {
        if (res.isSuccess) {
          this.allUtilizziAusiliari = res.utilizzoausiliare;
        } else {
          console.log(res)
        }
      },
      err => {
        console.log(err)
      },
    )
  }

  getdataQR(qrCode) {
    this.loading = true
    this.httpService.get('QrCode/QrCodeSearch?QrCode=' + qrCode).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          this.notification.success('Lettura QR code effettuata con successo!')
          this.scannerEnabled = false
        } else {
          this.loading = false
          this.notification.error(res.errorMessageIta)
        }
      },
      err => {
        this.loading = false
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
      },
    )
  }

  onActivate(e) {
    console.log('event')
    console.log(e);
    if (e) {
      this.scannerEnabled = true
    } else {
      this.scannerEnabled = false
    }
  }

  getDataByDate(e) {
    console.log('input change')
    console.log(e)
  }


}
