<div class="modal-header" *ngIf="isEdit">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="nome" class="mb-0 col-form-label">Nome*</label>
          <input type="text" class="form-control" formControlName="nome" id="nome">
        </div>
        <div class="col-lg-4">
          <label for="cognome" class="mb-0 col-form-label">Cognome*</label>
          <input type="text" class="form-control" formControlName="cognome" id="cognome">
        </div>
        <div class="col-lg-4">
          <label for="pIva" class="mb-0 col-form-label">Partita IVA*</label>
          <input type="text" class="form-control" formControlName="pIva" id="pIva">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="indirizzo" class="mb-0 col-form-label">Indirizzo*</label>
          <input type="text" class="form-control" formControlName="indirizzo" id="indirizzo">
        </div>
        <div class="col-lg-4">
          <label for="email" class="mb-0 col-form-label">E-mail*</label>
          <input type="email" class="form-control" formControlName="email" id="email"
            [ngClass]="{ 'border-danger': f.email.errors && f.email.touched}">
          <div *ngIf="f.email.errors && f.email.touched " class="text-danger">Formato e-mail non valido!</div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!form.valid" (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>