<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Pricing Tables'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-lists-21></vb-widgets-lists-21></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-lists-21v1></vb-widgets-lists-21v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-lists-21v2></vb-widgets-lists-21v2></div>
    </div>
  </div>
</div>
