<div class="modal-header" *ngIf="isEdit || isAdd">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <nz-spin [nzSpinning]="loading">
    <div class="modal-body" style="position: relative;">
      <form [formGroup]="form">
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="nome" class="mb-0 col-form-label">Nome*</label>
            <input type="text" class="form-control" formControlName="nome" id="nome">
          </div>
          <div class="col-lg-8">
            <label for="descrizione" class="mb-0 col-form-label">Descrizione*</label>
            <input type="text" class="form-control" formControlName="descrizione" id="descrizione">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <span class="text-danger">*Campi obbligatori</span>
      <div class="form-row d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!form.valid"
          (click)="postForm()">
          <span class="btnAddon">
            <i class="btnAddonIcon fe fe-plus-circle"></i>
          </span>
          {{button}}
        </button>
      </div>
    </div>
  </nz-spin>
