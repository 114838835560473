import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-attivita-pianificate',
  templateUrl: './card-attivita-pianificate.component.html',
  styleUrls: ['./card-attivita-pianificate.component.scss']
})
export class CardAttivitaPianificateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
