export class Ausiliare {
  id: number
  nome: string
  idTipo: number
  idMagazzino: number
  idSottoTipo: number
  nomeCommerciale: string
  additivoVitaminaA: boolean
  additivoVitaminaD: boolean
  additivoVitaminaE: boolean
  bio: boolean
  utilizzi: any[]
}
