import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UtilizzoDeiezione } from 'src/app/models/precisionfarming-models/UtilizzoDeiezione';
import { HttpService } from 'src/app/services/shared/http-services/http.services';

@Component({
  selector: 'app-m-manage-utilizzo-deiezione',
  templateUrl: './m-manage-utilizzo-deiezione.component.html',
  styleUrls: ['./m-manage-utilizzo-deiezione.component.scss']
})
export class MManageUtilizzoDeiezioneComponent implements OnInit {

  form: FormGroup;
  currentDate: any;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false;
  @Input() button;
  idUtilizzoUtilizzoDeiezione: number;
  idTipoUtilizzoDeiezione: number;
  utilizziDeiezioni: any;
  tipiDeiezione: any;
  idTipoDeiezione: number;
  idUtilizzoDeiezione: number;
  placeholderTipiDeiezione: string = ""
  placeholderUtilizziDeiezione: string = ""


  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    if (this.item) {
      this.getData();
    }
    this.getTipiDeiezione()
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: UtilizzoDeiezione = new UtilizzoDeiezione()
    f.nome = this.f.nome.value
    f.idTipoDeiezione = this.idUtilizzoDeiezione;
    // f.idTipoDeiezione = this.idTipoDeiezione;

    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('UtilizzoDeiezione/EditUtilizzoDeiezione', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idUtilizzoDeiezione = undefined
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('UtilizzoDeiezione/AddUtilizzoDeiezione', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idUtilizzoDeiezione = undefined
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('UtilizzoDeiezione/GetUtilizzoDeiezioneById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          console.log('res utilizzo deiezione');
          console.log(res);
          this.idTipoUtilizzoDeiezione = res.utilizzoDeiezione.idTipoDeiezione;
          // this.idUtilizzoUtilizzoDeiezione = res.UtilizzoDeiezione.idUtilizzoUtilizzoDeiezione;
          this.form.patchValue({
            nome: res.utilizzoDeiezione.nome,
            
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }

  getTipiDeiezione() {
    this.placeholderTipiDeiezione = "";
    this.loadingSelect = true;
    this.httpService.get('DropDown/TipiDeiezione').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.tipiDeiezione?.length) {
            this.placeholderTipiDeiezione = "Non ci sono tipi deiezione"
          }
          console.log('tipi deiezione')
          console.log(res)
          this.tipiDeiezione = res.tipiDeiezione
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  // getUtilizziDeiezione() {
  //   this.loadingSelect = true;
  //   this.httpService.get('').subscribe(
  //     res => {
  //       if (res.isSuccess) {
  //         this.loadingSelect = false
  // placeholder
  //         console.log('razza')
  //         console.log(res)
  //         this.utilizziDeiezioni = res.utilizziDeiezioni
  //       } else {
  //         this.loadingSelect = false
  //         console.log(res)
  //       }
  //     },
  //     err => {
  //       this.loadingSelect = false
  //       console.log(err)
  //     },
  //   )
  // }

}
