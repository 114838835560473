<div class="m-1">
  <div class="mb-4">
  <nz-collapse >
    <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
      [nzDisabled]="panel.disabled">
        <app-m-edit-utensile button='Aggiungi' (onButtonActive)="getData('')"></app-m-edit-utensile>
    </nz-collapse-panel>
  </nz-collapse>
</div>
<caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
[updateBtn]="true" [deleteBtn]="true" [isFiltrable]="true" [loading]="loading" [tableWidth]="tableWidth"
(updateBtnAction)="edit($event)" (deleteBtnAction)="delete($event)" (filterCustom)="getData($event)"
(actionBtnAction)="qrCode($event)" actionBtn='true' actionDescription='QR code' actionIcon="fa fa-qrcode"
tableSize="small">
</caiwa-generic-table>
</div>