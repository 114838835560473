import { Component, OnInit } from '@angular/core'

import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { MEditSensoreComponent } from 'src/app/modals/precisionfarming-modals/m-edit-sensore/m-edit-sensore.component'
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn'
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService'

@Component({
  selector: 'app-sensore',
  templateUrl: './sensore.component.html',
  styleUrls: ['./sensore.component.scss'],
  providers: [BsModalService],
})
export class SensoreComponent implements OnInit {
  currentDate: any
  selectedValue: any
  loadingSelect: boolean
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  deleteButton: boolean = false
  modalRef: BsModalRef
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Temperatura', dataKey: 'temperatura', columnWidth: '170px' },
    { name: 'Umidità aria', dataKey: 'umiditaAria', columnWidth: '170px' },
    { name: 'Umidità aria misura', dataKey: 'umiditaAriaMisura', columnWidth: '170px' },
    { name: 'Umidità terreno', dataKey: 'umiditaTerreno', columnWidth: '170px' },
    { name: 'Umidità terreno misura', dataKey: 'umiditaTerrenoMisura', columnWidth: '170px' },
    { name: 'pH', dataKey: 'ph', columnWidth: '170px' },
    { name: 'Luce', dataKey: 'luce', columnWidth: '170px' },
    { name: 'Luce misura', dataKey: 'luceMisura', columnWidth: '170px' },
    { name: 'Terreno', dataKey: 'terreno', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any
  panels = [
    {
      active: false,
      name: 'Aggiungi sensore',
      disabled: false,
    },
  ]

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {}

  ngOnInit(): void {
    this.getData('')
  }

  getData(event) {
    this.loading = true
    this.httpService.get('Sensore/GetSensore' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          //
          this.data$ = res.sensori
          console.log('res')
          console.log(this.data$)
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  edit(item) {
    this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        item: item,
        title: 'Dettagli sensore',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditSensoreComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi sensore',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditSensoreComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  delete(item) {
    this.loading = true
    let id = { id: item.id }
    this.httpService.delete('Sensore/DeleteSensore', id).subscribe(
      res => {
        if (res.isSuccess) {
          this.notification.success('Operazione effettuata con successo!')
          this.getData('')
        } else {
          this.notification.error(res.errorMessageIta)
        }
        console.log('res add user')
        console.log(res)
        // this.getRole('')
        this.loading = false
      },
      err => {
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
        this.loading = false
      },
    )
  }
}
