import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AnagraficaAnimale } from 'src/app/models/precisionfarming-models/AnagraficaAnimale';
import { Mezzo } from 'src/app/models/precisionfarming-models/Mezzo';
import { HttpService } from 'src/app/services/shared/http-services/http.services';

@Component({
  selector: 'app-m-edit-mezzo',
  templateUrl: './m-edit-mezzo.component.html',
  styleUrls: ['./m-edit-mezzo.component.scss']
})
export class MEditMezzoComponent implements OnInit {

  form: FormGroup;
  currentDate: any;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false;
  @Input() button;

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
      marca: ['', Validators.required],
      modello: ['', Validators.required],
      anno: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.currentDate = formatDate(new Date(), 'yyyy', 'en');
    if (this.item) {
      this.getData();
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: Mezzo = new Mezzo()
    f.nome = this.f.nome.value
    f.marca = this.f.marca.value
    f.modello = this.f.modello.value
    f.anno = this.f.anno.value.trim() + '-01-01T00:00:00.000Z'

    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('Mezzo/EditMezzo', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('Mezzo/AddMezzo', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('Mezzo/GetMezzoById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          this.form.patchValue({
            nome: res.mezzo.nome,
            marca: res.mezzo.marca,
            modello: res.mezzo.modello,
            anno: formatDate(res.mezzo.anno, 'yyyy', 'en')
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }

}
