<div class="modal-header" *ngIf="isEdit">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="nome" class="mb-0 col-form-label">Nome*</label>
          <input type="text" class="form-control" formControlName="nome" id="nome">
        </div>
        <div class="col-lg-4">
          <label for="materiale" class="mb-0 col-form-label">Materiale*</label>
          <input type="text" class="form-control" formControlName="materiale" id="materiale">
        </div>
        <div class="col-lg-4">
          <label for="idMagazzino" class="mb-0 col-form-label">Magazzino*</label>
          <nz-select [(ngModel)]="idMagazzino" [ngModelOptions]="{standalone: true}" id="idMagazzino" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderMagazzini" [nzDisabled]="!magazzini">
            <nz-option *ngFor="let opt of magazzini" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addMagazzino()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi magazzino</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/warehouses">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci magazzino</span>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="colore" class="mb-0 col-form-label">Colore*</label>
          <input type="text" class="form-control" formControlName="colore" id="colore">
        </div>
        <div class="col-lg-4">
          <label for="portataMassima" class="mb-0 col-form-label">Portata massima*</label>
          <input type="number" min="0" class="form-control" formControlName="portataMassima" id="portataMassima">
        </div>
        <div class="col-lg-4">
          <label for="tipo" class="mb-0 col-form-label">Tipo*</label>
          <input type="text" class="form-control" formControlName="tipo" id="tipo">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!(form.valid && idMagazzino)"
        (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>