<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-1></vb-widgets-charts-1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART2'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-2></vb-widgets-charts-2></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART9'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-9></vb-widgets-charts-9></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART5'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-5></vb-widgets-charts-5></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART10'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-10></vb-widgets-charts-10></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART6'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-6></vb-widgets-charts-6></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 4'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4></vb-widgets-charts-4></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 4-2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v2></vb-widgets-charts-4v2></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 4-1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v1></vb-widgets-charts-4v1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 4-3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v3></vb-widgets-charts-4v3></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 7'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-7></vb-widgets-charts-7></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-3></vb-widgets-charts-3></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 8'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-8></vb-widgets-charts-8></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 11'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-11></vb-widgets-charts-11></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 11-1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-11v1></vb-widgets-charts-11v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 11-2'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-11v2></vb-widgets-charts-11v2></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 12'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-12></vb-widgets-charts-12></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 12-1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-charts-12v1></vb-widgets-charts-12v1></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 13'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-13></vb-widgets-charts-13></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 13-1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-13v1></vb-widgets-charts-13v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'CHART / 13-2'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-charts-13v2></vb-widgets-charts-13v2></div>
    </div>
  </div>
</div>
