<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Bootstrap Tables','button':'Go to Docs','url':'https://getbootstrap.com/docs/5.0/content/tables/'}"
          ></vb-headers-heading-3>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Basic'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-2></vb-tables-bootstrap-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Dark table'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-3></vb-tables-bootstrap-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Striped rows'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-4></vb-tables-bootstrap-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Bordered table'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-5></vb-tables-bootstrap-5></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Borderless table'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-6></vb-tables-bootstrap-6></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Hoverable rows'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-7></vb-tables-bootstrap-7></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Small table'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-8></vb-tables-bootstrap-8></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Responsive table'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-bootstrap-9></vb-tables-bootstrap-9></div>
      </div>
    </div>
  </div>
</div>
