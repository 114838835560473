export class LuogoAnimali {
  id: number
  recintato: boolean
  geolocalizzazione: string
  superficeMinimaSpazioAlChiuso: number
  superficeMinimaSpazioAperto: number
  descrizioneDettagliTecnici: string
  densitaTotaleAllevamento: number
  azotoOrganicoAnnoEttaro: number
  bio: boolean
}
