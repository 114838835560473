<div>
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <div class="card">
        <div class="card-body"><vb-app-partials-wp-post-short></vb-app-partials-wp-post-short></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-app-partials-wp-post-short></vb-app-partials-wp-post-short></div>
      </div>
      <div class="card-placeholder">
        <div class="card-body"><vb-controls-pagination></vb-controls-pagination></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-28></vb-widgets-lists-28></div>
      </div>
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-4></vb-widgets-lists-4></div>
      </div>
    </div>
  </div>
</div>
