import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { User } from 'src/app/models/shared/User'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class jwtAuthService {
  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')),
    )
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  login(email, password, rememberMe) {
    return this.http
      .post<any>(`${environment.apiUrl}Login/Authenticate`, {
        email,
        password,
        rememberMe
      })
      .pipe(
        map(
          user => {
            console.log(user)

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.token) {
              localStorage.setItem('currentUser', JSON.stringify(user))
              this.currentUserSubject.next(user)
            }
            return user
          },
          err => {
            console.log(err)
          },
        ),
      )
  }

  logout() {
    // remove user from local storage and set current user to null
    this.currentUserSubject.next(null)
  }

  //  currentAccount(): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //       ? {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //             AccessToken: accessToken,
  //           },
  //         }
  //       : {}

  //     return this.http.get('/api/auth/account', params)
  //   }

  //     register(email: string, password: string, name: string): Observable<any> {
  //     // return this.http.post('/api/auth/register', { email, password, name })
  //     return null
  //   }

  // @Injectable()
  // export class jwtAuthService {

  //   constructor(private http: HttpClient) {
  //
  // }

  // public get currentUserValue(): User {

  //     return this.currentUserSubject.value;
  // }

  // login(username, password, rememberMe) {
  //     return this.http.post<any>(`http://5.249.154.100:28794/api/User/Authenticate`, { username, password, rememberMe })
  //         .pipe(map(user => {
  //             console.log(user);

  //             // store user details and jwt token in local storage to keep user logged in between page refreshes
  //             localStorage.setItem('currentUser', JSON.stringify(user));
  //             this.currentUserSubject.next(user);
  //             return user;
  //         }, err=>{
  //             console.log(err);

  //         }));
  // }

  // logout() {
  //     // remove user from local storage and set current user to null
  //     this.currentUserSubject.next(null);
  // }

  // constructor(private http: HttpClient) {}

  // login(email: string, password: string): Observable<any> {
  //   return this.http.post('/api/auth/login', { email, password })
  // }

  // register(email: string, password: string, name: string): Observable<any> {
  //   return this.http.post('/api/auth/register', { email, password, name })
  // }

  // currentAccount(): Observable<any> {
  //   const accessToken = store.get('accessToken')
  //   const params = accessToken
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           AccessToken: accessToken,
  //         },
  //       }
  //     : {}

  //   return this.http.get('/api/auth/account', params)
  // }

  // logout(): Observable<any> {
  //   return this.http.get('/api/auth/logout')
  // }
}
