import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crea-fattura',
  templateUrl: './crea-fattura.component.html',
  styleUrls: ['./crea-fattura.component.scss']
})
export class CreaFatturaComponent implements OnInit {

  listOfData: any[] = [
    {
      id: '1',
      prodotto: 'prodotto1',
      descrizione: 'descrizioneProdotto1',
      quantita: 2,
      prezzoUnitario: 189
    },
    {
      id: '2',
      prodotto: 'prodotto2',
      descrizione: 'descrizioneProdotto2',
      quantita: 1,
      prezzoUnitario: 54
    },
    {
      id: '3',
      prodotto: 'prodotto3',
      descrizione: 'descrizioneProdotto3',
      quantita: 5,
      prezzoUnitario: 36
    }
  ];
  totaleParziale: number;
  currentDate: string;

  constructor() {
    this.currentDate = formatDate(new Date(), 'dd/MM/yyyy', 'en');
   }

  ngOnInit(): void {
  }

  getTotaleParziale() {
    let tot = [];
  this.listOfData.forEach(element => {

    tot.push(element.quantita * element.prezzoUnitario);
      
    });
    const sum = tot.reduce((partial_sum, a) => partial_sum + a, 0);
    return sum;
  }

}
