<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-app-partials-github-head></vb-app-partials-github-head></div>
        <div class="card-body">
          <vb-app-partials-github-head-issue></vb-app-partials-github-head-issue>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <div class="card-placeholder">
        <div class="card-body">
          <vb-app-partials-github-discuss></vb-app-partials-github-discuss>
        </div>
        <div class="card-body"><vb-app-partials-github-write></vb-app-partials-github-write></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-25></vb-widgets-lists-25></div>
      </div>
    </div>
  </div>
</div>
