<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body d-flex justify-content-center align-items-center" style="position: relative;">
    <!-- <qr-code [value]="id" size="300" errorCorrectionLevel="M" #qrcode></qr-code> -->
    <!-- <qrcode [qrdata]="id" [width]="256" [errorCorrectionLevel]="'M'" #qrcode></qrcode> -->
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="id"
      cssClass="QRCode"></ngx-qrcode>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="submit" class="btn btn-primary btnWithAddon" (click)="downloadQRCode()">
      <span class="btnAddon">
        <i class="btnAddonIcon fe fe-download"></i>
      </span>
      Download
    </button>
  </div>
</nz-spin>