import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileAttachement } from 'src/app/models/shared/FileAttachement';

@Component({
  selector: 'caiwa-generic-upload',
  templateUrl: './c-generic-upload.component.html',
  styleUrls: ['./c-generic-upload.component.scss']
})
export class CGenericUploadComponent implements OnInit {
  fileName: string;
  fileType: string;
  fileAttachedInAdd: FileAttachement[] = [];
  base64textString: string = '';
  @Input() classDivFileUpload: string;
  @Input() classDivAttachement: string;
  @Input() textConfirmDelete: string = 'Sicuro di voler eliminare?';
  @Input() idInput: string = "base64Image";
  @Input() attachementItems: FileAttachement[] = [];
  @Output() addAttachement: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAttachement: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  emitAttachement(item) {
    this.addAttachement.emit(item);
  }

  emitDeleteAttachement(item) {
    this.deleteAttachement.emit(item);
  }

  handleFileInput(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    this.fileName = file.name;
    this.fileType = file.type.split('/')[1];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString); 
    console.log(btoa(binaryString));
   
    let a: FileAttachement = new FileAttachement();
    a.fileName = this.fileName;
    a.fileType = this.fileType;
    a.data = this.base64textString;

    this.fileAttachedInAdd.push(a);
    this.emitAttachement(this.fileAttachedInAdd);
  }

  openFileBrowser(event: any) { 
    event.preventDefault();
    let element: HTMLElement = document.querySelector("#" + this.idInput) as HTMLElement;
    element.click()
  }

  removeFile() {
    this.fileAttachedInAdd = []; 
  }

  removeArrayItem(index) {
    this.fileAttachedInAdd.splice(index, 1);
    this.emitAttachement(this.fileAttachedInAdd);
  }

}
