<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Ecommerce Product Catalog'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-controls-pagination></vb-controls-pagination></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-controls-pagination></vb-controls-pagination></div>
      </div>
    </div>
  </div>
</div>
