<!-- <nz-spin [nzSpinning]=""> -->
<div class="row">
  <div class="col-lg-12">
    <div class="card-placeholder">
      <div class="card-header">
        <span class="fw-bold dark-text">Statistiche generali</span>
        <!-- <vb-headers-heading [data]="{'title':'Statistiche generali'}"></vb-headers-heading> -->
      </div>
    </div>
  </div>
</div>
<div class="row mb-5">
  <div class="col-lg-4 col-md-12">
    <!-- <app-c-card-dashboard title="Totale impiegati" data="9" icon="mr-3 fe fe-users"></app-c-card-dashboard> -->
    <app-c-card-dashboard title="Totale impiegati" data="9" icon="mr-3 fa fa-user"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale animali" data="1200" icon="mr-3 fe fe-trending-up"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale operai" data="54" icon="mr-3 fa fa-user"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale stalle" data="5" icon="mr-3 fe fe-trending-up"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale spese" data="29854" currency="true" icon="mr-3 fa fa-euro"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale ricavi" data="48975" currency="true" icon="mr-3 fa fa-euro"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Produzione oggi" data="12" icon="mr-3 fe fe-trending-up"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Valore" data="3456" currency="true" icon="mr-3 fa fa-euro"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Vendite oggi" data="2345" currency="true" icon="mr-3 fa fa-euro"></app-c-card-dashboard>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card-placeholder">
      <div class="card-header">
        <span class="fw-bold dark-text">Colture in corso</span>
        <!-- <vb-headers-heading [data]="{'title':'Statistiche generali'}"></vb-headers-heading> -->
      </div>
    </div>
  </div>
</div>
<div class="row mb-5">
  <div class="col-lg-4 col-md-12 cursor" *ngFor="let item of cardColture">
    <app-c-card-dashboard cardCosti="true" [title]="item.nome" [data]="item.costoKg" currencyOnKg="true" [production]="item.produzioneAttesa" icon="mr-3 fa fa-euro" [id]="item.id"></app-c-card-dashboard>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card-placeholder">
      <div class="card-header">
        <span class="fw-bold dark-text">Raccolto</span>
        <!-- <vb-headers-heading [data]="{'title':'Statistiche generali'}"></vb-headers-heading> -->
      </div>
    </div>
  </div>
</div>
<!-- <div class="row mb-5">
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale impiegati" data="9" icon="mr-3 fe fe-users"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale animali" data="1200" icon="mr-3 fe fe-trending-up"></app-c-card-dashboard>
  </div>
  <div class="col-lg-4 col-md-12">
    <app-c-card-dashboard title="Totale operai" data="54" icon="mr-3 fe fe-users"></app-c-card-dashboard>
  </div>
</div> -->
<div class="row">
  <div class="col-lg-12">
    <div class="card-placeholder">
      <div class="card-header">
        <span class="fw-bold dark-text">Attività principali</span>
        <!-- <vb-headers-heading [data]="{'title':'Attività'}"></vb-headers-heading> -->
      </div>
    </div>
  </div>
</div>
<app-chart title='Totale Attività' titleData='78,367'></app-chart>
<!-- </nz-spin> -->