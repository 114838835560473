import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Fabbricato } from 'src/app/models/precisionfarming-models/Fabbricato'
import { HttpService } from 'src/app/services/shared/http-services/http.services'

@Component({
  selector: 'app-m-edit-fabbricato',
  templateUrl: './m-edit-fabbricato.component.html',
  styleUrls: ['./m-edit-fabbricato.component.scss'],
})
export class MEditFabbricatoComponent implements OnInit {
  form: FormGroup
  loadingSelect: boolean
  loading: boolean
  toClose: boolean = false
  deleteButton: boolean
  checked: boolean
  @Output() onClose = new EventEmitter()
  @Output() onButtonActive = new EventEmitter();
  @Input() item
  @Input() title
  @Input() isEdit = false
  @Input() button

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
      descrizione: ['', Validators.required],
      geolocalizzazione: ['', Validators.required],
      grandezza: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    if (this.item) {
      this.getData()
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit()
    }
  }

  postForm() {
    this.loading = true
    let f: Fabbricato = new Fabbricato()
    f.nome = this.f.nome.value
    f.descrizione = this.f.descrizione.value
    f.bio = this.checked
    f.geolocalizzazione = this.f.geolocalizzazione.value
    f.grandezza = this.f.grandezza.value

    if (this.isEdit) {
      f.id = this.item.id
      this.httpService.put('Fabbricato/EditFabbricato', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.form.reset()
            this.closeModal()
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    } else {
      this.httpService.post('Fabbricato/AddFabbricato', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.form.reset()
            this.closeModal()
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    }
  }

  getData() {
    this.loading = true
    this.httpService.get('Fabbricato/GetFabbricatoById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          this.checked = res.fabbricato.bio
          this.form.patchValue({
            nome: res.fabbricato.nome,
            descrizione: res.fabbricato.descrizione,
            geolocalizzazione: res.fabbricato.geolocalizzazione,
            grandezza: res.fabbricato.grandezza,
          })
        } else {
          this.loading = false
          console.log(res)
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }
}
