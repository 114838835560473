<!-- BASE: src\app\@vb\widgets\WidgetsGeneral\10v1\10v1.component.html -->

<div class="d-flex flex-wrap flex-column align-items-center">
    <div class="vb__utils__avatar vb__utils__avatar--size64 mb-3 ">
      <nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="60" class="avatar"></nz-avatar>
    </div>
    <div class="text-center">
      <div class="text-dark font-weight-bold font-size-18">{{name}} {{surname}}</div>
      <div class="text-uppercase font-size-12 mb-3">{{role}}</div>
      <!-- <button type="button" class="btn btn-primary btnWithAddon">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        Request Access
      </button> -->
    </div>
  </div>
