import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn';
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService';

@Component({
  selector: 'app-produzione-dati-sensore',
  templateUrl: './produzione-dati-sensore.component.html',
  styleUrls: ['./produzione-dati-sensore.component.scss']
})
export class ProduzioneDatiSensoreComponent implements OnInit {
  currentDate: any
  selectedValue: any
  loadingSelect: boolean
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  deleteButton: boolean = false
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Temperatura', dataKey: 'temperatura', columnWidth: '170px' },
    { name: 'Umidità aria', dataKey: 'umiditaAria', columnWidth: '170px' },
    { name: 'Umidità aria misura', dataKey: 'umiditaAriaMisura', columnWidth: '170px' },
    { name: 'Umidità terreno', dataKey: 'umiditaTerreno', columnWidth: '170px' },
    { name: 'Umidità terreno misura', dataKey: 'umiditaTerrenoMisura', columnWidth: '170px' },
    { name: 'pH', dataKey: 'ph', columnWidth: '170px' },
    { name: 'Luce', dataKey: 'luce', columnWidth: '170px' },
    { name: 'Luce misura', dataKey: 'luceMisura', columnWidth: '170px' },
    { name: 'Terreno', dataKey: 'idTerreno', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any
  terreni: any;
  idTerreno: number

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {}

  ngOnInit(): void {
    this.getData('')
    this.getTerreni()
  }

  getData(event) {
    this.loading = true
    this.httpService.get('Sensore/GetSensore' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          //
          this.data$ = res.sensori
          console.log('res')
          console.log(this.data$)
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  getTerreni() {
    this.loadingSelect = true;
    this.httpService.get('DropDown/Terreni').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          this.terreni = res.terreni
          console.log('terreni')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

}
