import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-ecommerce-product-catalog',
  templateUrl: './product-catalog.component.html',
})
export class EcommerceProductCatalogComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
