<div class="form-group row">
  <div [className]="classDivFileUpload">
    <div class="form-group">
      <label>File upload</label>
      <input (change)="handleFileInput($event)" type="file" class="file-upload-default" [id]="idInput">
      <div class="input-group col-xs-6" (click)="openFileBrowser($event)">
        <input type="text" class="form-control file-upload-info" disabled=""
          placeholder="File supported: .pdf, .png, .jpg, .jpeg, .jpe, .bmp, .gif">
        <span class="input-group-append">
          <button class="btn btn-primary btn-upload" type="button">Upload</button>
        </span>
      </div>
    </div>
  </div>
  <div [className]="classDivAttachement">
    <div class="form-group" *ngIf="fileAttachedInAdd[0] || attachementItems[0]">
      <label for="exampleFormControlSelect1">Attachement</label>
      <table class="container-fluid">
        <tbody class="tbody-border-top">
          <tr class="tr-border-bottom" *ngFor="let file of fileAttachedInAdd; let i = index" [attr.data-index]="i">
            <td class="col-2 text-truncate">{{file.fileName}}</td>
            <td class="d-flex justify-content-end p-2">
              <a nz-popconfirm [nzPopconfirmTitle]="textConfirmDelete" nzOkType="danger" nzOkText="Elimina"
                nzCancelText="Annulla" nzPopconfirmPlacement="bottom" (nzOnConfirm)="removeArrayItem(i)"
                (nzOnCancel)="cancel()">
                <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Elimina" class="btn btn-outline-danger">
                  <i class="fe fe-trash-2"></i>
                </button>
              </a>
            </td>
          </tr>
          <tr class="tr-border-bottom" *ngFor="let file of attachementItems">
            <td class="col-2 text-truncate">{{file.fileName}}</td>
            <td class="d-flex justify-content-end p-2">
              <a nz-popconfirm [nzPopconfirmTitle]="textConfirmDelete" nzOkType="danger" nzOkText="Elimina"
                nzCancelText="Annulla" nzPopconfirmPlacement="bottom" (nzOnConfirm)="emitDeleteAttachement(file.id)"
                (nzOnCancel)="cancel()">
                <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Elimina" class="btn btn-outline-danger">
                  <i class="fe fe-trash-2"></i>
                </button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>