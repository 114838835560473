import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produzione',
  templateUrl: './produzione.component.html',
  styleUrls: ['./produzione.component.scss']
})
export class ProduzioneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }


}
