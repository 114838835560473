<div class="card card-top card-top-primary">
  <div class="card-body">
    <nz-tabset [nzSelectedIndex]="0">
      <nz-tab nzTitle="Inserimento dati consuntivi">
        <app-produzione-dati-consuntivi></app-produzione-dati-consuntivi>
      </nz-tab>
      <nz-tab nzTitle="Attività pianificate">
        
      </nz-tab>
      
    </nz-tabset>
  </div>
</div>