import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
})
export class AppsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
