
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AnagraficaAnimale } from 'src/app/models/precisionfarming-models/AnagraficaAnimale';
import { Contenitore } from 'src/app/models/precisionfarming-models/Contenitore';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { MEditMagazzinoComponent } from '../m-edit-magazzino/m-edit-magazzino.component';

@Component({
  selector: 'app-m-edit-contenitore',
  templateUrl: './m-edit-contenitore.component.html',
  styleUrls: ['./m-edit-contenitore.component.scss'],
  providers: [BsModalService],
})
export class MEditContenitoreComponent implements OnInit {

  form: FormGroup;
  idMagazzino: number;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false
  @Input() button;
  magazzini: any;
  modalRef: BsModalRef;
  placeholderMagazzini: string = ""

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
      materiale: ['', Validators.required],
      colore: ['', Validators.required],
      portataMassima: ['', Validators.required],
      tipo: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.getMagazzini();
    if (this.item) {
      this.getData();
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: Contenitore = new Contenitore()
    f.nome = this.f.nome.value
    f.materiale = this.f.materiale.value
    f.idMagazzino = this.idMagazzino
    f.colore = this.f.colore.value
    f.portataMassima = this.f.portataMassima.value
    f.tipo = this.f.tipo.value

    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('Contenitore/EditContenitore', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idMagazzino  = undefined
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('Contenitore/AddContenitore', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idMagazzino  = undefined
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('Contenitore/GetContenitoreById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          this.idMagazzino = res.contenitore.idMagazzino
          this.form.patchValue({
            nome: res.contenitore.nome,
            materiale: res.contenitore.materiale,
            colore: res.contenitore.colore,
            portataMassima: res.contenitore.portataMassima,
            tipo: res.contenitore.tipo
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }

  getMagazzini() {
    this.placeholderMagazzini = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Magazzini').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.magazzini?.length) {
            this.placeholderMagazzini = "Non ci sono magazzini"
          }
          this.magazzini = res.magazzini
          console.log('magazzini')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  addMagazzino() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi magazzino',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditMagazzinoComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getMagazzini()
    })}

}
