<div class="col-md-3 p-0">
  <input type="text" (input)="onSearchChange($event.target.value)" placeholder="Cerca"
    class="rounded-0 border-top-0 border-right-0 border-left-0 form-control mb-3">
</div>

<nz-table #dynamicTable id="genericTable" #rowSelectionTable nzShowPagination nzShowSizeChanger nzVirtualScroll
  [nzData]="items" [nzLoading]="loading" (nzCurrentPageDataChange)="currentPageDataChange($event)"
  class="vb__utils__table mb-4" nzTableLayout="fixed" [nzScroll]="actionColumnSticky ? { x: tableWidth } : null"
  [nzSize]="tableSize">
  <thead>
    <tr>
      <th *ngIf="isCheckable" nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="checkAll($event)"></th>
      <th *ngFor="let column of displayedColumns" [nzWidth]="column.columnWidth" style="white-space: nowrap;"
        nzEllipsis>{{column.name}}</th>
      <th [nzRight]="actionColumnSticky ? '-1px' : false" *ngIf="updateBtn || attachmentsBtn || deleteBtn"
        [nzWidth]="actionBtn ? '135px' : '100px'" class="text-center" style="white-space: nowrap;">Opzioni</th>
    </tr>
  </thead>
  <tbody>
    <ng-template nz-virtual-scroll ngFor let-data [ngForOf]="dynamicTable.data">
      <tr (click)="emitRowClickAction()" [style]="{'cursor': rowClick ? 'pointer' : '' }"></tr>
      <td *ngFor="let list of data | keys: displayedColumns" class="p-2 pl-3 pr-3" style="white-space: nowrap;"
        nzEllipsis>{{checkDate(data[list])}}</td>
      <td nzRight="-1px" *ngIf="updateBtn || attachmentsBtn || deleteBtn || actionBtn"
        [className]="actionColumnSticky ? 'd-flex justify-content-center p-2 shadow' : 'd-flex justify-content-center p-2'"
        nzWidth="100px">
        <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Dettagli" class="btn btn-outline-primary"
          *ngIf="updateBtn" (click)="emitUpdateBtnAction(data);">
          <i class="icon fe fe-maximize"></i>
        </button>
        <button nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="actionDescription" 
          class="btn btn-outline-secondary ml-2" *ngIf="actionBtn" (click)="emitActionBtnAction(data)">
          <i [className]="actionIcon"></i>
        </button>
        <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Allegati" 
          class="btn btn-outline-secondary ml-2 d-none" *ngIf="attachmentsBtn" (click)="emitAttachmentsBtnAction(data)">
          <i class="icon fe fe-paperclip"></i>
        </button>
        <a nz-popconfirm [nzPopconfirmTitle]="textConfirmDelete" nzOkType="danger" nzOkText="Elimina"
          nzCancelText="Annulla" nzPopconfirmPlacement="bottom" (nzOnConfirm)="emitDeleteBtnAction(data)"
          (nzOnCancel)="cancel()">
          <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Elimina" *ngIf="deleteBtn" 
            class="btn btn-outline-danger ml-2">
            <i class="icon fe fe-trash-2"></i>
          </button>
        </a>
      </td>
    </ng-template>
  </tbody>
</nz-table>