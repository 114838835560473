import { Component } from '@angular/core'
import { FormService } from 'src/app/services/shared/form-services/FormService';

@Component({
  selector: 'app-apps-profile',
  templateUrl: './profile.component.html',
  providers: [FormService]
})
export class AppsProfileComponent {

  constructor() {
  }

}
