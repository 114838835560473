export const getMenuDataAdmin: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  // {
  //   category: true,
  //   title: 'Dashboards',
  // },
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  // {
  //   category: true,
  //   title: 'Apps & Pages',
  //   key: '2t2ghm',
  // },
  {
    title: 'Attività',
    url: '/activity',
    icon: 'fa fa-align-right',
    children: [
      {
        title: 'Produzione',
        url: '/activity/admin-production',
      },
      {
        title: 'Pulizia',
        url: '/activity/admin-cleaning',
      },
    ],
  },
  {
    title: 'Certificazione Bio',
    url: '/organic-certification',
    icon: 'fa fa-leaf',
  },
  {
    title: 'Prodotti',
    url: '/products',
    icon: 'fe fe-package',
  },
  {
    title: 'Ausiliari',
    url: '/auxiliaries',
    icon: 'fa fa-flask',
  },
  {
    title: 'Gestione dati',
    url: '/manage-data',
    icon: 'fa fa-bar-chart',
    children: [
      {
        title: 'QR code',
        url: '/manage-data/qr-code',
      },
      {
        title: 'Anagrafiche animale',
        url: '/manage-data/animals-registry',
      },
      {
        title: 'Ausiliari',
        url: '/manage-data/auxiliaries',
      },
      {
        title: 'Clienti',
        url: '/manage-data/customers',
      },
      {
        title: 'Contenitori',
        url: '/manage-data/containers',
      },
      {
        title: 'Deiezioni',
        url: '/manage-data/droppings',
      },
      {
        title: 'Fabbricati',
        url: '/manage-data/buildings',
      },
      {
        title: 'Farmaci',
        url: '/manage-data/drugs',
      },
      {
        title: 'Fornitori',
        url: '/manage-data/suppliers',
      },
      {
        title: 'Luoghi animali',
        url: '/manage-data/places',
      },
      {
        title: 'Magazzino',
        url: '/manage-data/warehouses',
      },
      {
        title: 'Mezzi',
        url: '/manage-data/vehicles',
      },
      {
        title: 'Prodotti',
        url: '/manage-data/products',
      },
      {
        title: 'Sensori',
        url: '/manage-data/sensors',
      },
      {
        title: 'Terreni',
        url: '/manage-data/farmlands',
      },
      {
        title: 'Utensili',
        url: '/manage-data/tools',
      },
      {
        title: 'Razze',
        url: '/manage-data/breeds',
      },
      {
        title: 'Mandrie',
        url: '/manage-data/herds',
      },
      {
        title: 'Tipi',
        url: '/manage-data/types',
      },
      {
        title: 'Sottotipi',
        url: '/manage-data/subtypes',
      },
      {
        title: 'Utilizzi deiezioni',
        url: '/manage-data/droplet-uses',
      },
      {
        title: 'Tipi deiezioni',
        url: '/manage-data/droplet-types',
      },
      {
        title: 'Tipi attività',
        url: '/manage-data/activity-types',
      },
      {
        title: 'Tipi ausiliari',
        url: '/manage-data/types-auxiliaries',
      },
      {
        title: 'Sottotipi ausiliari',
        url: '/manage-data/subtypes-auxiliaries',
      },
      {
        title: 'Utilizzi ausiliari',
        url: '/manage-data/uses-auxiliaries',
      },
    ],
  },
  {
    title: 'Vendite',
    url: '/sales',
    icon: 'fa fa-euro',
    children: [
      {
        title: 'Crea fattura',
        url: '/sales/create-invoice',
      },
    ],
  },

  // VB:REPLACE-END:MENU-CONFIG
]

export const getMenuData: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    category: true,
    title: 'Dashboards',
  },
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  {
    category: true,
    title: 'Apps & Pages',
    key: '2t2ghm',
  },
  {
    title: 'Attività',
    url: '/',
    icon: 'fa fa-tasks',
    children: [
      {
        title: 'Produzione',
        url: '/activity/user-production',
      },
      // {
      //   title: 'Pulizia',
      //   url: '/activity/cleaning',
      // },
    ],
  },
  {
    title: 'Certificazione Bio',
    url: '/organic-certification',
    icon: 'fa fa-leaf',
  },
  {
    title: 'Prodotti',
    url: '/products',
    icon: 'fe fe-package',
  },
  {
    title: 'Ausiliari',
    url: '/auxiliaries',
    icon: 'fa fa-flask',
  },
  {
    title: 'Gestione dati',
    url: '/manage-data',
    icon: 'fe fe-database',
    children: [
      {
        title: 'QR code',
        url: '/manage-data/qr-code',
      },
      {
        title: 'Anagrafiche animale',
        url: '/manage-data/animals-registry',
      },
      {
        title: 'Ausiliari',
        url: '/manage-data/auxiliaries',
      },
      {
        title: 'Clienti',
        url: '/manage-data/customers',
      },
      {
        title: 'Contenitori',
        url: '/manage-data/containers',
      },
      {
        title: 'Deiezioni',
        url: '/manage-data/droppings',
      },
      {
        title: 'Fabbricati',
        url: '/manage-data/buildings',
      },
      {
        title: 'Farmaci',
        url: '/manage-data/drugs',
      },
      {
        title: 'Fornitori',
        url: '/manage-data/suppliers',
      },
      {
        title: 'Luoghi animali',
        url: '/manage-data/places',
      },
      {
        title: 'Magazzino',
        url: '/manage-data/warehouses',
      },
      {
        title: 'Mezzi',
        url: '/manage-data/vehicles',
      },
      {
        title: 'Prodotti',
        url: '/manage-data/products',
      },
      {
        title: 'Sensori',
        url: '/manage-data/sensors',
      },
      {
        title: 'Terreni',
        url: '/manage-data/farmlands',
      },
      {
        title: 'Utensili',
        url: '/manage-data/tools',
      },
      {
        title: 'Razze',
        url: '/manage-data/breeds',
      },
      {
        title: 'Mandrie',
        url: '/manage-data/herds',
      },
      {
        title: 'Tipi',
        url: '/manage-data/types',
      },
      {
        title: 'Sottotipi',
        url: '/manage-data/subtypes',
      },
      {
        title: 'Utilizzi deiezione',
        url: '/manage-data/droplet-uses',
      },
      {
        title: 'Tipi deiezione',
        url: '/manage-data/droplet-types',
      },
      {
        title: 'Tipi attività',
        url: '/manage-data/activity-types',
      },
    ],
  },
  {
    title: 'Vendite',
    url: '/sales',
    icon: 'fa fa-calculator',
    children: [
      {
        title: 'Crea fattura',
        url: '/sales/create-invoice',
      },
    ],
  },

  // VB:REPLACE-END:MENU-CONFIG
]
