<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Typography','button':'Go to Docs','url':'https://getbootstrap.com/docs/4.5/layout/grid/'}"
          ></vb-headers-heading-3>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-hidden-typography></vb-hidden-typography></div>
      </div>
    </div>
  </div>
</div>
