import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-pricing-tables',
  templateUrl: './pricing-tables.component.html',
})
export class AdvancedPricingTablesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
