import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-nested',
  templateUrl: './nested.component.html',
})
export class NestedComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
