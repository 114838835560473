<div class="modal-header" *ngIf="isEdit">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <nz-spin [nzSpinning]="loading">
    <div class="modal-body" style="position: relative;">
      <form [formGroup]="form">
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="nome" class="mb-0 col-form-label">Nome*</label>
            <input type="text" class="form-control" formControlName="nome" id="nome">
          </div>
          <div class="col-lg-4">
            <label for="idTipoDeiezione" class="mb-0 col-form-label">Tipo deiezione*</label>
            <nz-select [(ngModel)]="idTipoDeiezione" [ngModelOptions]="{standalone: true}" id="idTipoDeiezione"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderTipiDeiezione" [nzDisabled]="!tipiDeiezione">
              <nz-option *ngFor="let opt of tipiDeiezione" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
              <button class="btn btn-link fw-bold" (click)="addTipoDeiezione()">
                <i class="fe fe-plus mr-1"></i>
                <span class="text-underline">Aggiungi tipo deiezione</span>
              </button>
              <button class="btn btn-link fw-bold" routerLink="/manage-data/droplet-types">
                <i class="fe fe-edit-2 mr-1"></i>
                <span class="text-underline">Gestisci tipo deiezione</span>
              </button>
            </div>
          </div>
          <div class="col-lg-4">
            <label for="idUtilizzoDeiezione" class="mb-0 col-form-label">Utilizzo deiezione*</label>
            <nz-select [(ngModel)]="idUtilizzoDeiezione" [ngModelOptions]="{standalone: true}" id="idUtilizzoDeiezione"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderUtilizziDeiezione" [nzDisabled]="!utilizzoDeiezioni">
              <nz-option *ngFor="let opt of utilizzoDeiezioni" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
              <button class="btn btn-link fw-bold" (click)="addUtilizzoDeiezione()">
                <i class="fe fe-plus mr-1"></i>
                <span class="text-underline">Aggiungi utilizzo deiezione</span>
              </button>
              <button class="btn btn-link fw-bold" routerLink="/manage-data/droplet-uses">
                <i class="fe fe-edit-2 mr-1"></i>
                <span class="text-underline">Gestisci utilizzo deiezione</span>
              </button>
            </div>
          </div>
          
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <span class="text-danger">*Campi obbligatori</span>
      <div class="form-row d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!(form.valid && idUtilizzoDeiezione && idTipoDeiezione)"
          (click)="postForm()">
          <span class="btnAddon">
            <i class="btnAddonIcon fe fe-plus-circle"></i>
          </span>
          {{button}}
        </button>
      </div>
    </div>
  </nz-spin>
