<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'C3','button':'Go to Docs','url':'https://c3js.org/'}"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Simple Line'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-1></vb-charts-c3-1></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Step'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-3></vb-charts-c3-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Scatter'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-5></vb-charts-c3-5></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Stacked Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-7></vb-charts-c3-7></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Sub Chart'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-9></vb-charts-c3-9></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Pie Chart'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-11></vb-charts-c3-11></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Spline'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-2></vb-charts-c3-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Area'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-4></vb-charts-c3-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-6></vb-charts-c3-6></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Combination'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-8></vb-charts-c3-8></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Zoom'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-10></vb-charts-c3-10></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Donut Chart'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-c3-12></vb-charts-c3-12></div>
      </div>
    </div>
  </div>
</div>
