import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificazione-bio',
  templateUrl: './certificazione-bio.component.html',
  styleUrls: ['./certificazione-bio.component.scss']
})
export class CertificazioneBioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
