

  <div class="card card-top card-top-primary">
    <div class="card-body">
      <nz-tabset [nzSelectedIndex]="0">
        <nz-tab nzTitle="Lettura dati sensore">
          <app-produzione-dati-sensore></app-produzione-dati-sensore>
        </nz-tab>
        <nz-tab nzTitle="Dati pianificazione">
          <app-produzione-dati-pianificazione></app-produzione-dati-pianificazione>
        </nz-tab> 
      </nz-tabset>
    </div>
  </div>
