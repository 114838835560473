import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { BsModalService } from 'ngx-bootstrap/modal'
import { HttpService } from 'src/app/services/shared/http-services/http.services'

@Component({
  selector: 'app-m-normativa',
  templateUrl: './m-normativa.component.html',
  styleUrls: ['./m-normativa.component.scss'],
  providers: [BsModalService],
})
export class MNormativaComponent implements OnInit {
  form: FormGroup
  loadingSelect: boolean
  loading: boolean
  toClose: boolean = false
  deleteButton: boolean
  @Output() onClose = new EventEmitter()
  @Output() onButtonActive = new EventEmitter()
  @Input() item
  @Input() title
  @Input() isEdit = false
  @Input() isAdd = false
  @Input() button
  loadingUpdate: boolean = false

  // manage base64 to pdf
  base64textString: string
  src: any
  base64preview: any
  fileLoading: boolean
  selectedForPreviewId: number
  filePreview: boolean
  BASE64_MARKER: string = ';base64,'
  indexPdf: string = 'data:application/pdf;base64,'

  constructor(private httpService: HttpService) // private modalService: BsModalService,
  {}

  ngOnInit(): void {
    this.getData()
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit()
    }
  }

  emitOnButtonActive() {
    this.onButtonActive.emit()
  }

  getData() {
    this.loading = true
    this.httpService.get('Normative/GetNormativeById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          console.log('res.normative')
          console.log(res.normative)
          this.loading = false

          // GESTIONE PDF
          // i metodi per la presentazione e per il download dei pdf funzionano, in questo momento i base64 nel db sono corrotti

          let resBase64 = res.normative.base64
          let pdfAsDataUri = 'data:application/pdf;base64,' + resBase64
          let pdfAsArray = this.convertDataURIToBinary(pdfAsDataUri)
          this.src = pdfAsArray
        } else {
          this.loading = false
          console.log(res)
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  // METODI PER LA GESTIONE DEI PDF

  convertDataURIToBinary(dataURI) {
    let base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length
    let base64 = dataURI.substring(base64Index)
    let raw = window.atob(base64)
    let rawLength = raw.length
    let array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  saveByteArray() {
    let blob = new Blob([this.src], { type: 'application/pdf' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    let fileName = this.item.nome
    link.download = fileName
    link.click()
  }
}
