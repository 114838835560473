<div class="row">
  <!-- <div class="col-lg-12  align-items-center justify-content-end d-flex d-row">
    <div class="mr-2">
      <nz-range-picker [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-range-picker>
    </div>
    <div class="mr-2">
      <nz-range-picker nzMode="month" [(ngModel)]="date" (ngModelChange)="onChange($event)"
        [nzPlaceHolder]="monthPlaceholder"></nz-range-picker>
    </div>
    <div>
      <nz-range-picker nzMode="year" [(ngModel)]="date" (ngModelChange)="onChange($event)"
        [nzPlaceHolder]="yearPlaceholder"></nz-range-picker>
    </div>
  </div> -->
</div>
<div class="card-2 mb-4">
  <div class="card-body">
    <div class="font-weight-bold text-dark font-size-24">{{titleData}}</div>
    <div class="text-dark fw-bold">{{title}}</div>
    <div class="container">
      <x-chartist [data]="chartData" [type]="'Line'" [options]="chartOptions" class="height-200 ct-hidden-points">
      </x-chartist>
    </div>
  </div>
</div>