import { Component, OnInit } from '@angular/core';
import ChartistTooltip from 'chartist-plugin-tooltips-updated'

@Component({
  selector: 'app-c-chart',
  templateUrl: './c-chart.component.html',
  styleUrls: ['./c-chart.component.scss']
})
export class CChartComponent implements OnInit {
  chartData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    series: [
      [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
    ]
  };

  chartOptions = {
    chartPadding: {
      right: 0,
      left: 0,
      top: 5,
      bottom: 5,
    },
    fullWidth: true,
    showPoint: true,
    lineSmooth: true,
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0,
    },
    axisX: {
      showGrid: true,
      showLabel: true,
      offset: 20,
    },
    showArea: false,
    plugins: [
      ChartistTooltip({
        anchorToPoint: false,
        appendToBody: true,
        seriesName: false,
      }),
    ],
  }

  constructor() { }

  ngOnInit(): void {
  }

}
