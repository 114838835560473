<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Form Examples'}"></vb-headers-heading>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Basic Form'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-forms-1></vb-forms-1></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Inline Form'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-forms-2></vb-forms-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Two Columns'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-forms-3></vb-forms-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Validation'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-forms-4></vb-forms-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Add Post'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-forms-5></vb-forms-5></div>
      </div>
    </div>
  </div>
</div>
