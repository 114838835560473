
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Deiezione } from 'src/app/models/precisionfarming-models/Deiezione';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { MManageTipoDeiezioneComponent } from '../manage-select-fields-modals/m-manage-tipo-deiezione/m-manage-tipo-deiezione.component';
import { MManageUtilizzoDeiezioneComponent } from '../manage-select-fields-modals/m-manage-utilizzo-deiezione/m-manage-utilizzo-deiezione.component';

@Component({
  selector: 'app-m-edit-deiezione',
  templateUrl: './m-edit-deiezione.component.html',
  styleUrls: ['./m-edit-deiezione.component.scss'],
  providers: [BsModalService],
})
export class MEditDeiezioneComponent implements OnInit {

  form: FormGroup;
  idTipoDeiezione: number;
  idUtilizzoDeiezione: number;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false
  @Input() button;
  tipiDeiezione: any;
  utilizzoDeiezioni: any;
  modalRef: BsModalRef;
  placeholderTipiDeiezione: string = ""
  placeholderUtilizziDeiezione: string = ""

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.getTipiDeiezione();
    this.getUtilizzoDeiezioni();
    if (this.item) {
      this.getData();
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: Deiezione = new Deiezione()
    f.nome = this.f.nome.value
    f.idTipoDeiezione = this.idTipoDeiezione
    f.idUtilizzoDeiezione = this.idUtilizzoDeiezione


    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('Deiezione/EditDeiezione', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTipoDeiezione  = undefined
            this.idUtilizzoDeiezione = undefined
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('Deiezione/AddDeiezione', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTipoDeiezione  = undefined
            this.idUtilizzoDeiezione = undefined
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('Deiezione/GetDeiezioneById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          this.idTipoDeiezione = res.deiezione.idTipoDeiezione;
          this.idUtilizzoDeiezione = res.deiezione.idUtilizzoDeiezione;
          this.form.patchValue({
            nome: res.deiezione.nome
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }
  
  getTipiDeiezione() {
    this.placeholderTipiDeiezione = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/TipiDeiezione').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.tipiDeiezione?.length) {
            this.placeholderTipiDeiezione = "Non ci sono tipi deiezione"
          }
          this.tipiDeiezione = res.tipiDeiezione
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getUtilizzoDeiezioni() {
    this.placeholderUtilizziDeiezione = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/UtilizzoDeiezioni').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.utilizzoDeiezioni?.length) {
            this.placeholderUtilizziDeiezione = "Non ci sono utilizzi deiezione"
          }
          this.utilizzoDeiezioni = res.utilizzoDeiezioni
          console.log('utilizzoDeiezioni')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  addTipoDeiezione() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi tipo deiezione',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTipoDeiezioneComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getTipiDeiezione()
    })}

    addUtilizzoDeiezione() {
      const modalOptions: ModalOptions = {
        keyboard: false,
        backdrop: true,
        initialState: {
          isEdit: false,
          isAdd: true,
          title: 'Aggiungi utilizzo deiezione',
          button: 'Aggiungi',
        },
        class: 'app-modal modal-lg',
      }
      this.modalRef = this.modalService.show(MManageUtilizzoDeiezioneComponent, modalOptions)
      this.modalRef.content.onClose.subscribe(() => {
        this.modalRef.hide()
        // this.clearHeader()
        this.getUtilizzoDeiezioni()
      })}

}
