import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-colors',
  templateUrl: './colors.component.html',
})
export class AdvancedColorsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
