import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { jwtAuthService } from 'src/app/services/jwt'
import { Router } from '@angular/router'

@Component({
  selector: 'app-c-user-menu-topbar',
  templateUrl: './c-user-menu-topbar.component.html',
  styleUrls: ['./c-user-menu-topbar.component.scss'],
})
export class CUserMenuTopbarComponent implements OnInit {
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''

  constructor(
    private store: Store<any>,
    private authenticationService: jwtAuthService,
    private router: Router,
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.name = state.name
      this.role = state.role
      this.email = state.email
    })
  }

  ngOnInit(): void {}

  logout(e) {
    e.preventDefault()
    localStorage.removeItem('currentUser')
    localStorage.removeItem('isLoggedin')
    // this.store.dispatch(new UserActions.Logout())
    this.authenticationService.logout()
    this.router.navigate(['/auth/login'])
  }
}
