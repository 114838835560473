<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-1></vb-widgets-tables-1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-2></vb-widgets-tables-2></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-2></vb-widgets-tables-2></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE4'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-4></vb-widgets-tables-4></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE5'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-5></vb-widgets-tables-5></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE6'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-6></vb-widgets-tables-6></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE7'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-7></vb-widgets-tables-7></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'TABLE8'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-8></vb-widgets-tables-8></div>
      </div>
    </div>
  </div>
</div>
