import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-apps-mail',
  templateUrl: './mail.component.html',
})
export class AppsMailComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
