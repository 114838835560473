import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-icons-linearicons-free',
  templateUrl: './linearicons-free.component.html',
})
export class IconsLineariconsFreeComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
