import { Injectable } from '@angular/core'
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { Router } from '@angular/router'
import { UserClaimsService } from './user-claims-service'
// import { UserClaimsService } from 'src/app/service/user-claims/user-claims-service';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(private claimsService: UserClaimsService, private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('isLoggedin')) {
      console.log('sono loggato bene')

      //  const userClaims: string[] = await this.claimsService.getUserClaims();
      // const accessClaims: string[] = next.data.requiredAccessClaims || [];

      //   // // user must have all the claims required to access route
      //   if (userClaims.filter(uc => accessClaims.indexOf(uc) > -1).length === accessClaims.length) {
      //     // console.log('respected requirements for ', route.url);
      //     return true;
      // }
      // return false;


      // // return true



      const userClaims: string = await this.claimsService.getUserClaims();
      console.log('userClaims')
      const accessClaims: any = next.data.role;
      console.log(accessClaims)

      // if (accessClaims) {

      //   if (userClaims == accessClaims) {
      //     // console.log('respected requirements for ', route.url);
      //     return true;
      //   }
      //   return false;
      // }

      if (accessClaims) {
        let access;
        accessClaims.forEach(element => {
          console.log('CLAIM')
          console.log(userClaims)
          console.log(element)
          if (userClaims == element) {
            // console.log('respected requirements for ', route.url);
            console.log('CLAIM')
            console.log(userClaims)
            console.log(element)
            access = true;
          }

        });
        if (access) {
          return true
        } else { 
          return false; 
        }

      }

      // // user must have all the claims required to access route

      return true
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } })
      return false
    }
  }
}
