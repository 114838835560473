import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
})
export class CardsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
