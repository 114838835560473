import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-email-templates',
  templateUrl: './email-templates.component.html',
})
export class AdvancedEmailTemplatesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
