import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomField } from 'src/app/models/shared/CustomField';

@Component({
  selector: 'caiwa-add-form-content',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})
export class AddFormComponent {
  
  @Input() field!: CustomField<string>;
  @Input() form!: FormGroup;

  get isValid() { return this.form.controls[this.field.key].valid;} 
    
}
