import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-todoist-list',
  templateUrl: './todoist-list.component.html',
})
export class ExtraAppsTodoistListComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
