<div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-23></vb-widgets-lists-23></div>
      </div>
    </div>
    <div class="col-lg-9 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-6></vb-headers-card-header-tabbed-6>
        </div>
        <div class="card-body"><vb-tables-antd-1></vb-tables-antd-1></div>
      </div>
    </div>
  </div>
</div>
