import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-helpdesk-dashboard',
  templateUrl: './helpdesk-dashboard.component.html',
})
export class ExtraAppsHelpdeskDashboardComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
