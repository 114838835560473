<div class="m-1">

  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Terreno'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 p-0 mb-4">
    <label for="idTerreno" class="mb-0 col-form-label">Seleziona terreno</label>
    <nz-select [(ngModel)]="idTerreno" [ngModelOptions]="{standalone: true}" id="idTerreno"
      class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="">
      <nz-option *ngFor="let opt of terreni" [nzValue]="opt.id" [nzLabel]="opt.codiceCatastale"></nz-option>
    </nz-select>
  </div>

    <div class="row">
        <div class="col-lg-12">
          <div class="card-placeholder">
            <div class="card-header">
              <vb-headers-heading [data]="{'title':'Statistiche'}"></vb-headers-heading>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="card">
            <div class="card-body"><vb-widgets-charts-4></vb-widgets-charts-4></div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card">
            <div class="card-body"><vb-widgets-charts-4v1></vb-widgets-charts-4v1></div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card">
            <div class="card-body"><vb-widgets-charts-4v2></vb-widgets-charts-4v2></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card-placeholder">
            <div class="card-header">
              <vb-headers-heading [data]="{'title':'Dati sensore'}"></vb-headers-heading>
            </div>
          </div>
        </div>
      </div>
    <caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
      [updateBtn]="true" [isFiltrable]="true" [loading]="loading" [tableWidth]="tableWidth" (updateBtnAction)="true"
      (filterCustom)="getData($event)" tableSize="small">
    </caiwa-generic-table>
  
  </div>
