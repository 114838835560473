import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-c-widget-profile',
  templateUrl: './c-widget-profile.component.html',
  styleUrls: ['./c-widget-profile.component.scss'],
})
export class CWidgetProfileComponent implements OnInit {

  userData: any;
  name: string;
  surname: string;
  role: string;
  constructor() { }


  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.name = this.userData.name;
    this.surname = this.userData.surname;
    this.role = this.userData.nameRole;
  }


}
