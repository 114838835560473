<div class="breadcrumbs p-0">
  <div class="path pb-5">
    <ng-container>
      <span>
        <strong class="current">Dati pianificazione colture</strong>
      </span>
    </ng-container>
  </div>
</div>
<nz-spin [nzSpinning]="loading">
    <nz-collapse>
      <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
        [nzDisabled]="panel.disabled">
        <app-m-edit-dati-pianificazione button='Aggiungi' (onButtonActive)="getData('')"></app-m-edit-dati-pianificazione>
      </nz-collapse-panel>
    </nz-collapse>
  </nz-spin>
  <br>
  <caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
    [updateBtn]="true" [deleteBtn]="true" [isFiltrable]="true" [loading]="loading"  [tableWidth]="tableWidth" tableSize="small"
    
    (updateBtnAction)="edit($event)" (deleteBtnAction)="delete($event)"
    (filterCustom)="true">
  </caiwa-generic-table>
