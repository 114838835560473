import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Sensore } from 'src/app/models/precisionfarming-models/Sensore';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { MEditTerrenoComponent } from '../m-edit-terreno/m-edit-terreno.component';
import { MManageTerrenoComponent } from '../manage-select-fields-modals/m-manage-terreno/m-manage-terreno.component';

@Component({
  selector: 'app-m-edit-sensore',
  templateUrl: './m-edit-sensore.component.html',
  styleUrls: ['./m-edit-sensore.component.scss'],
  providers: [BsModalService],
})
export class MEditSensoreComponent implements OnInit {

  form: FormGroup;
  currentDate: any;
  idTerreno: number;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false
  @Input() button;
  terreni: any;
  modalRef: BsModalRef;
  placeholderTerreni: string = ""

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      temperatura: ['', Validators.required],
      umiditaAria: ['', Validators.required],
      umiditaAriaMisura: ['', Validators.required],
      umiditaTerreno: ['', Validators.required],
      umiditaTerrenoMisura: ['', Validators.required],
      ph: ['', Validators.required],
      luce: ['', Validators.required],
      luceMisura: ['', Validators.required],
      data: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.getTerreni();
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    if (this.item) {
      this.getData();
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: Sensore = new Sensore()
    f.temperatura = this.f.temperatura.value
    f.umiditaAria = this.f.umiditaAria.value
    f.umiditaAriaMisura = this.f.umiditaAriaMisura.value
    f.umiditaTerreno = this.f.umiditaTerrenoMisura.value
    f.ph = this.f.ph.value
    f.umiditaTerrenoMisura = this.f.umiditaTerrenoMisura.value
    f.luce = this.f.luce.value
    f.luceMisura = this.f.luceMisura.value
    f.idTerreno = this.idTerreno

    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('Sensore/EditSensore', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTerreno = undefined
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('Sensore/AddSensore', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTerreno = undefined
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('Sensore/GetSensoreById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          this.idTerreno = res.sensore.idTerreno;
          this.form.patchValue({
            temperatura: res.sensore.temperatura,
            umiditaAria: res.sensore.umiditaAria,
            umiditaAriaMisura: res.sensore.umiditaAriaMisura,
            umiditaTerreno: res.sensore.umiditaTerrenoMisura,
            ph: res.sensore.ph,
            umiditaTerrenoMisura: res.sensore.umiditaTerrenoMisura,
            luce: res.sensore.luce,
            luceMisura: res.sensore.luceMisura
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }

  getTerreni() {
    this.placeholderTerreni = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Terreni').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.terreni?.length) {
            this.placeholderTerreni = "Non ci sono terreni"
          }
          this.terreni = res.terreni
          console.log('terreni')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  edit() {
    // this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        // item: item,
        title: 'Dettagli utensile',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTerrenoComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      // this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi utensile',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTerrenoComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      // this.getData('')
    })
  }

  addTerreno() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi terreno',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditTerrenoComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getTerreni()
    })}

}
