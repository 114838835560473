<div>
  <div *ngIf="qrError" class="p-3 text-center">Qualità dell'immagine insufficiente, lettura QR code non
    effettuata.&nbsp;<button class="btn btn-link fw-bold p-0" (click)="openFileInput()">Riprovare</button></div>
    <div *ngIf="qrIncomplete" class="p-3 text-center">Lettura QR code non
      effettuata.&nbsp;<button class="btn btn-link fw-bold p-0" (click)="openFileInput()">Riprovare</button></div>
  <div class="video-row">
    <video class="video" id="video" #video autoplay playsinline></video>
  </div>
  <div style="opacity: 0; position: fixed;">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div>
</div>

<input type="file" accept="image/*" capture="environment" (change)="handleFileInput($event)" id="file" class="d-none">