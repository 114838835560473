<div class="breadcrumbs p-0">
  <div class="path pb-5">
    <ng-container>
      <span>
        <strong class="current">Normativa</strong>
      </span>
    </ng-container>
  </div>
</div>
<caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
    [updateBtn]="true" [deleteBtn]="false" [isFiltrable]="true" [loading]="loading" [tableWidth]="tableWidth"
    (updateBtnAction)="edit($event)" (deleteBtnAction)="delete($event)" (filterCustom)="getData($event)"
    tableSize="small">
  </caiwa-generic-table>

