import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-wordpress-post',
  templateUrl: './wordpress-post.component.html',
})
export class ExtraAppsWordpressPostComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
