<div class="pt-5">
  <div class="card boxContainer mt-2">
    <div class="text-dark font-size-32 mb-3">Sign In</div>
    <div class="mb-4">
      Login and password
      <br />
      <strong>demo@visualbuilder.cloud / VisualBuilder</strong>
    </div>
    <div class="mb-4">
      <nz-radio-group [ngModel]="authProvider" (ngModelChange)="setProvider($event)">
        <label nz-radio nzValue="jwt">JWT</label>
        <label nz-radio nzValue="firebase">Firebase</label>
      </nz-radio-group>
    </div>
    <form nz-form [nzLayout]="'vertical'" [formGroup]="form" (ngSubmit)="submitForm()" role="form">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your email!">
          <input type="text" nz-input formControlName="email" placeholder="Email Address" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <input type="password" nz-input formControlName="password" placeholder="Password" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" [nzLoading]="loading" class="text-center w-100">
            <strong>Log In</strong>
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
    <a routerLink="/auth/forgot-password" class="vb__utils__link">
      Forgot password?
    </a>
  </div>
  <div class="text-center pt-2 mb-auto">
    <span class="mr-2">Don't have an account?</span>
    <a routerLink="/auth/register" class="vb__utils__link">
      Sign up
    </a>
  </div>
</div>