import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CustomField } from 'src/app/models/shared/CustomField'
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton'
import { FieldControlService } from 'src/app/services/shared/form-services/FieldControlService'

@Component({
  selector: 'caiwa-add-form-template',
  templateUrl: './add-form-template.component.html',
  providers: [FieldControlService],
  styleUrls: ['./add-form-template.component.scss'],
})
export class AddFormTemplateComponent implements OnInit {
  @Input() fields: CustomField<string>[] | null = []
  @Input() text: CustomTextButton<string>[] | null = []

  @Output() onSave = new EventEmitter<any>()

  form!: FormGroup
  payLoad = ''
  formRaw: any = {}

  constructor(private fcs: FieldControlService) {}

  ngOnInit() {
    this.form = this.fcs.toFormGroup(this.fields as CustomField<string>[])
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue())
    this.formRaw = this.form.getRawValue()
    this.onSave.emit(this.form)
  }
}
