import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-typography',
  templateUrl: './typography.component.html',
})
export class AdvancedTypographyComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
