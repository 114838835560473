<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Basic Header'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-4
            [data]="{'title':'With Icon','icon':'fe fe-phone-call','description':'Some descriptiopn here...'}"
          ></vb-headers-card-header-4>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header card-header-borderless py-0">
          <vb-headers-card-header-5 [data]="{'title':'Borderless'}"></vb-headers-card-header-5>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card bg-light">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Tinted'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Colored Top Border'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-3 [data]="{'title':'With Actions'}"></vb-headers-card-header-3>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'With Footer'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
        <div class="card-footer">
          <vb-headers-card-footer
            [data]="{'mainTitle':'Unlock Account','mainIcon':'fe fe-lock','mainType':'btn-success','additionalTitle':'Cancel'}"
          ></vb-headers-card-footer>
        </div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-4
            [data]="{'title':'With Icon','icon':'fe fe-phone-call','description':'Some descriptiopn here...'}"
          ></vb-headers-card-header-4>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
    </div>
  </div>
</div>
