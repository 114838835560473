import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class TableWidthService {
  constructor() {}

  getTableWidth(column) {
    let widthTable = 0
    let tableWidth
    for (let columnWidth of column) {
      let width = parseInt(columnWidth.columnWidth.slice(0, -2))
      widthTable += width
    }
    tableWidth = widthTable.toString() + 'px'
    return tableWidth
  }
}
