<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Account Information','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-widgets-general-19></vb-widgets-general-19></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Server Statistics','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body bg-light rounded">
          <vb-widgets-general-21v2></vb-widgets-general-21v2>
        </div>
        <div class="card-body bg-light rounded">
          <vb-widgets-general-21v2></vb-widgets-general-21v2>
        </div>
        <div class="card-body bg-success text-white rounded">
          <vb-widgets-general-21></vb-widgets-general-21>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Work Progress','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-widgets-lists-10></vb-widgets-lists-10></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Account Settings','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <vb-widgets-general-20v1></vb-widgets-general-20v1>
      </div>
      <div class="card card-top card-top-success">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'User Divergence','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-charts-chartistjs-7></vb-charts-chartistjs-7></div>
      </div>
      <div class="card card-top card-top-primary">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Months Activity','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-charts-chartistjs-10></vb-charts-chartistjs-10></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Revenue','description':'Block with important Account information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-widgets-general-1v1></vb-widgets-general-1v1></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body"><vb-app-partials-calendar></vb-app-partials-calendar></div>
      </div>
    </div>
  </div>
</div>
