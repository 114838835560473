import { formatDate } from '@angular/common'
import { Component, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn'
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService'
import { noSpaceValidator } from 'src/app/services/shared/validators/Validators'


@Component({
  selector: 'app-c-manage-data',
  templateUrl: './c-manage-data.component.html',
  styleUrls: ['./c-manage-data.component.scss'],
  // providers: [BsModalService],
})
export class CManageDataComponent implements OnInit {
  // form: FormGroup
  // operators$: Observable<CustomTextButton<any>[]>
  // deleteButton: boolean = false
  loading: boolean = false
  // selectedValue: number
  // loadingSelect: boolean = false
  // currentDate: any
  // modalRef: BsModalRef
  // openAlert: boolean = false
  // radioValue: string = 'm'
  panels = [
    {
      active: false,
      name: 'Anagrafica animale',
      disabled: false,
    },
    {
      active: false,
      name: 'Ausiliare',
      disabled: false,
    },
    {
      active: false,
      name: 'Cliente',
      disabled: false,
    },
    {
      active: false,
      name: 'Contenitore',
      disabled: false,
    },
    {
      active: false,
      name: 'Deiezione',
      disabled: false,
    },
    {
      active: false,
      name: 'Fabbricato',
      disabled: false,
    },
    {
      active: false,
      name: 'Farmaco',
      disabled: false,
    },
    {
      active: false,
      name: 'Fornitore',
      disabled: false,
    },
    {
      active: false,
      name: 'Luogo animali',
      disabled: false,
    },
    {
      active: false,
      name: 'Magazzino',
      disabled: false,
    },
    {
      active: false,
      name: 'Mezzo',
      disabled: false,
    },
    {
      active: false,
      name: 'Prodotto',
      disabled: false,
    },
    {
      active: false,
      name: 'Sensore',
      disabled: false,
    },
    {
      active: false,
      name: 'Terreno',
      disabled: false,
    },
    {
      active: false,
      name: 'Utensile',
      disabled: false,
    },
  ]
  // colonne: ITableColumn[] = [
  //   { name: 'Nome', dataKey: 'name', columnWidth: '200px' },
  //   { name: 'Cognome', dataKey: 'surname', columnWidth: '200px' },
  //   { name: 'Sesso', dataKey: 'gender', columnWidth: '100px' },
  //   { name: 'Codice fiscale', dataKey: 'fiscalCode', columnWidth: '250px' },
  //   { name: 'Nazionalità', dataKey: 'nationality', columnWidth: '200px' },
  //   { name: 'Regione', dataKey: 'residenceRegion', columnWidth: '200px' },
  //   { name: 'Indirizzo di residenza', dataKey: 'residenceAddress', columnWidth: '300px' },
  //   { name: 'E-mail', dataKey: 'email', columnWidth: '200px' },
  //   { name: 'Numero di telefono', dataKey: 'phoneNumber', columnWidth: '200px' },
  //   { name: 'Ruolo', dataKey: 'role', columnWidth: '200px' },
  //   { name: "Data d'assunzione", dataKey: 'employmentDate', columnWidth: '200px' },
  //   { name: 'Codice seriale', dataKey: 'serialCode', columnWidth: '200px' },
  // ]
  // idSelectedItem: any
  // tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)

  constructor(
    // private httpService: HttpService,
    // private notification: NzNotificationService,
    // private formBuilder: FormBuilder,
    // private modalService: BsModalService,
    // private tableWidthService: TableWidthService,
  ) {
    // this.form = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   surname: ['', Validators.required],
    //   nationality: ['', Validators.required],
    //   fiscalCode: ['', Validators.required],
    //   residenceAddress: ['', Validators.required],
    //   postalCode: ['', Validators.required],
    //   residenceRegion: ['', Validators.required],
    //   email: ['', [Validators.email, Validators.required]],
    //   phoneNumber: ['', Validators.required],
    //   employmentDate: ['', Validators.required],
    //   serialCode: ['', Validators.required],
    // })
  }

  ngOnInit(): void {
    // this.getRole()
    // this.getOperators('')
    // this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
  }

  // get f() {
  //   return this.form.controls
  // }

  // getRole() {
  //   this.loadingSelect = true
  //   this.httpService.get('Role/GetAllRole').subscribe(
  //     res => {
  //       if (res.isSuccess) {
  //         this.role = res.role
  //       }
  //       this.loadingSelect = false
  //     },
  //     err => {
  //       console.log(err)
  //       this.loadingSelect = false
  //     },
  //   )
  // }

  // postForm() {
  //   this.loading = true
  //   let f: ManageOperator = new ManageOperator()
  //   f.name = this.f.name.value
  //   f.surname = this.f.surname.value
  //   f.gender = this.radioValue
  //   f.nationality = this.f.nationality.value
  //   f.fiscalCode = this.f.fiscalCode.value
  //   f.residenceRegion = this.f.residenceRegion.value
  //   f.residenceAddress = this.f.residenceAddress.value
  //   f.email = this.f.email.value
  //   f.phoneNumber = this.f.phoneNumber.value
  //   f.employmentDate = this.f.employmentDate.value
  //   f.serialCode = this.f.serialCode.value
  //   f.idRole = this.selectedValue
  //   f.isActive = true
  //   f.postalCode = this.f.postalCode.value

  //   this.httpService.post('Operator/AddOperator', f).subscribe(
  //     res => {
  //       if (res.isSuccess) {
  //         this.notification.success('Operazione effettuata con successo!')
  //         this.form.reset()
  //         this.getOperators('')
  //       } else if (
  //         res.errorMessageIta ==
  //         "Il codice fiscale inserito esiste già, l'operatore associato è disattivo"
  //       ) {
  //         this.openAlert = true
  //       } else {
  //         this.notification.error(res.errorMessageIta)
  //       }
  //       console.log('res add operator')
  //       console.log(res)
  //       this.loading = false
  //     },
  //     err => {
  //       this.notification.error("Errore nel completamento dell'operazione!")
  //       console.log(err)
  //       this.loading = false
  //     },
  //   )
  // }

  // getOperators(event) {
  //   this.loading = true
  //   this.httpService.get('Operator/GetAllOperator?Search=' + event).subscribe(
  //     res => {
  //       if (res.isSuccess) {
  //         console.log(res.role)
  //         this.loading = false
  //         this.operators$ = res.operators
  //         console.log('operators')
  //         console.log(this.operators$)
  //       } else {
  //         this.loading = false
  //       }
  //     },
  //     err => {
  //       this.loading = false
  //       console.log(err)
  //     },
  //   )
  // }

  // cliccaBtnUpdate(event) {
  //   this.idSelectedItem = event
  //   const modalOptions: ModalOptions = {
  //     keyboard: false,
  //     backdrop: true,
  //     initialState: {
  //       item: this.idSelectedItem,
  //     },
  //     class: 'app-modal modal-lg',
  //   }
  //   this.modalRef = this.modalService.show(MEditOperatorComponent, modalOptions)
  //   this.modalRef.content.onClose.subscribe(() => {
  //     this.modalRef.hide()
  //     this.operators$ = undefined
  //     this.getOperators('')
  //   })
  // }

  // assignWorkShift(event) {
  //   this.idSelectedItem = event.id
  //   const modalOptions: ModalOptions = {
  //     keyboard: false,
  //     backdrop: true,
  //     initialState: {
  //       item: this.idSelectedItem,
  //     },
  //     class: 'app-modal modal-lg',
  //   }
  //   this.modalRef = this.modalService.show(MAssignWorkShiftComponent, modalOptions)
  //   this.modalRef.content.onClose.subscribe(() => {
  //     this.modalRef.hide()
  //     // this.operators$ = undefined;
  //     // this.getOperators('');
  //   })
  // }

  // enableOperator() {
  //   this.loading = true
  //   let f = {
  //     fiscalCode: this.f.fiscalCode.value,
  //   }
  //   this.httpService.post('Operator/EnableOperatorWithFiscalCode', f).subscribe(
  //     res => {
  //       if (res.isSuccess) {
  //         this.notification.success('Operazione effettuata con successo!')
  //         this.form.reset()
  //       } else {
  //         this.notification.error(res.errorMessageIta)
  //       }
  //       console.log('res')
  //       console.log(res)
  //       this.loading = false
  //     },
  //     err => {
  //       this.notification.error("Errore nel completamento dell'operazione!")
  //       console.log(err)
  //       this.loading = false
  //     },
  //   )
  // }
}

