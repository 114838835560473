import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-c-card-dashboard',
  templateUrl: './c-card-dashboard.component.html',
  styleUrls: ['./c-card-dashboard.component.scss'],
})
export class CCardDashboardComponent implements OnInit {
  @Input() id = 0
  @Input() title: string
  @Input() data: number
  @Input() currency: boolean = false
  @Input() currencyOnKg: boolean = false
  @Input() icon: string
  @Input() production: number
  @Input() cardCosti: boolean = false
  coltura: any

  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    this.activatedRoute.paramMap.subscribe(param => (this.coltura = param.get('id')))
  }

  ngOnInit(): void {}

  onClick() {
    if (this.id != 0) {
      this.route.navigate(['activity/admin-production', this.id])
    }
  }
}
