<div class="modal-header" *ngIf="isEdit || isAdd">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="codiceCatastale" class="mb-0 col-form-label">Codice catastale*</label>
          <input type="text" class="form-control" formControlName="codiceCatastale" id="codiceCatastale">
        </div>
        <div class="col-lg-4">
          <label for="coordinate" class="mb-0 col-form-label">Coordinate*</label>
          <input type="text" class="form-control" formControlName="coordinate" id="coordinate">
        </div>
        <div class="col-lg-4">
          <label for="grandezza" class="mb-0 col-form-label">Grandezza*</label>
          <input type="text" class="form-control" formControlName="grandezza" id="grandezza">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!form.valid" (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>