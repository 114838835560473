<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Simple Email'}"></vb-headers-heading>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-emails-1></vb-emails-1></div>
      </div>
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Email w/ Header'}"></vb-headers-heading>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-emails-2></vb-emails-2></div>
      </div>
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Ecommerce Email'}"></vb-headers-heading>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-emails-3></vb-emails-3></div>
      </div>
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Email w/ Action'}"></vb-headers-heading>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-emails-4></vb-emails-4></div>
      </div>
    </div>
  </div>
</div>
