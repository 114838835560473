<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Antd Tables','button':'Go to Docs','url':'https://ant.design/components/table/'}"
          ></vb-headers-heading-3>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Basic Usage'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-3></vb-tables-antd-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Row Selection'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-4></vb-tables-antd-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Custom Filters'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-6></vb-tables-antd-6></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Expandable Row'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-7></vb-tables-antd-7></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'Fixed Header and Columns'}"
          ></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-8></vb-tables-antd-8></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Filter and Sorter'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-5></vb-tables-antd-5></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Resizable column'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-tables-antd-9></vb-tables-antd-9></div>
      </div>
    </div>
  </div>
</div>
