import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { getMenuData, getMenuDataAdmin } from './config'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  getMenuData(): Observable<any[]> {

    let userData = JSON.parse(localStorage.getItem('currentUser'));
    let role = userData.role; 
    if(role == 1 || role == 2) {
      return of(getMenuDataAdmin)
    } else {
      return of(getMenuData)
    }

    
  }
}
