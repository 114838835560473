<!-- BASE: src\app\@vb\components\Topbar\UserMenu\user-menu.component.html -->

<div>
    <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
      <nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      
      
      <ul nz-menu class="p-1 dropdown-user">
        <!-- <li nz-menu-item>
          <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
          <div>
            <strong class="mr-1">
              {{ 'topBar.profileMenu.billingPlan' | translate }}:
            </strong>
            Professional
          </div>
          <div>
            <strong>
              {{ 'topBar.profileMenu.role' | translate }}:
            </strong>
            {{role || '—'}}
          </div>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item>
          <div>
            <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>
            {{email || '—'}}
            <br />
            <strong>{{ 'topBar.profileMenu.phone' | translate }}: </strong>
            {{phone || '—'}}
          </div>
        </li>
        <li nz-menu-divider></li> -->
        <li><app-c-widget-profile></app-c-widget-profile></li>
        <li nz-menu-divider></li>
        <li nz-menu-item>
          <a href="javascript: void(0);" routerLink="/profile">
            <i class="fe fe-user mr-2"></i>
            Profilo
            <!-- {{ 'topBar.profileMenu.editProfile' | translate }} -->
          </a>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item>
  
          <a href="javascript: void(0);" (click)="logout($event)">
            <i class="fe fe-log-out mr-2"></i>
            Esci
            <!-- {{ 'topBar.profileMenu.logout' | translate }} -->
          </a>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
