import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dashboard-beta',
  templateUrl: './beta.component.html',
})
export class DashboardBetaComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
