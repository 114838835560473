import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-cards-basic',
  templateUrl: './basic.component.html',
})
export class CardsBasicComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
