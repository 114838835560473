import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-invoice',
  templateUrl: './invoice.component.html',
})
export class AdvancedInvoiceComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
