<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Ecommerce Orders'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-3 [data]="{'title':'Latest Orders'}"></vb-headers-card-header-3>
        </div>
        <div class="card-body"><vb-tables-antd-2></vb-tables-antd-2></div>
      </div>
    </div>
  </div>
</div>
