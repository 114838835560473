import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-widgets-tables',
  templateUrl: './tables.component.html',
})
export class WidgetsTablesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
