<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL14'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-14></vb-widgets-general-14></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL12'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-12></vb-widgets-general-12></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL12V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body text-white bg-primary rounded">
          <vb-widgets-general-12v1></vb-widgets-general-12v1>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL12V2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-12v2></vb-widgets-general-12v2></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL12V3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-12v3></vb-widgets-general-12v3></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL13'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-13></vb-widgets-general-13></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL13V1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-13v1></vb-widgets-general-13v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-1></vb-widgets-general-1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL1V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-1v1></vb-widgets-general-1v1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL9'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-9></vb-widgets-general-9></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL10'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-10></vb-widgets-general-10></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL10V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-10v1></vb-widgets-general-10v1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL5V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-5v1></vb-widgets-general-5v1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL11'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-11></vb-widgets-general-11></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL11V1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-11v1></vb-widgets-general-11v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-2></vb-widgets-general-2></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL2V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-2v1></vb-widgets-general-2v1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL2V2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-2v2></vb-widgets-general-2v2></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL2V3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body text-white bg-success rounded">
          <vb-widgets-general-2v3></vb-widgets-general-2v3>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL2V4'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body text-white bg-danger rounded">
          <vb-widgets-general-2v4></vb-widgets-general-2v4>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-3></vb-widgets-general-3></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL3V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body text-white bg-primary rounded">
          <vb-widgets-general-3v1></vb-widgets-general-3v1>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL4'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-4></vb-widgets-general-4></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL5'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-5></vb-widgets-general-5></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL6'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-6></vb-widgets-general-6></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL6V1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-6v1></vb-widgets-general-6v1></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL7'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-7></vb-widgets-general-7></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL8'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-8></vb-widgets-general-8></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL16'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL26'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-26></vb-widgets-general-26></div>
      </div>
    </div>
    <div class="col-lg-8 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL15'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-15></vb-widgets-general-15></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL17'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-17></vb-widgets-general-17></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL17V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-17v1></vb-widgets-general-17v1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL17V2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-17v2></vb-widgets-general-17v2></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL18'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-18></vb-widgets-general-18></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL18V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-18v1></vb-widgets-general-18v1></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL19'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-19></vb-widgets-general-19></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL20'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-20></vb-widgets-general-20></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL21'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-success text-white rounded">
          <vb-widgets-general-21></vb-widgets-general-21>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL21V2'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-light rounded">
          <vb-widgets-general-21v2></vb-widgets-general-21v2>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL20V1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-general-20v1></vb-widgets-general-20v1></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL21V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-primary text-white rounded">
          <vb-widgets-general-21v1></vb-widgets-general-21v1>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL21V3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-danger text-white rounded">
          <vb-widgets-general-21v3></vb-widgets-general-21v3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL22'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-22></vb-widgets-general-22></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL23'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-23></vb-widgets-general-23></div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL23V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-23v1></vb-widgets-general-23v1></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL24'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-primary text-white rounded">
          <vb-widgets-general-24></vb-widgets-general-24>
        </div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL24V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body bg-light text-dark rounded">
          <vb-widgets-general-24v1></vb-widgets-general-24v1>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL25'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-25></vb-widgets-general-25></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'GENERAL25V1'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-25v1></vb-widgets-general-25v1></div>
      </div>
    </div>
  </div>
</div>
