import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-ausiliare',
  templateUrl: './main-ausiliare.component.html',
  styleUrls: ['./main-ausiliare.component.scss']
})
export class MainAusiliareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
