import { Component, OnInit, ViewChild } from '@angular/core'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { it_IT, NzI18nService } from 'ng-zorro-antd/i18n'
import { AntdModule } from 'src/app/antd.module'
import { CardColture } from 'src/app/models/precisionfarming-models/CardColture'
import { HttpService } from 'src/app/services/shared/http-services/http.services'

@Component({
  selector: 'app-c-dashboard',
  templateUrl: './c-dashboard.component.html',
  styleUrls: ['./c-dashboard.component.scss'],
})
export class CDashboardComponent implements OnInit {
  loading: boolean
  date = null
  date1: any
  date2: any
  monthPlaceholder: string[] = ["Mese d'inizio", 'Mese di fine']
  yearPlaceholder: string[] = ["Anno d'inizio", 'Anno di fine']
  cardColture: CardColture

  onChange(result: Date[]): void {
    console.log('onChange: ', result)
  }

  constructor(private httpService: HttpService, private i18n: NzI18nService) {}

  ngOnInit() {
    this.getData('')
    this.getCardColture()
    this.i18n.setLocale(it_IT)
  }

  getData(event) {
    this.loading = true
    this.httpService.get('Fabbricato/GetFabbricato' + event).subscribe(
      res => {
        console.log('dashboard')
        if (res.isSuccess) {
          this.loading = false
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  getCardColture() {
    this.loading = true
    this.httpService.get('Colture/GetColtureDashboard').subscribe(
      res => {
        if (res.isSuccess) {
          this.cardColture = res.colture
          console.log('this.cardColture')
          console.log(this.cardColture)
          this.loading = false
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }
}
