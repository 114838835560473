<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-12></vb-widgets-charts-12></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-12v1></vb-widgets-charts-12v1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-12v1></vb-widgets-charts-12v1></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-19></vb-widgets-lists-19></div>
      </div>
    </div>
    <div class="col-lg-8 col-md-12">
      <div class="card card-top">
        <div class="card-body"><vb-widgets-charts-7></vb-widgets-charts-7></div>
      </div>
    </div>
  </div>
</div>
