import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-widgets-general',
  templateUrl: './general.component.html',
})
export class WidgetsGeneralComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
