<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-2 [data]="{'title':'Introduction'}"></vb-headers-card-header-2>
        </div>
        <div class="card-body">
          <vb-controls-button-2
            [data]="{'title':'New Request','icon':'fe fe-plus-circle','type':'primary'}"
          ></vb-controls-button-2>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-2 [data]="{'title':'Revenue'}"></vb-headers-card-header-2>
        </div>
        <div class="card-body"><vb-widgets-general-1></vb-widgets-general-1></div>
      </div>
      <div class="card"><vb-widgets-general-6></vb-widgets-general-6></div>
      <div class="card"><vb-widgets-general-6v1></vb-widgets-general-6v1></div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-2 [data]="{'title':'Introduction'}"></vb-headers-card-header-2>
        </div>
        <div class="card-body"><vb-placeholders-1></vb-placeholders-1></div>
      </div>
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-2 [data]="{'title':'Activity Stream'}"></vb-headers-card-header-2>
        </div>
        <div class="card-body"><vb-widgets-lists-11></vb-widgets-lists-11></div>
      </div>
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-2 [data]="{'title':'Statistics'}"></vb-headers-card-header-2>
        </div>
        <div class="card-body"><vb-widgets-tables-1></vb-widgets-tables-1></div>
      </div>
    </div>
  </div>
</div>
