<div class="dark-text mb-3 mr-3 card-2 card-dashboard" (click)="onClick()">
  <div class="d-flex flex-row justify-content-between align-items-center p-0">
    <div class="data-container d-flex justify-content-center align-items-center">
      <div class="text-break fw-bold nobr">
        <span *ngIf="currency">&euro; &nbsp; {{data}}</span>
        <!-- <span *ngIf="currencyOnKg">&euro;/KG&nbsp;</span> -->
        <span *ngIf="currencyOnKg">&euro;&nbsp; {{data*production}}</span>
        <span *ngIf="!currency && !currencyOnKg">{{data}}</span>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center p-0 w-100">
      <div>
        <div class="text-break fw-bold nobr text-left ml-4 row"><div *ngIf="cardCosti">Costi&nbsp;</div>{{title}}</div>
        <div *ngIf="currencyOnKg" class="text-break text-left ml-4 fs-11">PRODUZIONE ATTESA:&nbsp;{{production}}&nbsp;Kg</div>
      </div>
      <div style="width: 60px;" class="d-flex align-items-center justify-content-center">
        <i [className]="icon" style="font-size: 30px;"></i>
      </div>
    </div>
  </div>
</div>