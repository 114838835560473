<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-app-partials-do-head></vb-app-partials-do-head></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Choose an Image'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-app-partials-do-image></vb-app-partials-do-image></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Choose Plan'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-app-partials-do-plan></vb-app-partials-do-plan></div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card card-top card-top-primary">
        <div class="card-body">
          <vb-controls-button-3
            [data]="{'mainTitle':'Update','mainType':'primary','additionalTitle':'Cancel'}"
          ></vb-controls-button-3>
        </div>
      </div>
    </div>
  </div>
</div>
