import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-github-discuss',
  templateUrl: './github-discuss.component.html',
})
export class ExtraAppsGithubDiscussComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
