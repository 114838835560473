import { formatDate } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { AnagraficaAnimale } from 'src/app/models/precisionfarming-models/AnagraficaAnimale'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { MManageMandriaComponent } from '../manage-select-fields-modals/m-manage-mandria/m-manage-mandria.component'
import { MManageRazzeComponent } from '../manage-select-fields-modals/m-manage-razze/m-manage-razze.component'

@Component({
  selector: 'app-m-edit-anagrafica-animale',
  templateUrl: './m-edit-anagrafica-animale.component.html',
  styleUrls: ['./m-edit-anagrafica-animale.component.scss'],
  providers: [BsModalService],
})
export class MEditAnagraficaAnimaleComponent implements OnInit {
  form: FormGroup
  idRazza: number;
  idMandria: number;
  currentDate: any
  currentDate1: any
  currentDate2: any
  currentDate3: any
  currentDate4: any
  selectedValue: any
  loadingSelect: boolean
  loading: boolean
  placeholderRazze: string = ""
  placeholderMandrie: string = ""
  rimontaNaturale: boolean = false
  toClose: boolean = false
  deleteButton: boolean
  @Output() onClose = new EventEmitter()
  @Output() onButtonActive = new EventEmitter();
  @Input() item
  @Input() title
  @Input() isEdit = false
  @Input() isAdd = false
  @Input() button
  razze: any
  mandrie: any
  modalRef: BsModalRef;

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      dataNascita: ['', Validators.required],
      dataSvezzamento: ['', Validators.required],
      dataMorte: ['', Validators.required],
      dataRimonta: ['', Validators.required],
      dataIngressoMandria: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.currentDate1 = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.currentDate = this.currentDate1
    this.currentDate2 = this.currentDate1
    this.currentDate3 = this.currentDate1
    this.currentDate4 = this.currentDate1
    this.getRazze();
    this.getMandrie();
    if (this.item) {
      this.getData()
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit()
    }
  }

  postForm() {
    this.loading = true
    let f: AnagraficaAnimale = new AnagraficaAnimale()
    f.dataNascita = this.f.dataNascita.value
    f.dataSvezzamento = this.f.dataSvezzamento.value
    f.dataMorte = this.f.dataMorte.value
    f.rimontaNaturale = this.rimontaNaturale
    f.dataRimonta = this.f.dataRimonta.value
    f.dataIngressoMandria = this.f.dataIngressoMandria.value
    f.idRazza = this.idRazza
    f.idMandria = this.idMandria

    if (this.isEdit) {
      f.id = this.item.id
      this.httpService.put('AnagraficaAnimale/EditAnagraficaAnimale', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.idRazza = undefined
            this.idMandria = undefined
            this.form.reset()
            this.closeModal()
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    } else {
      this.httpService.post('AnagraficaAnimale/AddAnagraficaAnimale', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.idRazza = undefined
            this.idMandria = undefined
            this.form.reset()
            this.closeModal()
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    }
  }

  getData() {
    this.loading = true
    this.httpService.get('AnagraficaAnimale/GetAnagraficaAnimaleById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          console.log('res.anagraficaAnimale.dataNascita')
          console.log(res.anagraficaAnimale.dataNascita)
          this.loading = false
          this.idRazza = res.anagraficaAnimale.idRazza
          this.idMandria = res.anagraficaAnimale.idMandria
          this.rimontaNaturale = res.anagraficaAnimale.rimontaNaturale
          this.form.patchValue({
            dataNascita: formatDate(res.anagraficaAnimale.dataNascita, 'yyyy-MM-dd', 'en'),
            dataSvezzamento: formatDate(res.anagraficaAnimale.dataSvezzamento, 'yyyy-MM-dd', 'en'),
            dataMorte: formatDate(res.anagraficaAnimale.dataMorte, 'yyyy-MM-dd', 'en'),
            dataRimonta: formatDate(res.anagraficaAnimale.dataRimonta, 'yyyy-MM-dd', 'en'),
            dataIngressoMandria: formatDate(res.anagraficaAnimale.dataIngressoMandria, 'yyyy-MM-dd', 'en'),
          })
        } else {
          this.loading = false
          console.log(res)
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  getRazze() {
    this.placeholderRazze = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Razze').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.razze?.length) {
            this.placeholderRazze = "Non ci sono razze"
          }
          this.razze = res.razze
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getMandrie() {
    this.placeholderMandrie = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Mandrie').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.mandrie?.length) {
            this.placeholderMandrie = "Non ci sono mandrie"
          }
          this.mandrie = res.mandrie;
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  editRazze() {
    // this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        // item: item,
        title: 'Dettagli razza',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageRazzeComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getRazze()
    })
  }

  addRazze() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi razza',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageRazzeComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getRazze()
    })
  }

  editMandria() {
    // this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        // item: item,
        title: 'Dettagli mandria',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageMandriaComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getMandrie()
    })
  }

  addMandria() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi mandria',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageMandriaComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getMandrie()
    })
  }

}


