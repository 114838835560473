import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpService } from 'src/app/services/shared/http-services/http.services';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QRCodeComponent implements OnInit {

  scannerEnabled: boolean = false;
  panels = [
    {
      active: false,
      name: 'Avvia ricerca tramite QR code',
      disabled: false,
    },
  ]
  isMezzo: boolean = false;
  isMagazzino: boolean = false;
  isTerreno: boolean = false;
  isUtensile: boolean = false;
  isAusiliare: boolean = false;
  type: string;
  loading: boolean = false;
  data: any;
  
  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
  ) { }

  ngOnInit(): void {
  }

  getData(qrCode) {
    this.loading = true
    this.httpService.get('QrCode/QrCodeSearch?QrCode=' + qrCode).subscribe(
      res => {
        if (res.isSuccess) {
          this.type = res.tipoDato
          this.data = res.qrData
          console.log('res')
          console.log(this.data)
          this.notification.success('Lettura QR code effettuata con successo!')
          this.scannerEnabled = false
          this.loading = false
        } else {
          this.loading = false
          this.notification.error(res.errorMessageIta)
        }
      },
      err => {
        this.loading = false
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
      },
    )
  }

  onActivate(e) {
    this.data = undefined;
    this.type = undefined;
    console.log('e')
    console.log(e)
    if (e) {
      console.log('è att')
      this.scannerEnabled = true;
    } else {
      console.log('non è att')
      this.scannerEnabled = false;
    }
  }

  
}
