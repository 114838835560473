export class Cliente {

  id: number
  nome: string
  cognome: string
  ragioneSociale: string
  pIva: string
  indirizzo: string
  codiceFiscale: string
  email: string
  telefono: string

}