import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-ui-kits-antd',
  templateUrl: './antd.component.html',
})
export class UiKitsAntdComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
