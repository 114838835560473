<div class="m-1">
    <div class="mb-4">
      <nz-collapse>
        <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
          [nzDisabled]="panel.disabled">
          <app-m-manage-razze button='Aggiungi' (onButtonActive)="getData('')"></app-m-manage-razze>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <caiwa-generic-table [items]="data$" [displayedColumns]="colonne" [isCheckable]="false" [rowClick]="false"
      [updateBtn]="true" [deleteBtn]="true" [isFiltrable]="true" [loading]="loading" [tableWidth]="tableWidth"
      (updateBtnAction)="edit($event)" (deleteBtnAction)="delete($event)" (filterCustom)="getData($event)"
      tableSize="small">
    </caiwa-generic-table>
  </div>