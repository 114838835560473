import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { MManageTipoAttivitaComponent } from 'src/app/modals/precisionfarming-modals/manage-select-fields-modals/m-manage-tipo-attivita/m-manage-tipo-attivita.component';
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn';
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService';

@Component({
  selector: 'app-tipo-attivita',
  templateUrl: './tipo-attivita.component.html',
  styleUrls: ['./tipo-attivita.component.scss'],
  providers: [BsModalService],
})
export class TipoAttivitaComponent implements OnInit {

  loadingSelect: boolean
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  deleteButton: boolean = false
  modalRef: BsModalRef
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Nome', dataKey: 'nome', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any
  panels = [
    {
      active: false,
      name: 'Aggiungi tipo attività',
      disabled: false,
    },
  ]

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {}

  ngOnInit(): void {
    this.getData('')
  }

  getData(event) {
    this.loading = true
    this.httpService.get('TipoAttivita/GetTipoAttivita' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          //
          this.data$ = res.tipiAttivita
          console.log('res')
          console.log(this.data$)
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  edit(item) {
    this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        item: item,
        title: 'Dettagli tipo attività',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTipoAttivitaComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi tipo attività',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTipoAttivitaComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  delete(item) {
    this.loading = true
    let id = { id: item.id }
    this.httpService.delete('TipoAttivita/DeleteTipoAttivita', id).subscribe(
      res => {
        if (res.isSuccess) {
          this.notification.success('Operazione effettuata con successo!')
          this.getData('')
        } else {
          this.notification.error(res.errorMessageIta)
        }
        console.log('res add user')
        console.log(res)
        // this.getRole('')
        this.loading = false
      },
      err => {
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
        this.loading = false
      },
    )
  }

}
