<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder"><vb-headers-tag [data]="{'title':'Tag'}"></vb-headers-tag></div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-1></vb-widgets-lists-1></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST5'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-5></vb-widgets-lists-5></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST8'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-8></vb-widgets-lists-8></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST11'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-11></vb-widgets-lists-11></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST14'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-14></vb-widgets-lists-14></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST17'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-17></vb-widgets-lists-17></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST20'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-20></vb-widgets-lists-20></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST25'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-25></vb-widgets-lists-25></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST28'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-28></vb-widgets-lists-28></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST2'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-lists-2></vb-widgets-lists-2></div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST6'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-6></vb-widgets-lists-6></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST9'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-9></vb-widgets-lists-9></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST12'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-12></vb-widgets-lists-12></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST15'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-15></vb-widgets-lists-15></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST19'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-19></vb-widgets-lists-19></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST22'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-22></vb-widgets-lists-22></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST26'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-26></vb-widgets-lists-26></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST27'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-27></vb-widgets-lists-27></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST3'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-3></vb-widgets-lists-3></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST4'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-4></vb-widgets-lists-4></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST7'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-7></vb-widgets-lists-7></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST10'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-10></vb-widgets-lists-10></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST13'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-13></vb-widgets-lists-13></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST16'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-16></vb-widgets-lists-16></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST18'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-18></vb-widgets-lists-18></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST23'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-23></vb-widgets-lists-23></div>
      </div>
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST24'}"></vb-headers-tag>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-24></vb-widgets-lists-24></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST21'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-lists-21></vb-widgets-lists-21></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST21V1'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-lists-21v1></vb-widgets-lists-21v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <vb-headers-tag [data]="{'title':'LIST21V2'}"></vb-headers-tag>
      </div>
      <div class="card"><vb-widgets-lists-21v2></vb-widgets-lists-21v2></div>
    </div>
  </div>
</div>
