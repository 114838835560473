import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash'
import { select, Store } from '@ngrx/store'
import { MenuService } from 'src/app/services/menu'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'vb-menu-classic-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class MenuClassicLeftComponent implements OnInit {
  menuColor: String
  isMenuShadow: Boolean
  isMenuUnfixed: Boolean
  isSidebarOpen: Boolean
  isMobileView: Boolean
  leftMenuWidth: Number
  isMenuCollapsed: Boolean
  logo: String
  menuData: any[]
  menuDataActivated: any[]
  role: String
  isMobileMenuOpen: any
  @Output() onClickLink = new EventEmitter()

  constructor(private menuService: MenuService, private store: Store<any>, private router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.role
    })
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.menuColor = state.menuColor
      this.isMenuShadow = state.isMenuShadow
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isSidebarOpen = state.isSidebarOpen
      this.isMobileView = state.isMobileView
      this.leftMenuWidth = state.leftMenuWidth
      this.isMenuCollapsed = state.isMenuCollapsed
      this.logo = state.logo
    })
  }

  ngOnInit() {
    this.activateMenu(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.activateMenu(event.url ? event.url : null)
      })
    this.onCollapse(false)
    // this.onCollapse(true);
  }

  emitOnClickLink() {
    this.onClickLink.emit()
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData))
    const pathWithSelection = this.getPath({ url: url }, menuData, (entry: any) => entry, 'url')
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true
      _.each(pathWithSelection, (parent: any) => (parent.open = true))
    }
    this.menuDataActivated = menuData.slice()
  }

  getPath(
    element: any,
    source: any,
    property: any,
    keyProperty = 'key',
    childrenProperty = 'children',
    path = [],
  ) {
    let found = false
    const getElementChildren = (value: any) => _.get(value, childrenProperty)
    const getElementKey = (value: any) => _.get(value, keyProperty)
    const key = getElementKey(element)
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key) {
          path.push(e)
          return true
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e),
          ))
        }
      }) &&
      (found || _.map(path, property))
    )
  }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    )
  }

  onCollapse(value: any) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value,
      }),
    )
    this.removeUnSelectedItem()
  }

  // onCollapse(value: any) {
  //   this.store.dispatch(
  //     new SettingsActions.SetStateAction({
  //       isMenuCollapsed: value,
  //     }),
  //   )
  // }

  // prova stile menu
  selectedItem: HTMLCollection
  unSelectedItem: HTMLCollection

  position: any
  element: any

  removeUnSelectedItem() {
    this.unSelectedItem = document.getElementsByClassName('unselectedItem')
    console.log('unselected')
    console.log(this.unSelectedItem)
    if (this.unSelectedItem.item(0)) {
      document.body.removeChild(this.unSelectedItem.item(0))
    }
  }

  onSelected() {
    this.removeUnSelectedItem()

    setTimeout(() => {
      this.selectedItem = document.getElementsByClassName('ant-menu-item-selected')
      let elementLeft = this.selectedItem.item(0).getBoundingClientRect().right - 20

      // this.position= this.selectedItem.getBoundingClientRect();
      console.log('position')
      console.log(this.selectedItem.item(0).getBoundingClientRect())
      this.element = document.createElement('div')
      this.element.style.height = '40px'
      this.element.style.width = '35px'
      this.element.style.backgroundColor = '#0F2A49'
      this.element.style.position = 'fixed'
      this.element.style.zIndex = '2'
      this.element.style.borderBottomRightRadius = ' 14px'
      this.element.style.borderTopRightRadius = ' 14px'
      this.element.style.top =
        this.selectedItem
          .item(0)
          .getBoundingClientRect()
          .top.toString() + 'px'
      this.element.style.left = elementLeft.toString() + 'px'
      this.element.classList.add('unselectedItem')
      document.body.appendChild(this.element)
    }, 300)
  }

  // @HostListener('document:scroll', ['$event'])
  onScroll() {
    console.log('scroll')
  }
}
