<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-app-partials-do-head></vb-app-partials-do-head></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4></vb-widgets-charts-4></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v3></vb-widgets-charts-4v3></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v1></vb-widgets-charts-4v1></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Droplets (2)'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body"><vb-app-partials-do-server></vb-app-partials-do-server></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-app-partials-do-server></vb-app-partials-do-server></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Create Something New'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-8></vb-widgets-lists-8></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-6></vb-widgets-lists-6></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-5></vb-widgets-lists-5></div>
      </div>
    </div>
  </div>
</div>
