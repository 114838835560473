import { Component, OnInit } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { MEditClienteComponent } from 'src/app/modals/precisionfarming-modals/m-edit-cliente/m-edit-cliente.component'
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn'
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService'

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
  providers: [BsModalService],
})
export class ClienteComponent implements OnInit {
  loadingSelect: boolean
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  // data$: any;
  deleteButton: boolean = false
  modalRef: BsModalRef
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Nome', dataKey: 'nome', columnWidth: '170px' },
    { name: 'Cognome', dataKey: 'cognome', columnWidth: '170px' },
    { name: 'Ragione sociale', dataKey: 'ragioneSociale', columnWidth: '170px' },
    { name: 'Partita IVA', dataKey: 'pIva', columnWidth: '170px' },
    { name: 'Indirizzo', dataKey: 'indirizzo', columnWidth: '170px' },
    { name: 'Codice fiscale', dataKey: 'codiceFiscale', columnWidth: '170px' },
    { name: 'E-mail', dataKey: 'email', columnWidth: '170px' },
    { name: 'Telefono', dataKey: 'telefono', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any
  panels = [
    {
      active: false,
      name: 'Aggiungi cliente',
      disabled: false,
    },
  ]

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {}

  ngOnInit(): void {
    this.getData('')
  }

  getData(event) {
    this.loading = true
    this.httpService.get('Cliente/GetCliente' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          //
          this.data$ = res.clienti
          console.log('res')
          console.log(res)
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  edit(item) {
    this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        item: item,
        title: 'Dettagli cliente',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditClienteComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi cliente',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditClienteComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  delete(item) {
    this.loading = true
    let id = { id: item.id }
    this.httpService.delete('Cliente/DeleteCliente', id).subscribe(
      res => {
        this.notification.success('Operazione effettuata con successo!')
        this.getData('')
        this.loading = false
      },
      err => {
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
        this.loading = false
      },
    )
  }
}
