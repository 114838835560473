<div class="modal-header" *ngIf="isEdit || isAdd">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <nz-spin [nzSpinning]="loading">
    <div class="modal-body" style="position: relative;">
      <form [formGroup]="form">
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="nome" class="mb-0 col-form-label">Nome*</label>
            <input type="text" class="form-control" formControlName="nome" id="nome">
          </div>
          <div class="col-lg-4">
            <label for="idTipo" class="mb-0 col-form-label">Ausiliare*</label>
            <nz-select [(ngModel)]="idTipo" [ngModelOptions]="{standalone: true}" id="idTipo" class="form-control"
              [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderAusiliari" [nzDisabled]="!tipi">
              <nz-option *ngFor="let opt of tipi" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
              <button class="btn btn-link fw-bold" (click)="addTipoAusiliare()">
                <i class="fe fe-plus mr-1"></i>
                <span class="text-underline">Aggiungi ausiliare</span>
              </button>
              <button class="btn btn-link fw-bold" routerLink="/manage-data/types-auxiliaries">
                <i class="fe fe-edit-2 mr-1"></i>
                <span class="text-underline">Gestisci ausiliare</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <span class="text-danger">*Campi obbligatori</span>
      <div class="form-row d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!form.valid" (click)="postForm()">
          <span class="btnAddon">
            <i class="btnAddonIcon fe fe-plus-circle"></i>
          </span>
          {{button}}
        </button>
      </div>
    </div>
  </nz-spin>
