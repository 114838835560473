import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-tables-antd',
  templateUrl: './antd.component.html',
})
export class TablesAntdComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
