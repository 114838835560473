<div class="card">
  <div class="card-header ">
    <div class="row justify-content-between m-0 ">
      <div><strong>Fattura #0001</strong></div>
      <div><strong>Data: </strong> {{currentDate}}</div>
    </div>
  </div>
  <div class="card-body mb-4 py-3">
    <div class="row justify-content-between mb-3">
      <div class="col-lg-6 mb-3">
        <p><strong>Emittente:</strong></p>
        <hr>
        <p>
          <strong class="my-2">Azienda 1</strong><br>
          Indirizzo: via Rossi, 3<br>
          Telefono: 094567865<br>
          E-mail: azienda1@az.it
        </p>
      </div>
      <div class="col-lg-6 mb-3">
        <p><strong>Destinatario:</strong> </p>
          <hr>
          <p>
          <strong class="my-2">Azienda 2</strong><br>
          Indirizzo: via Doppi, 2<br>
          Telefono: 094565865<br>
          E-mail: azienda2@az.it
        </p>
      </div>

    </div>
    <nz-table #basicTable [nzData]="listOfData" nzShowPagination='false' nzSize='small' [nzScroll]="{ x: '1100px' }">
      <thead>
        <tr>
          <th>#</th>
          <th>Descrizione</th>
          <th>Quantità</th>
          <th>Prezzo unitario</th>
          <th>Importo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{data.id}}</td>
          <td>{{ data.descrizione }}</td>
          <td>{{ data.quantita }}</td>
          <td>{{ data.prezzoUnitario }}</td>
          <td>{{ data.quantita*data.prezzoUnitario}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">
            <strong>Totale parziale:</strong>
          </td>
          <td>{{getTotaleParziale()}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right"><strong>IVA:</strong></td>
          <td>0</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right"><strong>Totale:</strong></td>
          <td>{{getTotaleParziale()}}</td>
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right"><strong>Saldo dovuto:</strong></td>
          <td>{{getTotaleParziale()}}</td>
        </tr> -->
      </tbody>
    </nz-table>
  </div>
</div>