<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-2></vb-widgets-general-2></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-2v1></vb-widgets-general-2v1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-2v2></vb-widgets-general-2v2></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-lists-20></vb-widgets-lists-20></div>
      </div>
    </div>
    <div class="col-lg-8 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-tables-4></vb-widgets-tables-4></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body"><vb-widgets-tables-7></vb-widgets-tables-7></div>
      </div>
    </div>
  </div>
</div>
