import { Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode'

@Component({
  selector: 'app-m-qr-code',
  templateUrl: './m-qr-code.component.html',
  styleUrls: ['./m-qr-code.component.scss']
})
export class MQRCodeComponent implements OnInit {

  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  id: string;
  @Input() item;
  @Input() title;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  elementType = NgxQrcodeElementTypes.IMG
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH

  constructor() { }

  ngOnInit(): void {
    this.id = this.item.qrcode.toString()
    console.log('QR code')
    console.log(this.item)
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }


  private convertBase64ToBlob(Base64Image: any) {

    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1]
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }

  public downloadQRCode() {
    const fileNameToDownload = 'image_qrcode';
    const base64Img = document.getElementsByClassName('QRCode')[0].innerHTML;
    var mySubString = base64Img.substring(
      base64Img.indexOf('"') + 1,
      base64Img.lastIndexOf('"')
    );
    let blobData = this.convertBase64ToBlob(mySubString);
    console.log(mySubString)
    const blob = new Blob([blobData], { type: "image/png" });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'QRcode';
    link.click();
  }

}
