<div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-22></vb-widgets-lists-22></div>
      </div>
    </div>
    <div class="col-lg-9 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header py-0">
          <vb-headers-card-header-3 [data]="{'title':'Helen Maggie'}"></vb-headers-card-header-3>
        </div>
        <div class="card-body"><vb-widgets-general-14></vb-widgets-general-14></div>
      </div>
    </div>
  </div>
</div>
