export class Sensore {
  id: number
  temperatura: string
  umiditaAria: string
  umiditaAriaMisura: string
  umiditaTerreno: string
  umiditaTerrenoMisura: string
  ph: string
  luce: string
  luceMisura: string
  data: Date
  idTerreno: number
}