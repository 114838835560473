import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UtilizzoAusiliare } from 'src/app/models/precisionfarming-models/UtilizzoAusiliare';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { MEditAusiliareComponent } from '../../m-edit-ausiliare/m-edit-ausiliare.component';
import { MManageTipoAusiliareComponent } from '../m-manage-tipo-ausiliare/m-manage-tipo-ausiliare.component';

@Component({
  selector: 'app-m-manage-utilizzo-ausiliare',
  templateUrl: './m-manage-utilizzo-ausiliare.component.html',
  styleUrls: ['./m-manage-utilizzo-ausiliare.component.scss'],
  providers: [BsModalService],
})
export class MManageUtilizzoAusiliareComponent implements OnInit {

  form: FormGroup;
  modalRef: BsModalRef;
  currentDate: any;
  loadingSelect: boolean;
  loading: boolean;
  toClose: boolean = false;
  deleteButton: boolean;
  @Output() onClose = new EventEmitter();
  @Output() onButtonActive = new EventEmitter();
  @Input() item;
  @Input() title;
  @Input() isEdit = false;
  @Input() isAdd = false;
  @Input() button;
  tipi: any;
  idTipo: any;
  placeholderAusiliari: string = ""

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.getTipi();
    if (this.item) {
      this.getData();
    }
  }

  get f() {
    return this.form.controls
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit();
    }
  }

  postForm() {
    this.loading = true
    let f: UtilizzoAusiliare = new UtilizzoAusiliare()
    f.nome = this.f.nome.value
    f.idAusiliare = this.idTipo


    if (this.isEdit) {
      f.id = this.item.id;
      this.httpService.put('UtilizzoAusiliare/EditUtilizzoAusiliare', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTipo = undefined;
            this.form.reset()
            this.closeModal();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    } else {
      this.httpService.post('UtilizzoAusiliare/AddUtilizzoAusiliare', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success("Operazione effettuata con successo!");
            this.toClose = true;
            this.loading = false;
            this.idTipo = undefined;
            this.form.reset()
            this.closeModal();
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta);
          }
          console.log('res');
          console.log(res);
          this.loading = false;
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!");
          console.log(err);
          this.loading = false;
        },
      )
    }

  }

  getData() {
    this.loading = true;
    this.httpService.get('UtilizzoAusiliare/GetUtilizzoAusiliareById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false;
          this.idTipo = res.utilizzoausiliare.idAusiliare
          this.form.patchValue({
            nome: res.utilizzoausiliare.nome,
          })
        } else {
          this.loading = false;
          console.log(res);
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      },
    )
  }

  getTipi() {
    this.placeholderAusiliari = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Ausiliare').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.ausiliare?.length) {
            this.placeholderAusiliari = "Non ci sono ausiliari"
          }
          this.tipi = res.ausiliare
          console.log('tipi')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  addTipoAusiliare() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi ausiliare',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditAusiliareComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getTipi()
    })
  }

}
