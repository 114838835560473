import { Injectable } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CustomField } from 'src/app/models/shared/CustomField'

@Injectable()
export class FieldControlService {
  constructor() { }

  toFormGroup(fields: CustomField<string>[]) {
    const group: any = {}

    fields.forEach(field => {
      if (!field.hasBlankSpaces) {
        group[field.key] = field.required
          ? new FormControl(field.value || '', Validators.required)
          : new FormControl(field.value || '')
      } else {
        group[field.key] = new FormControl(field.value || '', [
          Validators.required,
          this.noWhitespaceValidator,
        ])
      }
      // if (field.type == 'email') {
      //   group[field.key] = new FormControl(field.value || '', [Validators.required, Validators.email])
      // }
    })
    return new FormGroup(group)
  }

  public noWhitespaceValidator(control: FormControl) {
    let username: string = control.value

    for (let i = 0; i < username.length; i++) {
      if (username.charAt(i) === ' ') {
        return { whitespace: true }
      }
    }

    return null
    // <div *ngIf="yourForm.hasError('whitespace')">Please don't use white spaces</div>
  }
}
