<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <pdf-viewer src="https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf" [original-size]="false"
    [autoresize]="true" style="width: 100%; height: 500px"></pdf-viewer>
  <div class="modal-footer d-flex justify-content-between">
    <div class="form-row d-flex justify-content-end w-100">
      <button type="submit" class="btn btn-primary btnWithAddon" (click)="saveByteArray()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-download"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>