import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-jira-dashboard',
  templateUrl: './jira-dashboard.component.html',
})
export class ExtraAppsJiraDashboardComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
