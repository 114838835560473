<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Chartist.js','button':'Go to Docs','url':'https://gionkunz.github.io/chartist-js/'}"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header
            [data]="{'title':'CSS Styling & Animations'}"
          ></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-1></vb-charts-chartistjs-1></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Line'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-3></vb-charts-chartistjs-3></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Scatter'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-5></vb-charts-chartistjs-5></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Bi-polar Line'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-7></vb-charts-chartistjs-7></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Stacked Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-9></vb-charts-chartistjs-9></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Simple Pie'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-11></vb-charts-chartistjs-11></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'SMIL Animations'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-2></vb-charts-chartistjs-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Area'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-4></vb-charts-chartistjs-4></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Horizontal Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-6></vb-charts-chartistjs-6></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Bi-polar Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-8></vb-charts-chartistjs-8></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Overlapping Bar'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-10></vb-charts-chartistjs-10></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Pie w/ Labels'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-charts-chartistjs-12></vb-charts-chartistjs-12></div>
      </div>
    </div>
  </div>
</div>
