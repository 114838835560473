import { Component, Input, OnInit } from '@angular/core';
import ChartistTooltip from 'chartist-plugin-tooltips-updated'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input() title: string;
  @Input() titleData: number;
  loading: boolean
  date = null;
  date1: any;
  date2: any;
  monthPlaceholder: string[] = ['Mese d\'inizio','Mese di fine'];
  yearPlaceholder: string[] = ['Anno d\'inizio','Anno di fine'];
  chartData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    series: [
      [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
    ]
  };

  chartOptions = {
    chartPadding: {
      right: 0,
      left: 0,
      top: 5,
      bottom: 5,
    },
    fullWidth: true,
    showPoint: true,
    lineSmooth: true,
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0,
    },
    axisX: {
      showGrid: true,
      showLabel: true,
      offset: 20,
    },
    showArea: false,
    plugins: [
      ChartistTooltip({
        anchorToPoint: false,
        appendToBody: true,
        seriesName: false,
      }),
    ],
  }

  onChange(result: Date[]): void {
    console.log('onChange: ', result);
  }
  

  constructor() { }

  ngOnInit(): void {
  }

}
