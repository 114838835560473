import { Injectable, OnDestroy } from '@angular/core'

import adminClaims from './claims/admin.claims'
import userClaims from './claims/user.claims'

// import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// key that is used to access the data in local storage
const USER_CLAIMS_KEY = 'USER_CLAIMS'
const USER_NAME = 'USER_NAME'

// import * as CryptoJS from 'crypto-js';

// @Injectable({
//   providedIn: 'root'
// })
// export class AESEncryptDecryptService {

//   secretKey = 'PFauvKLJ8eH60wA76uJ7cwSyxqJ2rLnhmQNSkKtywBhPKqsdVVTgbJg5BOYa';

//   encrypt(value: string): string {
//     return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
//   }

//   decrypt(textToDecrypt: string) {
//     return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
//   }

// }

@Injectable()
export class UserClaimsService implements OnDestroy {
  private _destroyed$ = new Subject()
  dataService: any

  constructor() // private dataService: DataService,
  // private authService: AuthService,
  // private encryptionService: AESEncryptDecryptService,
  // @Inject(LOCAL_STORAGE) private storage: StorageService
  {}

  async userData() {
    return {
      userClaims: await this.getUserClaims(),
      userName: 'pasquale2', // this.storage.get(USER_NAME) ? this.encryptionService.decrypt(this.storage.get(USER_NAME)) : ''
    }
  }

  async getUserClaims(): Promise<any> {
    // const claims = [] // this.storage.get(USER_CLAIMS_KEY) ? JSON.parse(this.encryptionService.decrypt(this.storage.get(USER_CLAIMS_KEY))) : [];
    // const claimsChecked = !!+localStorage.getItem('checkedClaims') // this.storage.get('checkedClaims');

    // let userData = JSON.parse(localStorage.getItem('currentUser'));
    
    // let role = userData.role;

    // const claimsChecked = role == 1 ? true : false;

    // const claimsChecked = !!+localStorage.getItem('checkedClaims') // this.storage.get('checkedClaims');

    // if (!claimsChecked || typeof claimsChecked === 'undefined') {
    //   const res = await this.getSystemUserByEmail()

    //   if (Object.keys(res).length === 0) {
    //     return claims
    //   } else {
    //     // this.storage.set('checkedClaims', true);
    //     localStorage.setItem('checkedClaims', 'true')
    //     return Promise.resolve(res)
    //   }
    // } else {
    //   return Promise.resolve(claims)
    // }

    // const claimsChecked = !!+localStorage.getItem('checkedClaims') // this.storage.get('checkedClaims');

    // if (!claimsChecked || typeof claimsChecked === 'undefined') {
    //   const res = await this.getSystemUserByEmail()

    //   if (Object.keys(res).length === 0) {
    //     return claims
    //   } else {
    //     // this.storage.set('checkedClaims', true);
    //     localStorage.setItem('checkedClaims', 'true')
    //     return Promise.resolve(res)
    //   }
    // } else {
    //   return Promise.resolve(claims)
    // }

    const claims = undefined

    const claimsChecked = !!+localStorage.getItem('checkedClaims') // this.storage.get('checkedClaims');

    if (!claimsChecked || typeof claimsChecked === 'undefined') {
      const res = await this.getSystemUserByEmail()

      if (!Object.keys(res)) {
        return claims
      } else {
        // this.storage.set('checkedClaims', true);
        localStorage.setItem('checkedClaims', 'true')
        return Promise.resolve(res)
      }
    } else {
      return Promise.resolve(claims)
    }
  }

  // crypto-js
  // ngx-webstorage-service

  async getSystemUserByEmail(): Promise<any> {

     let userData = JSON.parse(localStorage.getItem('currentUser'));
    
      let role = userData.role;

      return role;
    

    // const email = 'pasquale@gmail.com' // this.authService.getUserInfo() ? this.authService.getUserInfo().userName : '';

    // if (email && typeof email !== 'undefined') {
    //   const promise = new Promise((resolve, reject) => {
    //     this.dataService
    //       .getServiceCall(`API?USER=${email}`)
    //       .pipe(takeUntil(this._destroyed$))
    //       .subscribe(
    //         (response: any) => {
    //           let claimsArray: string[] = []
    //           const roleId = response.RoleId

    //           if (roleId === 1) {
    //             claimsArray = adminClaims
    //           } else if (roleId === 2) {
    //             claimsArray = userClaims
    //           }

    //           // this.storage.set(USER_NAME, this.encryptionService.encrypt(sysUser.Name + ' ' + sysUser.Surname));
    //           // this.storage.set(USER_CLAIMS_KEY, this.encryptionService.encrypt(JSON.stringify(claimsArray)));

    //           resolve(claimsArray)
    //         },
    //         () => {
    //           reject([])
    //         },
    //       )
    //   })

    //   return await promise
    // } else {
    //   return []
    // }
  }

  ngOnDestroy() {
    this._destroyed$.next()
    this._destroyed$.complete()
  }
}
