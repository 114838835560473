<div class="row d-flex align-item-center justify-content-center">
  <div class="card p-5">
    <h2 class="my-3">Scegli tipo di attività</h2>
    <button nz-button nzType="primary" class="text-center w-100 my-2">
      <strong>Produzione</strong>
    </button>
    <button nz-button nzType="primary" class="text-center w-100">
      <strong>Pulizia</strong>
    </button>
  </div>
</div>