import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { MEditAnagraficaAnimaleComponent } from 'src/app/modals/precisionfarming-modals/m-edit-anagrafica-animale/m-edit-anagrafica-animale.component'
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn'
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService'

@Component({
  selector: 'app-anagrafica-animale',
  templateUrl: './anagrafica-animale.component.html',
  styleUrls: ['./anagrafica-animale.component.scss'],
  providers: [BsModalService],
})
export class AnagraficaAnimaleComponent implements OnInit {
  form: FormGroup
  currentDate: any
  selectedValue: any
  loadingSelect: boolean
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  deleteButton: boolean = false
  modalRef: BsModalRef
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Razza', dataKey: 'razza', columnWidth: '170px' },
    { name: 'Data nascita', dataKey: 'dataNascita', columnWidth: '170px' },
    { name: 'Data svezzamento', dataKey: 'dataSvezzamento', columnWidth: '170px' },
    { name: 'Data morte', dataKey: 'dataMorte', columnWidth: '170px' },
    { name: 'Rimonta naturale', dataKey: 'rimontaNaturale', columnWidth: '170px' },
    { name: 'Data rimonta', dataKey: 'dataRimonta', columnWidth: '170px' },
    { name: 'Mandria', dataKey: 'mandria', columnWidth: '170px' },
    { name: 'Data ingresso mandria', dataKey: 'dataIngressoMandria', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any
  panels = [
    {
      active: false,
      name: 'Aggiungi anagrafica animale',
      disabled: false,
    },
  ]

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {
    this.form = this.formBuilder.group({
      dataNascita: ['', Validators.required],
      dataSvezzamento: ['', Validators.required],
      dataMorte: ['', Validators.required],
      rimontaNaturale: ['', Validators.required],
      dataRimonta: ['', Validators.required],
      dataIngressoMandria: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.getData('');
  }

  get f() {
    return this.form.controls
  }

  getData(event) {
    this.loading = true
    this.httpService.get('AnagraficaAnimale/GetAnagraficaAnimale' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          console.log('res anagrafica')
          console.log(res)
          this.data$ = res.anagraficheAnimale
         
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  edit(item) {
    this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        item: item,
        title: 'Dettagli anagrafica animale',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditAnagraficaAnimaleComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi Anagrafica Animale',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditAnagraficaAnimaleComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  delete(item) {
    this.loading = true
    let id = { id: item.id }
    this.httpService.delete('AnagraficaAnimale/DeleteAnagraficaAnimale', id).subscribe(
      res => {
        if (res.isSuccess) {
          this.notification.success('Operazione effettuata con successo!')
          this.getData('')
        } else {
          this.notification.error(res.errorMessageIta)
        }
        console.log('res add user')
        console.log(res)
        // this.getRole('')
        this.loading = false
      },
      err => {
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
        this.loading = false
      },
    )
  }
}
