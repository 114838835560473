<div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-body"><vb-widgets-general-10v1></vb-widgets-general-10v1></div>
      </div>
      <div class="card">
        <div class="card-body text-white bg-primary rounded">
          <vb-widgets-general-12v1></vb-widgets-general-12v1>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-1></vb-widgets-general-1></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-16></vb-widgets-lists-16></div>
      </div>
    </div>
    <div class="col-lg-8 col-md-12">
      <div class="card card-top card-top-primary">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'@mary.ableton (Mary Stanform)','description':'17,256 followers'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-6></vb-headers-card-header-tabbed-6>
        </div>
        <div class="card-body"><vb-widgets-general-15></vb-widgets-general-15></div>
        <div class="card-body"><vb-app-partials-wp-write></vb-app-partials-wp-write></div>
      </div>
    </div>
  </div>
</div>
