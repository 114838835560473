import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn'
import { formatDate } from '@angular/common'

@Component({
  selector: 'caiwa-generic-table',
  templateUrl: './c-generic-table.component.html',
  providers: [],
  styleUrls: ['./c-generic-table.component.scss'],
})
export class CGenericTableComponent implements OnInit {
  // DATA
  @Input() items: any[]
  @Input() displayedColumns: ITableColumn[]
  @Input() loading: boolean
  @Input() actionColumnSticky = true

  // STYLE
  @Input() tableWidth: string = '2500px'
  @Input() tableSize: string = 'default'

  // PAGINATION
  @Input() isPageable = false
  @Input() nzPageSizeOptions: number[] = []

  // EXTRA
  @Input() isCheckable = false
  @Input() isFiltrable = false

  // CLICKS AND BUTTONS
  @Input() rowClick = false
  @Input() updateBtn = false
  @Input() deleteBtn = false
  @Input() attachmentsBtn = false
  @Input() actionBtn = false
  @Input() actionDescription: string = ""
  @Input() actionIcon: string = ""
  @Input() textConfirmDelete: string = 'Sicuro di voler eliminare?'

  // CLICKS AND BUTTONS FUNCTIONS
  @Output() rowClickAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateBtnAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteBtnAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() attachmentsBtnAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() actionBtnAction: EventEmitter<any> = new EventEmitter<any>()
  @Output() filterCustom: EventEmitter<any> = new EventEmitter<any>()
  constructor() { }

  isAllDisplayDataChecked = false
  isOperating = false
  isIndeterminate = false
  listOfDisplayData: any[] = []
  mapOfCheckedId: { [key: string]: boolean } = {}
  numberOfChecked = 0
  regExpDate: RegExp = /^[0-9]{4}[\-]{1}[0-9]{2}[\-]{1}[0-9]{2}/g
  tooltipVisible: boolean = false;



  onSearchChange(searchValue: string): void {
    if (searchValue.length == 0 || searchValue.length >= 3) this.filterCustom.next(searchValue)
  }

  emitRowClickAction() {
    this.rowClickAction.emit()
  }

  emitUpdateBtnAction(item) {
    this.updateBtnAction.emit(item)
  }

  emitDeleteBtnAction(item) {
    this.deleteBtnAction.emit(item)
  }

  emitAttachmentsBtnAction(item) {
    this.attachmentsBtnAction.emit(item)
  }

  emitActionBtnAction(item) {
    this.actionBtnAction.emit(item)
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event
    this.refreshStatus()
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter(item => !item.disabled)
      .every(item => this.mapOfCheckedId[item.id])
    this.isIndeterminate =
      this.listOfDisplayData
        .filter(item => !item.disabled)
        .some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked
    this.numberOfChecked = this.items.filter(item => this.mapOfCheckedId[item.id]).length
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData
      .filter(item => !item.disabled)
      .forEach(item => (this.mapOfCheckedId[item.id] = value))
    this.refreshStatus()
  }

  operateData(): void {
    this.isOperating = true
    setTimeout(() => {
      this.items.forEach(item => (this.mapOfCheckedId[item.id] = false))
      this.refreshStatus()
      this.isOperating = false
    }, 1000)
  }

  ngOnInit(): void { }

  checkDate(string) {
    // console.log('string')
    // console.log(string)

    // if (
    //   this.regExpDate.test(string) == function() { return new Date().getTime().toString(); }
    //    { 
    // )
    //   string = formatDate(string, 'dd/MM/yyyy', 'en')
    //   return string
    // } else {
    //   return string
    // }
    // if (
    //   this.regExpDate.test(string)
    //   ) {
    //   string = formatDate(string, 'dd/MM/yyyy', 'en')
    //   return string
    // } else {
    //   return string
    // }


    if (this.regExpDate.test(string)) {
      string = formatDate(string, 'dd/MM/yyyy', 'en')
      return string
      // aggiungere check sulla fine stringa es indexof t 
    } else if (!isNaN(Date.parse(string)) && string.length == 19) {
        string = formatDate(string, 'dd/MM/yyyy', 'en')
        return string
      } else {
        return string
      }
  }


}
