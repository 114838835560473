<nz-spin [nzSpinning]="loading">
  <nz-collapse nzAccordion>
    <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
      [nzDisabled]="panel.disabled">
        <app-anagrafica-animale *ngIf="panel.name=='Anagrafica animale'"></app-anagrafica-animale>
        <app-ausiliare *ngIf="panel.name=='Ausiliare'"></app-ausiliare>
        <app-cliente *ngIf="panel.name=='Cliente'"></app-cliente>
        <app-contenitore *ngIf="panel.name=='Contenitore'"></app-contenitore>
        <app-deiezione *ngIf="panel.name=='Deiezione'"></app-deiezione>
        <app-fabbricato *ngIf="panel.name=='Fabbricato'"></app-fabbricato>
        <app-farmaco *ngIf="panel.name=='Farmaco'"></app-farmaco>
        <app-fornitore *ngIf="panel.name=='Fornitore'"></app-fornitore>
        <app-luogo-animali *ngIf="panel.name=='Luogo animali'"></app-luogo-animali>
        <app-magazzino *ngIf="panel.name=='Magazzino'"></app-magazzino>
        <app-mezzo *ngIf="panel.name=='Mezzo'"></app-mezzo>
        <app-prodotto *ngIf="panel.name=='Prodotto'"></app-prodotto>
        <app-sensore *ngIf="panel.name=='Sensore'"></app-sensore>
        <app-terreno *ngIf="panel.name=='Terreno'"></app-terreno>
        <app-utensile *ngIf="panel.name=='Utensile'"></app-utensile>       
    </nz-collapse-panel>
  </nz-collapse>
</nz-spin>