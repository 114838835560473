<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Last Month Statistics'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-charts-11></vb-widgets-charts-11></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-charts-11v1></vb-widgets-charts-11v1></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-charts-11v2></vb-widgets-charts-11v2></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Today Statistics'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4></vb-widgets-charts-4></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v1></vb-widgets-charts-4v1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-charts-4v2></vb-widgets-charts-4v2></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <vb-headers-heading-2
            [data]="{'title':'Recently Referrals','description':'Block with important Recently Referrals information'}"
          ></vb-headers-heading-2>
        </div>
        <div class="card-body"><vb-tables-antd-3></vb-tables-antd-3></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Your Cards (3)','button':'View All','url':'https://google.com'}"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-17></vb-widgets-general-17></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-17v1></vb-widgets-general-17v1></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-17v2></vb-widgets-general-17v2></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Your accounts','button':'View All','url':'https://google.com'}"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-5></vb-widgets-general-5></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-general-5></vb-widgets-general-5></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading-3
            [data]="{'title':'Recent Transactions','button':'View All','url':'https://google.com'}"
          ></vb-headers-heading-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card"><vb-widgets-general-6v1></vb-widgets-general-6v1></div>
      <div class="card"><vb-widgets-general-6></vb-widgets-general-6></div>
      <div class="card"><vb-widgets-general-6v1></vb-widgets-general-6v1></div>
    </div>
  </div>
</div>
