<nz-collapse>
  <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
    [nzDisabled]="panel.disabled" (nzActiveChange)="onActivate($event);">
    <nz-spin [nzSpinning]="loading">
      <app-qr-code-reader *ngIf="scannerEnabled" (qrCode)="getData($event)"></app-qr-code-reader>
      <div *ngIf="!scannerEnabled">
        <div class="card-header" *ngIf="type">
          <strong>Il QR code scansionato appartiene ad un {{type}}</strong>
        </div>
        <div class="card-body">
          <div [ngSwitch]="type">
            <div *ngSwitchCase="'Terreno'">
              <p><strong>Id:</strong> {{data?.id}}</p>
              <p><strong>Codice catastale:</strong> {{data?.codiceCatastale}}</p>
              <p><strong>Coordinate:</strong> {{data?.coordinate}}</p>
              <p><strong>Grandezza:</strong> {{data?.grandezza}}</p>
            </div>
            <div *ngSwitchCase="'Utensile'">
              <p><strong>Id:</strong> {{data?.id}}</p>
              <p><strong>Nome:</strong> {{data?.nome}}</p>
              <p><strong>Descrizione:</strong> {{data?.descrizione}}</p>
            </div>
            <div *ngSwitchCase="'Mezzo'">
              <p><strong>Id:</strong> {{data?.id}}</p>
              <p><strong>Nome:</strong> {{data?.nome}}</p>
              <p><strong>Marca:</strong> {{data?.marca}}</p>
              <p><strong>Modello:</strong> {{data?.modello}}</p>
              <p><strong>Anno:</strong> {{data?.anno | date: 'yyyy'}}</p>
            </div>
            <div *ngSwitchCase="'Magazzino'">
              <p><strong>Id:</strong> {{data?.id}}</p>
              <p><strong>Nome:</strong> {{data?.nome}}</p>
              <p><strong>Coordinate:</strong> {{data?.coordinate}}</p>
              <p *ngIf="data?.bio"><strong>Il magazzino è bio</strong></p>
              <p *ngIf="!data?.bio"><strong>Il magazzino non è bio</strong></p>
            </div>
            <div *ngSwitchCase="'Ausiliare'">
              <p><strong>Id:</strong> {{data?.id}}</p>
              <p><strong>Nome:</strong> {{data?.nome}}</p>
              <p><strong>Tipo:</strong> {{data?.tipo}}</p>
              <p><strong>Magazzino:</strong> {{data?.magazzino}}</p>
              <p><strong>Sottotipo:</strong> {{data?.sottoTipo}}</p>
              <p><strong>Nome commerciale:</strong> {{data?.nomeCommerciale}}</p>
              <p *ngIf="data?.additivoVitaminaA || data?.additivoVitaminaD || data?.additivoVitaminaE">
                <strong>Sono presenti gli additivi:</strong>
              </p>
              <ul>
                <li *ngIf="data?.additivoVitaminaA">Vitamina A</li>
                <li *ngIf="data?.additivoVitaminaD">Vitamina B</li>
                <li *ngIf="data?.additivoVitaminaE">Vitamina D</li>
              </ul>
              <p *ngIf="data?.bio"><strong>L'ausiliare è bio</strong></p>
              <p *ngIf="!data?.bio"><strong>L'ausiliare non è bio</strong></p>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>
  </nz-collapse-panel>
</nz-collapse>