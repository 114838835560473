<div nz-form [formGroup]="form" class="mb-3">
  <div [ngSwitch]="field.controlType" style="width: 100% !important;">
    <label [attr.for]="field.key">{{field.label}}</label>

    <input *ngSwitchCase="'textbox'" [formControlName]="field.key" [id]="field.key" [type]="field.type"
      placeholder="{{field.placeholder}}" class='form-control'>

    <input *ngSwitchCase="'number'" [formControlName]="field.key" [id]="field.key" [type]="field.type"
      placeholder="{{field.placeholder}}" class='form-control'>

    <!-- <select [id]="field.key" *ngSwitchCase="'dropdown'" [formControlName]="field.key"
      class='form-select form-control'>
      <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</option>
    </select> -->

    <nz-select [id]="field.key" *ngSwitchCase="'dropdown'" [formControlName]="field.key" class="form-control" style="border: none">
      <nz-option *ngFor="let opt of field.options" [nzValue]="opt.id" [nzLabel]="opt.name"></nz-option>
    </nz-select>

    <!-- <div *ngIf="form.hasError('whitespace')">Please don't use white spaces</div> -->
    <!-- <div *ngIf="!isValid">Inserire {{field.label}}</div> -->
  </div>
</div>

<!-- <div nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [attr.for]="field.key">{{field.label}}</nz-form-label>

    <nz-form-control [ngSwitch]="field.controlType"  nzErrorTip="{{field.label}} is required">

      <input nz-input *ngSwitchCase="'textbox'" [formControlName]="field.key" [id]="field.key" [type]="field.type">

      <nz-select [id]="field.key" *ngSwitchCase="'dropdown'" [formControlName]="field.key">
        <nz-option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</nz-option>
      </nz-select>

    </nz-form-control> -->

<!-- <nz-form-control nzErrorTip="{{field.label}} is required"></nz-form-control> -->
<!-- <div class="errorMessage" *ngIf="!isValid"></div> -->
<!-- </nz-form-item>
</div> -->