<div class="modal-header" *ngIf="isEdit || isAdd">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="nome" class="mb-0 col-form-label">Nome*</label>
          <input type="text" class="form-control" formControlName="nome" id="nome">
        </div>
        <div class="col-lg-4">
          <label for="idTipo" class="mb-0 col-form-label">Tipo ausiliare*</label>
          <nz-select [(ngModel)]="idTipo" [ngModelOptions]="{standalone: true}" id="idTipo" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderTipi" [nzDisabled]="!tipi">
            <nz-option *ngFor="let opt of tipi" [nzValue]="opt.id" [nzLabel]="opt.classe"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addTipoAusiliare()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi tipo</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/types-auxiliaries">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci tipo</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="idMagazzino" class="mb-0 col-form-label">Magazzino*</label>
          <nz-select [(ngModel)]="idMagazzino" [ngModelOptions]="{standalone: true}" id="idMagazzino"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderMagazzini" [nzDisabled]="!magazzini">
            <nz-option *ngFor="let opt of magazzini" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addMagazzino()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi magazzino</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/warehouses">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci magazzino</span>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idSottoTipo" class="mb-0 col-form-label">Sottotipo ausiliare*</label>
          <nz-select [(ngModel)]="idSottoTipo" [ngModelOptions]="{standalone: true}" id="idSottoTipo"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderSottotipi" [nzDisabled]="!sottotipi">
            <nz-option *ngFor="let opt of sottotipi" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addSottotipoAusiliare()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi sottotipo</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/subtypes-auxiliaries">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci sottotipo</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="nomeCommerciale" class="mb-0 col-form-label">Nome commerciale*</label>
          <input type="text" class="form-control" formControlName="nomeCommerciale" id="nomeCommerciale">
        </div>
        <div class="col-lg-4">
          <label for="idUtilizzoAusiliare" class="mb-0 col-form-label">Utilizzi ausiliare*</label>
          <div>
            <nz-select [(ngModel)]="idUtilizzi" [ngModelOptions]="{standalone: true}" id="idUtilizzoAusiliare"
              class="container" [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderUtilizzoAusiliare" [nzDisabled]="!utilizziAusiliari">
              <nz-option *ngFor="let opt of utilizziAusiliari" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
          </div>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd) ">
            <button class="btn btn-link fw-bold" (click)="addUtilizzoAusiliare()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi utilizzo ausiliare</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/uses-auxiliaries">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci utilizzo ausiliare</span>
            </button>
          </div>
        </div>

      </div>
      <div class="form-group row">
        <div class="col-lg-4 align-items-center d-flex pt-3">
          <label nz-checkbox [(ngModel)]="additivoVitaminaA" [ngModelOptions]="{standalone: true}" class="mb-0 col-form-label">Additivo vitamina
            A*</label>
        </div>
        <div class="col-lg-4 align-items-center d-flex pt-3">
          <label nz-checkbox [(ngModel)]="additivoVitaminaD" [ngModelOptions]="{standalone: true}" class="mb-0 col-form-label">Additivo vitamina
            D*</label>
        </div>
        <div class="col-lg-4 align-items-center d-flex pt-3">
          <label nz-checkbox [(ngModel)]="additivoVitaminaE" [ngModelOptions]="{standalone: true}" class="mb-0 col-form-label">Additivo vitamina
            E*</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 align-items-center d-flex pt-3">
          <label nz-checkbox [(ngModel)]="checked" [ngModelOptions]="{standalone: true}" class="mb-0 col-form-label">Bio*</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon"
        [disabled]="!(form.valid && idTipo && idMagazzino && idSottoTipo)" (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>