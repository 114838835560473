import { PipeTransform, Pipe } from '@angular/core';
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn';

/*
* This Pipe picks only the column passed inside displayed columns
*/
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {

    transform(value, displayedColumns: ITableColumn[], args: string[]): any {
        let keys = [];

        for (let key in value) {
            if (!displayedColumns.some(e => e.dataKey === key)) {
                continue;
            }
            keys.push(key);
        }
        return keys;
    }

}
