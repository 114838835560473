export class Attivita {
  id: number
  nome: string
  descrizione: string
  idProdotto: number
  dataInizio: Date
  dataFine: Date
  idMagazzino: number
  quantitaPrevista: number
  idTipoAttivita: number
  idColtura: number
  idStatus: number
  costoPrevisto: number
  costoEffettivo: number
  idMezzi: number[]
  idAusiliari: {
    id: number
    utilizzo: number
  }[]
  idTerreni: number[]
  idUtensili: number[]
}
