<div class="modal-header" *ngIf="isEdit">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idRazza" class="mb-0 col-form-label">Razza*</label>
          <nz-select [(ngModel)]="idRazza" [ngModelOptions]="{standalone: true}" id="idRazza" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderRazze" [nzDisabled]="!razze">
            <nz-option *ngFor="let opt of razze" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addRazze()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi razza</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/breeds">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci razza</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="dataNascita" class="mb-0 col-form-label">Data di nascita*</label>
          <input type="date" class="form-control" formControlName="dataNascita" id="dataNascita"
            [(ngModel)]="currentDate1">
        </div>
        <div class="col-lg-4">
          <label for="dataSvezzamento" class="mb-0 col-form-label">Data di svezzamento*</label>
          <input type="date" class="form-control" formControlName="dataSvezzamento" id="dataSvezzamento"
            [(ngModel)]="currentDate2">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="dataMorte" class="mb-0 col-form-label">Data di morte</label>
          <input type="date" class="form-control" formControlName="dataMorte" id="dataMorte" [(ngModel)]="currentDate">
        </div>
        <div class="col-lg-4">
          <label for="dataRimonta" class="mb-0 col-form-label">Data rimonta*</label>
          <input type="date" class="form-control" formControlName="dataRimonta" id="dataRimonta"
            [(ngModel)]="currentDate3">
        </div>
        <div class="col-lg-4 align-items-center d-flex pt-3">
          <label nz-checkbox [(ngModel)]="rimontaNaturale" [ngModelOptions]="{standalone: true}" class="mb-0 col-form-label">Rimonta naturale*</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idMandria" class="mb-0 col-form-label">Mandria*</label>
          <nz-select [(ngModel)]="idMandria" [ngModelOptions]="{standalone: true}" id="idMandria"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderMandrie" [nzDisabled]="!mandrie">
            <nz-option *ngFor="let opt of mandrie" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addMandria()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi mandria</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/herds">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci mandrie</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="dataIngressoMandria" class="mb-0 col-form-label">Data ingresso in mandria*</label>
          <input type="date" class="form-control" formControlName="dataIngressoMandria" id="dataIngressoMandria"
            [(ngModel)]="currentDate4">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!(form.valid && idMandria && idRazza)"
        (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>