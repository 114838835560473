<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card-placeholder">
        <div class="card-header">
          <vb-headers-heading [data]="{'title':'Ecommerce Dashboard'}"></vb-headers-heading>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <div class="card">
        <div class="card-body"><vb-widgets-tables-5></vb-widgets-tables-5></div>
      </div>
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-tabbed-6></vb-headers-card-header-tabbed-6>
        </div>
        <div class="card-body"><vb-widgets-charts-3></vb-widgets-charts-3></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body text-white bg-success rounded">
          <vb-widgets-general-2v3></vb-widgets-general-2v3>
        </div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-2></vb-widgets-general-2></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-general-2v1></vb-widgets-general-2v1></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-lists-11></vb-widgets-lists-11></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card"><vb-widgets-general-16></vb-widgets-general-16></div>
    </div>
  </div>
</div>
