import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-ecommerce-dashboard',
  templateUrl: './dashboard.component.html',
})
export class EcommerceDashboardComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
