<div class="modal-header" *ngIf="isEdit">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <nz-spin [nzSpinning]="loading">
    <div class="modal-body" style="position: relative;">
      <form [formGroup]="form">
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="temperatura" class="mb-0 col-form-label">Temperatura*</label>
            <input type="text" class="form-control" formControlName="temperatura" id="temperatura">
          </div>
          <div class="col-lg-4">
            <label for="umiditaAria" class="mb-0 col-form-label">Umidità aria*</label>
            <input type="text" class="form-control" formControlName="umiditaAria" id="umiditaAria">
          </div>
          <div class="col-lg-4">
            <label for="umiditaAriaMisura" class="mb-0 col-form-label">Umidità aria misura*</label>
            <input type="text" class="form-control" formControlName="umiditaAriaMisura" id="umiditaAriaMisura">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="umiditaTerreno" class="mb-0 col-form-label">Umidità terreno*</label>
            <input type="text" class="form-control" formControlName="umiditaTerreno" id="umiditaTerreno">
          </div>
          <div class="col-lg-4">
            <label for="umiditaTerrenoMisura" class="mb-0 col-form-label">Umidità terreno misura*</label>
            <input type="text" class="form-control" formControlName="umiditaTerrenoMisura" id="umiditaTerrenoMisura">
          </div>
          <div class="col-lg-4">
            <label for="ph" class="mb-0 col-form-label">pH*</label>
            <input type="text" class="form-control" formControlName="ph" id="ph">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="luce" class="mb-0 col-form-label">Luce*</label>
            <input type="text" class="form-control" formControlName="luce" id="luce">
          </div>
          <div class="col-lg-4">
            <label for="luceMisura" class="mb-0 col-form-label">Luce misura*</label>
            <input type="text" class="form-control" formControlName="luceMisura" id="luceMisura">
          </div>
          <div class="col-lg-4">
            <label for="data" class="mb-0 col-form-label">Data*</label>
            <input type="date" class="form-control" formControlName="data" id="data"
              [(ngModel)]="currentDate">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-4">
            <label for="idTerreno" class="mb-0 col-form-label">Terreno*</label>
            <nz-select [(ngModel)]="idTerreno" [ngModelOptions]="{standalone: true}" id="idTerreno"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderTerreni" [nzDisabled]="!terreni">
              <nz-option *ngFor="let opt of terreni" [nzValue]="opt.id" [nzLabel]="opt.codiceCatastale"></nz-option>
            </nz-select>
            <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
              <button class="btn btn-link fw-bold" (click)="addTerreno()">
                <i class="fe fe-plus mr-1"></i>
                <span class="text-underline">Aggiungi terreno</span>
              </button>
              <button class="btn btn-link fw-bold" routerLink="/manage-data/farmlands">
                <i class="fe fe-edit-2 mr-1"></i>
                <span class="text-underline">Gestisci terreno</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <span class="text-danger">*Campi obbligatori</span>
      <div class="form-row d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!(form.valid && idTerreno)"
          (click)="postForm()">
          <span class="btnAddon">
            <i class="btnAddonIcon fe fe-plus-circle"></i>
          </span>
          {{button}}
        </button>
      </div>
    </div>
  </nz-spin>
