import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { Ausiliare } from 'src/app/models/precisionfarming-models/Ausiliare'
import { HttpService } from 'src/app/services/shared/http-services/http.services'
import { MEditMagazzinoComponent } from '../m-edit-magazzino/m-edit-magazzino.component'
import { MManageSottotipoAusiliareComponent } from '../manage-select-fields-modals/m-manage-sottotipo-ausiliare/m-manage-sottotipo-ausiliare.component'
import { MManageSottotipoComponent } from '../manage-select-fields-modals/m-manage-sottotipo/m-manage-sottotipo.component'
import { MManageTipoAusiliareComponent } from '../manage-select-fields-modals/m-manage-tipo-ausiliare/m-manage-tipo-ausiliare.component'
import { MManageTipoComponent } from '../manage-select-fields-modals/m-manage-tipo/m-manage-tipo.component'
import { MManageUtilizzoAusiliareComponent } from '../manage-select-fields-modals/m-manage-utilizzo-ausiliare/m-manage-utilizzo-ausiliare.component'

@Component({
  selector: 'app-m-edit-ausiliare',
  templateUrl: './m-edit-ausiliare.component.html',
  styleUrls: ['./m-edit-ausiliare.component.scss'],
  providers: [BsModalService],
})
export class MEditAusiliareComponent implements OnInit {
  form: FormGroup
  idTipo: number;
  idMagazzino: number;
  magazzini: any;
  idSottoTipo: number;
  loadingSelect: boolean
  loading: boolean
  toClose: boolean = false
  deleteButton: boolean
  checked: boolean = false
  additivoVitaminaA: boolean = false
  additivoVitaminaD: boolean = false
  additivoVitaminaE: boolean = false
  @Output() onClose = new EventEmitter()
  @Output() onButtonActive = new EventEmitter();
  @Input() item
  @Input() title
  @Input() isEdit = false
  @Input() isAdd = false
  @Input() button
  sottotipi: any
  tipi: any
  modalRef: BsModalRef;
  utilizziAusiliari: any
  idUtilizzoAusiliare: any
  idUtilizzi: any
  placeholderMagazzini: string = ""
  placeholderSottotipi: string = ""
  placeholderTipi: string = ""
  placeholderUtilizzoAusiliare: string = ""

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
      nomeCommerciale: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.getMagazzini();
    this.getSottotipi();
    this.getTipi();
    this.getUtilizzoAusiliare();
    if (this.item) {
      this.getData()
    }
  }

  get f() {
    return this.form.controls
  }

  closeModal() {
    if (this.toClose && !this.loading) {
      this.onClose.emit()
    }
  }

  emitOnButtonActive() {
    this.onButtonActive.emit();
  }

  postForm() {
    this.loading = true
    let f: Ausiliare = new Ausiliare()
    f.nome = this.f.nome.value
    f.idTipo = this.idTipo
    f.idSottoTipo = this.idSottoTipo
    f.idMagazzino = this.idMagazzino
    f.nomeCommerciale = this.f.nomeCommerciale.value
    f.additivoVitaminaA = this.additivoVitaminaA
    f.additivoVitaminaD = this.additivoVitaminaD
    f.additivoVitaminaE = this.additivoVitaminaE
    f.bio = this.checked
    let utilizziId = [];
    for (let i = 0; i < this.idUtilizzi.length; i++) {
      let string = this.idUtilizzi[i].toString();
      utilizziId.push(string)
    }
    f.utilizzi = utilizziId
    console.log('f')
    console.log(f)
    if (this.isEdit) {
      f.id = this.item.id
      this.httpService.put('Ausiliare/EditAusiliare', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.idTipo = undefined
            this.idSottoTipo = undefined
            this.idMagazzino = undefined
            this.idUtilizzi = []
            this.form.reset()
            this.closeModal()
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    } else {
      this.httpService.post('Ausiliare/AddAusiliare', f).subscribe(
        res => {
          if (res.isSuccess) {
            this.notification.success('Operazione effettuata con successo!')
            this.toClose = true
            this.loading = false
            this.idTipo = undefined
            this.idSottoTipo = undefined
            this.idMagazzino = undefined
            this.idUtilizzi = []
            this.form.reset()
            this.closeModal()
            this.emitOnButtonActive();
          } else {
            this.notification.error(res.errorMessageIta)
          }
          console.log('res')
          console.log(res)
          this.loading = false
        },
        err => {
          this.notification.error("Errore nel completamento dell'operazione!")
          console.log(err)
          this.loading = false
        },
      )
    }
  }

  getData() {
    this.loading = true
    this.httpService.get('Ausiliare/GetAusiliareById?Id=' + this.item.id).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          this.idTipo = res.ausiliare.idTipo
          this.idSottoTipo = res.ausiliare.idSottoTipo
          this.idMagazzino = res.ausiliare.idMagazzino
          this.checked = res.ausiliare.bio
          this.additivoVitaminaA = res.ausiliare.additivoVitaminaA
          this.additivoVitaminaD = res.ausiliare.additivoVitaminaD
          this.additivoVitaminaE = res.ausiliare.additivoVitaminaE
          let utilizziId = [];
          for (let i = 0; i < res.utilizzi.length; i++) {
            utilizziId.push(res.utilizzi[i].id)
          }
          this.idUtilizzi = utilizziId
          this.form.patchValue({
            nome: res.ausiliare.nome,
            nomeCommerciale: res.ausiliare.nomeCommerciale,
          })
        } else {
          this.loading = false
          console.log(res)
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  getMagazzini() {
    this.placeholderMagazzini = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/Magazzini').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.magazzini?.length) {
            this.placeholderMagazzini = "Non ci sono magazzini"
          }
          this.magazzini = res.magazzini
        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getTipi() {
    this.placeholderTipi = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/TipiAusiliari').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.tipiAusiliari?.length) {
            this.placeholderTipi = "Non ci sono tipi ausiliare"
          }
          this.tipi = res.tipiAusiliari
          console.log('tipi')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  getSottotipi() {
    this.placeholderSottotipi = ""
    this.loadingSelect = true;
    this.httpService.get('DropDown/SottoTipiAusiliari').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.sottoTipi?.length) {
            this.placeholderSottotipi = "Non ci sono sottotipi"
          }
          this.sottotipi = res.sottoTipi
          console.log('sottot')
          console.log(res)

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  addTipoAusiliare() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi tipo ausiliare',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageTipoAusiliareComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getTipi()
    })
  }

  addMagazzino() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi magazzino',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditMagazzinoComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getMagazzini()
    })
  }

  addSottotipoAusiliare() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi sottotipo ausiliare',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageSottotipoAusiliareComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getSottotipi()
    })
  }

  getUtilizzoAusiliare() {
    this.placeholderUtilizzoAusiliare = ""
    this.loadingSelect = true;
    this.httpService.get('UtilizzoAusiliare/GetUtilizzoAusiliare').subscribe(
      res => {
        if (res.isSuccess) {
          this.loadingSelect = false
          if (!res.utilizzoausiliare?.length) {
            this.placeholderUtilizzoAusiliare = "Non ci sono utilizzi ausiliare"
          }
          this.utilizziAusiliari = res.utilizzoausiliare;

        } else {
          this.loadingSelect = false
          console.log(res)
        }
      },
      err => {
        this.loadingSelect = false
        console.log(err)
      },
    )
  }

  addUtilizzoAusiliare() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        isAdd: true,
        title: 'Aggiungi utilizzo ausiliare',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MManageUtilizzoAusiliareComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getUtilizzoAusiliare();
    })
  }
}
