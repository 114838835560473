import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dashboard-crypto',
  templateUrl: './crypto.component.html',
})
export class DashboardCryptoComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
