import { Role } from './Role'

export class CustomField<T> {
  value: T | undefined
  key: string
  label: string
  required: boolean
  hasBlankSpaces: boolean
  order: number
  controlType: string
  type: string
  class: string
  placeholder: string
  options: Role[]

  constructor(
    options: {
      value?: T
      key?: string
      label?: string
      required?: boolean
      hasBlankSpaces?: boolean
      order?: number
      controlType?: string
      type?: string
      class?: string
      placeholder?: string
      options?: Role[]
    } = {},
  ) {
    this.value = options.value
    this.key = options.key || ''
    this.label = options.label || ''
    this.required = !!options.required
    this.order = options.order === undefined ? 1 : options.order
    this.controlType = options.controlType || ''
    this.type = options.type || ''
    this.class = options.class || ''
    this.placeholder = options.placeholder || ''
    this.options = options.options || []
  }
}
