import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dashboard-gamma',
  templateUrl: './gamma.component.html',
})
export class DashboardGammaComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
