import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-advanced-utilities',
  templateUrl: './utilities.component.html',
})
export class AdvancedUtilitiesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
