<div class="modal-header" *ngIf="isEdit">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<nz-spin [nzSpinning]="loading">
  <div class="modal-body" style="position: relative;">
    <form [formGroup]="form">
      <div class="form-group row">

        <div class="col-lg-4">
          <label for="nome" class="mb-0 col-form-label">Nome*</label>
          <input type="text" class="form-control" formControlName="nome" id="nome">
        </div>
        <div class="col-lg-8">
          <label for="descrizione" class="mb-0 col-form-label">Descrizione*</label>
          <input type="text" class="form-control" formControlName="descrizione" id="descrizione">
        </div>
        
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idProdotto" class="mb-0 col-form-label">Prodotto*</label>
          <nz-select [(ngModel)]="idProdotto" [ngModelOptions]="{standalone: true}" id="idProdotto" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderProdotti"
            [nzDisabled]="!prodotti">
            <nz-option *ngFor="let opt of prodotti" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addProdotto()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi prodotto</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/products">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci prodotto</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="dataInizio" class="mb-0 col-form-label">Data di inizio</label>
          <input type="date" class="form-control" formControlName="dataInizio" id="dataInizio"
            [(ngModel)]="currentDate">
        </div>
        <div class="col-lg-4">
          <label for="dataFine" class="mb-0 col-form-label">Data di fine*</label>
          <input type="date" class="form-control" formControlName="dataFine" id="dataFine" [(ngModel)]="currentDate3">
        </div>

      </div>
      <div class="form-group row">

        <div class="col-lg-4">
          <label for="idMagazzino" class="mb-0 col-form-label">Magazzino*</label>
          <nz-select [(ngModel)]="idMagazzino" [ngModelOptions]="{standalone: true}" id="idMagazzino"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderMagazzini"
            [nzDisabled]="!magazzini">
            <nz-option *ngFor="let opt of magazzini" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addMagazzino()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi magazzino</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/warehouses">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci magazzino</span>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="quantitaPrevista" class="mb-0 col-form-label">Quantità prevista*</label>
          <input type="number" min="0" class="form-control" formControlName="quantitaPrevista" id="quantitaPrevista">
        </div>
        <div class="col-lg-4">
          <label for="costiPrevisti" class="mb-0 col-form-label">Costi previsti*</label>
          <input type="number" min="0" class="form-control" formControlName="costiPrevisti" id="costiPrevisti">
        </div>
      </div>
      <div class="form-group row">

        <div class="col-lg-4">
          <label for="idTipoAttivita" class="mb-0 col-form-label">Tipo attività*</label>
          <nz-select [(ngModel)]="idTipoAttivita" [ngModelOptions]="{standalone: true}" id="idTipoAttivita"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true"
            [nzPlaceHolder]="placeholderTipiAttivita" [nzDisabled]="!tipiAttivita">
            <nz-option *ngFor="let opt of tipiAttivita" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addTipoAttivita()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi tipo attività</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/activity-types">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci tipo attività</span>
            </button>
          </div>
        </div>

        <div class="col-lg-4">
          <label for="idMezzi" class="mb-0 col-form-label">Mezzi*</label>
          <div>
            <nz-select [(ngModel)]="idMezzi" [ngModelOptions]="{standalone: true}" id="idMezzi" class="container"
              [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderMezzi" [nzDisabled]="!mezzi">
              <nz-option *ngFor="let opt of mezzi" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
          </div>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addMezzo()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi mezzo</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/vehicles">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci mezzo</span>
            </button>
          </div>
        </div>




        <div class="col-lg-4">
          <label for="idTerreni" class="mb-0 col-form-label">Terreni*</label>
          <div>
            <nz-select [(ngModel)]="idTerreni" [ngModelOptions]="{standalone: true}" id="idTerreni" class="container"
              [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderTerreni"
              [nzDisabled]="!terreni">
              <nz-option *ngFor="let opt of terreni" [nzValue]="opt.id" [nzLabel]="opt.codiceCatastale"></nz-option>
            </nz-select>
          </div>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addTerreni()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi terreno</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/farmlands">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci terreno</span>
            </button>
          </div>
        </div>

      </div>
      <div class="form-group row">

        <div class="col-lg-4">
          <label for="idUtensili" class="mb-0 col-form-label">Utensili*</label>
          <div>
            <nz-select [(ngModel)]="idUtensili" [ngModelOptions]="{standalone: true}" id="idUtensili" class="container"
              [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderUtensili"
              [nzDisabled]="!utensili">
              <nz-option *ngFor="let opt of utensili" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
          </div>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addUtensili()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi utensile</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/tools">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci utensile</span>
            </button>
          </div>
        </div>

        <div class="col-lg-4">
          <label for="idAusiliari" class="mb-0 col-form-label">Ausiliari*</label>
          <nz-select [(ngModel)]="idAusiliari" [ngModelOptions]="{standalone: true}" id="idAusiliari"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true"
            (ngModelChange)="getUtilizzoAusiliare()" [nzPlaceHolder]="placeholderAusiliari" [nzDisabled]="!ausiliari">
            <nz-option *ngFor="let opt of ausiliari" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addAusiliare()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi ausiliare</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/auxiliaries">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci ausiliare</span>
            </button>
          </div>
        </div>



        <div class="col-lg-4">
          <label for="idUtilizzoAusiliare" class="mb-0 col-form-label">Utilizzo ausiliare*</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idUtilizzoAusiliare" [ngModelOptions]="{standalone: true}" id="idUtilizzoAusiliare"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true"
              [nzPlaceHolder]="placeholderUtilizziAusiliare" [nzDisabled]="!idAusiliari">
              <nz-option *ngFor="let opt of utilizziAusiliari" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Aggiungi ausiliare in relazione ad utilizzo ausiliare" (click)="postIdAusiliari()">
              <i class="btnAddonIcon fe fe-plus-circle"></i>
            </button>
          </div>
          <div class="container fluid d-flex justify-content-end p-0" *ngIf="!(isEdit || isAdd)">
            <button class="btn btn-link fw-bold" (click)="addUtilizzoAusiliare()">
              <i class="fe fe-plus mr-1"></i>
              <span class="text-underline">Aggiungi utilizzo ausiliare</span>
            </button>
            <button class="btn btn-link fw-bold" routerLink="/manage-data/uses-auxiliaries">
              <i class="fe fe-edit-2 mr-1"></i>
              <span class="text-underline">Gestisci utilizzo ausiliare</span>
            </button>
          </div>
        </div>
        <!-- <div class="col-lg-4">
          <label class="mb-0 col-form-label">&nbsp;</label>
          <div class="w-100 d-flex justify-content-start">
           
          </div>
        </div> -->

      </div>
      <div class="form-group" *ngIf="ausiliareUtilizzoAusiliare[0]">
        <label class="col-md-12 text-left p-0">Relazioni ausiliare/utilizzo ausiliare</label>
        <div class="col-md-12 p-0" *ngIf="!loadingUpdate">
          <table class="w-100 mb-3">
            <tbody>
              <tr class="border-tab" *ngFor="let item of ausiliareUtilizzoAusiliare; let i = index"
                [attr.data-index]="i">
                <td class="col-4 text-truncate">{{getNameAusiliareById(item.id)}}</td>
                <td class="col-4 text-truncate">{{getNameUtilizzoById(item.utilizzo)}}</td>
                <td class="d-flex justify-content-end p-2">
                  <a nz-popconfirm nzPopconfirmTitle="Sicuro di voler eliminare?" nzOkType="danger" nzOkText="Elimina"
                    nzCancelText="Annulla" nzPopconfirmPlacement="bottom" (nzOnConfirm)="removeArrayItem(i)"
                    (nzOnCancel)="cancel()">
                    <button nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Elimina" class="btn btn-outline-danger">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idColtura" class="mb-0 col-form-label">Coltura*</label>
          <nz-select [(ngModel)]="idColtura" [ngModelOptions]="{standalone: true}" id="idColtura" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderColture"
            [nzDisabled]="!colture">
            <nz-option *ngFor="let opt of colture" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
        </div>
        <div class="col-lg-4">
          <label for="idStatus" class="mb-0 col-form-label">Status*</label>
          <nz-select [(ngModel)]="idStatus" [ngModelOptions]="{standalone: true}" id="idStatus" class="form-control"
            [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeHolderStatus"
            [nzDisabled]="!status">
            <nz-option *ngFor="let opt of status" [nzValue]="opt.id" [nzLabel]="opt.name"></nz-option>
          </nz-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <span class="text-danger">*Campi obbligatori</span>
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon"
        [disabled]="!(form.valid && ausiliareUtilizzoAusiliare.length && idMagazzino && idMezzi && idProdotto && idTerreni && idTipoAttivita && idUtensili)"
        (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>