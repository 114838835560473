import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-extra-apps-google-analytics',
  templateUrl: './google-analytics.component.html',
})
export class ExtraAppsGoogleAnalyticsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
