import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { MEditDatiPianificazioneComponent } from 'src/app/modals/precisionfarming-modals/attivita/m-edit-dati-pianificazione/m-edit-dati-pianificazione.component';
import { ITableColumn } from 'src/app/models/interfaces/ITableColumn';
import { CustomTextButton } from 'src/app/models/shared/CustomTextButton';
import { HttpService } from 'src/app/services/shared/http-services/http.services';
import { TableWidthService } from 'src/app/services/shared/table-services/TableWidthService';

@Component({
  selector: 'app-produzione-dati-pianificazione',
  templateUrl: './produzione-dati-pianificazione.component.html',
  styleUrls: ['./produzione-dati-pianificazione.component.scss']
})
export class ProduzioneDatiPianificazioneComponent implements OnInit {

  // data$: any
  loading: boolean
  data$: Observable<CustomTextButton<any>[]>
  panels = [
    {
      active: false,
      name: 'Inserisci pianificazione attività',
      disabled: false,
    },
  ]
  colonne: ITableColumn[] = [
    { name: 'Id', dataKey: 'id', columnWidth: '90px' },
    { name: 'Nome', dataKey: 'nome', columnWidth: '170px' },
    { name: 'Descrizione', dataKey: 'descrizione', columnWidth: '170px' },
    { name: 'Prodotto', dataKey: 'nomeProdotto', columnWidth: '170px' },
    { name: 'Data d\'inizio', dataKey: 'dataInizio', columnWidth: '170px' },
    { name: 'Data di fine', dataKey: 'dataFine', columnWidth: '170px' },
    { name: 'Magazzino', dataKey: 'nomeMagazzino', columnWidth: '170px' },
    { name: 'Quantità prevista', dataKey: 'quantitaPrevista', columnWidth: '170px' },
    { name: 'Tipo attività', dataKey: 'nomeTipoAttivita', columnWidth: '170px' },
  ]
  tableWidth: string = this.tableWidthService.getTableWidth(this.colonne)
  idSelectedItem: any;
  modalRef: BsModalRef

  constructor(
    private httpService: HttpService,
    private notification: NzNotificationService,
    private modalService: BsModalService,
    private tableWidthService: TableWidthService,
  ) {
    
  }

  ngOnInit(): void {
    // this.data$ = [
    //   {
    //     nome: "Attività1",
    //     descrizione: "Descrizione attività1"
    //   },
    //   {
    //     nome: "Attività2",
    //     descrizione: "Descrizione attività2"
    //   }
    // ]
    this.getData('');
  }

  getData(event) {
    this.loading = true
    this.httpService.get('Attivita/GetAttivita' + event).subscribe(
      res => {
        if (res.isSuccess) {
          this.loading = false
          //
          this.data$ = res.attivita
          console.log('res')
          console.log(this.data$)
        } else {
          this.loading = false
        }
      },
      err => {
        this.loading = false
        console.log(err)
      },
    )
  }

  edit(item) {
    this.idSelectedItem = item.id
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: true,
        item: item,
        title: 'Dettagli attività',
        button: 'Salva modifiche',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditDatiPianificazioneComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  add() {
    const modalOptions: ModalOptions = {
      keyboard: false,
      backdrop: true,
      initialState: {
        isEdit: false,
        title: 'Aggiungi attività',
        button: 'Aggiungi',
      },
      class: 'app-modal modal-lg',
    }
    this.modalRef = this.modalService.show(MEditDatiPianificazioneComponent, modalOptions)
    this.modalRef.content.onClose.subscribe(() => {
      this.modalRef.hide()
      // this.clearHeader()
      this.getData('')
    })
  }

  delete(item) {
    this.loading = true
    let id = { id: item.id }
    this.httpService.delete('Attivita/DeleteAttivita', id).subscribe(
      res => {
        if (res.isSuccess) {
          this.notification.success('Operazione effettuata con successo!')
          this.getData('')
        } else {
          this.notification.error(res.errorMessageIta)
        }
        console.log('res add user')
        console.log(res)
        // this.getRole('')
        this.loading = false
      },
      err => {
        this.notification.error("Errore nel completamento dell'operazione!")
        console.log(err)
        this.loading = false
      },
    )
  }

}
