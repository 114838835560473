<div class="modal-header d-flex flex-column p-0" *ngIf="isEdit">
  <div class="w-100 d-flex flex-row justify-content-between" style="padding: 1rem 1rem;">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="toClose=true; closeModal(); deleteButton=false;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="w-100" *ngIf="!scannerEnabled">
    <hr class="m-0">
  </div>

  <div class=" w-100 pt-3"  *ngIf="!scannerEnabled">
    <form [formGroup]="formDatePicker">
      <div class="form-group justify-content-end d-flex row m-0">
      <div class="col-lg-4 lg-right" style="margin-bottom: 0 !important;">
          <label for="filterDate" class="mb-0 col-form-label" style=" min-width: 152px !important;"><strong>Filtra dettagli per data:</strong></label>
       </div>
        <div class="col-lg-4">
          <input type="date" class="form-control" [(ngModel)]="currentDate2" formControlName="filterDate"
            (change)="getDataByDate($event)" id="filterDate">
        </div>
      </div>
    </form>
  </div>
</div>
<nz-spin [nzSpinning]="loading">
  <app-qr-code-reader *ngIf="scannerEnabled" (qrCode)="getdataQR($event)"></app-qr-code-reader>
  <div class="modal-body" style="position: relative;" *ngIf="!scannerEnabled">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="nome" class="mb-0 col-form-label">Nome</label>
          <input type="text" class="form-control" formControlName="nome" id="nome" disabled>
        </div>
        <div class="col-lg-8">
          <label for="descrizione" class="mb-0 col-form-label">Descrizione</label>
          <input type="text" class="form-control" formControlName="descrizione" id="descrizione" disabled>
        </div>

      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idProdotto" class="mb-0 col-form-label">Prodotto</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idProdotto" [ngModelOptions]="{standalone: true}" id="idProdotto"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true" [nzPlaceHolder]="placeholderProdotti"
              nzDisabled>
              <nz-option *ngFor="let opt of prodotti" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="dataInizio" class="mb-0 col-form-label">Data di inizio pianificata</label>
          <input type="date" class="form-control" formControlName="dataInizio" id="dataInizio" [(ngModel)]="currentDate"
            disabled>
        </div>
        <div class="col-lg-4">
          <label for="dataFine" class="mb-0 col-form-label">Data di fine pianificata</label>
          <input type="date" class="form-control" formControlName="dataFine" id="dataFine" [(ngModel)]="currentDate3"
            disabled>
        </div>

      </div>
      <div class="form-group row">

        <div class="col-lg-4">
          <label for="idMagazzino" class="mb-0 col-form-label">Magazzino</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idMagazzino" [ngModelOptions]="{standalone: true}" id="idMagazzino"
              class="form-control" [nzLoading]="loadingSelect" nzBorderless="true"
              [nzPlaceHolder]="placeholderMagazzini" nzDisabled>
              <nz-option *ngFor="let opt of magazzini" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Apri lettore QR code" (click)="scannerEnabled=true; openFileInput();">
              <i class="btnAddonIcon fe fe-camera"></i>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="quantitaPrevista" class="mb-0 col-form-label">Quantità prevista</label>
          <input type="number" min="0" class="form-control" formControlName="quantitaPrevista" id="quantitaPrevista"
            disabled>
        </div>
        <div class="col-lg-4">
          <label for="idTipoAttivita" class="mb-0 col-form-label">Tipo attività</label>
          <nz-select [(ngModel)]="idTipoAttivita" [ngModelOptions]="{standalone: true}" id="idTipoAttivita"
            class="form-control" [nzLoading]="loadingSelect" nzBorderless="true"
            [nzPlaceHolder]="placeholderTipiAttivita" nzDisabled>
            <nz-option *ngFor="let opt of tipiAttivita" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idMezzi" class="mb-0 col-form-label">Mezzi</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idMezzi" [ngModelOptions]="{standalone: true}" id="idMezzi" class="container"
              [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderMezzi" nzDisabled>
              <nz-option *ngFor="let opt of mezzi" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Apri lettore QR code" (click)="scannerEnabled=true; openFileInput()">
              <i class="btnAddonIcon fe fe-camera"></i>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="idTerreni" class="mb-0 col-form-label">Terreni</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idTerreni" [ngModelOptions]="{standalone: true}" id="idTerreni" class="container"
              nzDisabled [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderTerreni">
              <nz-option *ngFor="let opt of terreni" [nzValue]="opt.id" [nzLabel]="opt.codiceCatastale"></nz-option>
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Apri lettore QR code" (click)="scannerEnabled=true; openFileInput()">
              <i class="btnAddonIcon fe fe-camera"></i>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="idUtensili" class="mb-0 col-form-label">Utensili</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idUtensili" [ngModelOptions]="{standalone: true}" id="idUtensili" class="container"
              [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderUtensili" nzDisabled>
              <nz-option *ngFor="let opt of utensili" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option>
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Apri lettore QR code" (click)="scannerEnabled=true; openFileInput()">
              <i class="btnAddonIcon fe fe-camera"></i>
            </button>
          </div>
        </div>

      </div>
      <div class="form-group" *ngIf="ausiliareUtilizzoAusiliare[0]">
        <label class="col-lg-12 text-left p-0">Relazioni ausiliare/utilizzo ausiliare</label>
        <div class="col-lg-12 p-0" *ngIf="!loadingUpdate">
          <table class="w-100">
            <tbody>
              <tr class="border-tab tab-height" *ngFor="let item of ausiliareUtilizzoAusiliare; let i = index"
                [attr.data-index]="i">
                <td class="col-4 text-truncate">{{getNameAusiliareById(item.id)}}</td>
                <td class="col-4 text-truncate">{{getNameUtilizzoById(item.utilizzo)}}</td>
                <td class="d-flex justify-content-end p-2" style="position: relative;">
                  <button type="button" class="btn btn-primary btnAddon btn-form-field" style="height: 35px;" nz-tooltip
                    nzTooltipPlacement="top" nzTooltipTitle="Apri lettore QR code"
                    (click)="scannerEnabled=true; openFileInput()">
                    <i class="btnAddonIcon fe fe-camera"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4">
          <label for="idContenitori" class="mb-0 col-form-label">Contenitori</label>
          <div style="position: relative;">
            <nz-select [(ngModel)]="idContenitori" [ngModelOptions]="{standalone: true}" id="idContenitori"
              class="container" [nzLoading]="loadingSelect" nzMode="multiple" [nzPlaceHolder]="placeholderContenitori"
              nzDisabled>
              <!-- <nz-option *ngFor="let opt of contenitori" [nzValue]="opt.id" [nzLabel]="opt.nome"></nz-option> -->
            </nz-select>
            <button type="button" class="btn btn-primary btnAddon btn-form-field" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Apri lettore QR code" (click)="scannerEnabled=true; openFileInput()">
              <i class="btnAddonIcon fe fe-camera"></i>
            </button>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="dataInizioEffettiva" class="mb-0 col-form-label">Data di inizio</label>
          <input type="date" class="form-control" formControlName="dataInizioEffettiva" id="dataInizioEffettiva"
            [(ngModel)]="currentDate4">
        </div>
        <div class="col-lg-4">
          <label for="quantitaProdotta" class="mb-0 col-form-label">Quantità prodotta</label>
          <input type="number" min="0" class="form-control" formControlName="quantitaProdotta" id="quantitaProdotta">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex justify-content-end" *ngIf="!scannerEnabled">
    <div class="form-row d-flex justify-content-end">
      <button type="submit" class="btn btn-primary btnWithAddon" [disabled]="!(form.valid)" (click)="postForm()">
        <span class="btnAddon">
          <i class="btnAddonIcon fe fe-plus-circle"></i>
        </span>
        {{button}}
      </button>
    </div>
  </div>
</nz-spin>