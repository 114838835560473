<div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="card-placeholder">
        <div class="card-body"><vb-widgets-lists-24></vb-widgets-lists-24></div>
      </div>
    </div>
    <div class="col-lg-9 col-md-12">
      <div class="card">
        <div class="card-header py-0">
          <vb-headers-card-header-3 [data]="{'title':'Welcome 👋'}"></vb-headers-card-header-3>
        </div>
        <div class="card-body"><vb-widgets-lists-26></vb-widgets-lists-26></div>
        <div class="card-body">
          <vb-controls-button [data]="{'title':'Add Task','type':'primary'}"></vb-controls-button>
        </div>
        <div class="card-body"><vb-typography-2></vb-typography-2></div>
      </div>
      <div class="card">
        <div class="card-header">
          <vb-headers-card-header [data]="{'title':'Completed'}"></vb-headers-card-header>
        </div>
        <div class="card-body"><vb-widgets-lists-27></vb-widgets-lists-27></div>
      </div>
      <div class="card">
        <div class="card-body"><vb-widgets-tables-6></vb-widgets-tables-6></div>
      </div>
    </div>
  </div>
</div>
